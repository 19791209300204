import { combineReducers } from "redux"
import { connectRouter } from 'connected-react-router'

import user from "./userReducer"
import eventManager from "./eventManagerReducer"
import booth from "./boothReducer"
import session from "./sessionReducer"
import survey from "./surveyReducer"
import qrCode from "./qrCodeReducer"
import appState from "./appStateReducer"
import statistic from "./statisticReducer"
import announcement from "./announcementReducer"
import book from "./bookReducer"
import news from "./newsReducer"

export default history => combineReducers({
	router: connectRouter(history),
	user,
	eventManager,
	booth,
	session,
	survey,
	qrCode,
	appState,
	statistic,
    announcement,
	book,
	news
})
