import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { push } from "connected-react-router";

import { withStyles } from "@material-ui/core/styles";
import {
    Dialog,
    TextField,
    Typography,
    Slide,
    Grid,
    Divider,
    MenuItem,
    Button,
    List,
    ListItem,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    ListItemSecondaryAction,
    IconButton,
    Tooltip,
    AppBar,
    Toolbar, Paper
} from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import {
    Add,
    KeyboardArrowRight,
    DeleteOutline,
    Close
} from '@material-ui/icons';

import { DialogTitle, DialogContent, DialogActions } from "../../reusable/Dialog"

import Question from "../../../model/Question"
import Dispatch from "../../../constant/dispatch";
import PieChart from "../../reusable/Chart/PieChart";
import BarChart from "../../reusable/Chart/BarChart";
import TextList from "../../reusable/Chart/TextList";

const styles = theme => ({
    card: {
        height: "100%",
        position: "relative"
    },
    cardContent: {
        position: "absolute",
        bottom: 0
    }
});

class SurveyResultDisplay extends React.Component {
    
    chartDataFormat(options, answers) {
        return answers.reduce((accumulator, current) =>
                accumulator.map(value => value[0] === current ? [value[0], value[1]+1] : [value[0], value[1]]),
            [["Answer", "Amount"]].concat(options.map(option => [option, 0]))
        )
    }
    
    render() {
        const { classes, question, answers, index } = this.props
        
        return <Card className={classes.card}>
	        <CardHeader title={<Typography variant="subtitle1">{(index + 1) + ". " + question.prompt}</Typography>}/>
            <CardContent>
                {/*<div className={classes.cardContent}>*/}
                    {
                        question.type === "OPTION" ?
                            <BarChart data={this.chartDataFormat(["1", "2", "3", "4", "5"], answers)}/> :
                        question.type === "MCQ" ?
                            <PieChart data={this.chartDataFormat(question.options, answers)}/> :
                        question.type === "OPENENDED" &&
                            <TextList data={answers} />
                            
                    }
                {/*</div>*/}
            </CardContent>
        </Card>
    }
}

const mapStateToProps = function(storage) {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles),
)(SurveyResultDisplay);
