import React from "react";
import {connect} from "react-redux";
import {compose} from "recompose";
import {push} from "connected-react-router";

import {withStyles} from "@material-ui/core/styles";

import {DropzoneDialog} from 'material-ui-dropzone'
import QRCode from 'qrcode.react';

import {DialogTitle, DialogContent, DialogActions} from "./Dialog"

import Dispatch from "../../constant/dispatch";
import qrCode from "../../reducer/qrCodeReducer";
import Palette from "../../util/Palette";
import {IoIosAdd} from "react-icons/io";

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    menu: {
        width: 200,
    },
});

class ImageDropzoneDialog extends React.Component {

    render() {
        return <DropzoneDialog
            open={this.props.isOpen}

            onChange={(item) => {

                const reader = new FileReader();
                reader.addEventListener('load', () =>{
                    this.props.onImageUploaded(reader.result)
                });
                reader.readAsDataURL(item[0]);
            }}

            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}

            acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
            showPreviews={true}
            maxFileSize={5000000}

            submitButtonText={""}
            filesLimit={1}
            onClose={this.props.onClose}
            showFileNamesInPreview={true}/>
    }
}

export default compose(
    withStyles(styles),
)(ImageDropzoneDialog);
