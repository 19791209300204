import axios from "axios"
import { push } from "connected-react-router"

import storage from "../storage/storage"
import Dispatch from "../constant/dispatch"
import server from "../constant/server"
import UserAuth from "../util/UserAuth"
import { SnackBarVariant } from "../component/reusable/SnackBar"
import { AnnouncementOutlined } from "@material-ui/icons"

export default {
	getNews: eventId => {
		axios.get(server + "/events/" + eventId + "/news/admin")
			.then(response => {
				storage.dispatch({type: Dispatch.ON_GET_NEWS_RESPONSE_SUCCESS, payload: response.data.map(news => {
					return {
						...news,
					}
				})})
			})
			.catch(err => {
				console.log(err.response)
				storage.dispatch({type: Dispatch.ON_GET_NEWS_RESPONSE_FAIL})
				if(err.response === undefined)
				storage.dispatch({
					type: Dispatch.ENQUEUE_SNACKBAR,
					payload: {
						id: Math.round(Math.random()*1000),
						message: "Failed to retrieve data",
						type: SnackBarVariant.ERROR,
						options: [{
							text: "RETRY",
							action: () => storage.dispatch({
								type: Dispatch.ON_GET_NEWS_REQUEST,
								payload: eventId
							})
						}]
					}
				})
			})
	},

	createNews: news => {
		delete news.id
		axios.post(server + "/news", news)
			.then(response => {
				//update the table from the database
				storage.dispatch({
					type: Dispatch.ON_SAVE_NEWS_RESPONSE_SUCCESS,
					payload: storage.getState().eventManager.currentEvent.id
				})
				storage.dispatch({type: Dispatch.CLOSE_VIEW_NEWS_DIALOG})
				console.log("EVENTID:",storage.getState().eventManager.currentEvent.id )
			})
			.catch(err => {
				console.log(err.response)
				storage.dispatch({type: Dispatch.ON_SAVE_NEWS_RESPONSE_FAIL})
			})
	},

	updateNews : news => {
		axios.put(server + "/news/" + news.id,  news)
			.then(response => {
				storage.dispatch({type: Dispatch.ON_SAVE_NEWS_RESPONSE_SUCCESS, payload: storage.getState().eventManager.currentEvent.id})
				storage.dispatch({type: Dispatch.CLOSE_VIEW_NEWS_DIALOG})
			})
			.catch(err => {
				console.log(err.response)
				storage.dispatch({type: Dispatch.ON_SAVE_NEWS_RESPONSE_FAIL})
			})
	},
    updateNewsImage: (params, fieldKey, image)=>{

		console.log(params)

        let formData = new FormData();
        formData.append('upload',image[0])
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        axios.post(server + `/news/${params.id}/IMAGE/upload`, formData, config)
            .then(response => {
                storage.dispatch({type: Dispatch.ON_UPDATE_NEWS_IMAGE_RESPONSE_SUCCESS, payload:{
                        location : response.data.location,
                        fieldKey : fieldKey
                    }})
            })
            .catch(err => {
                console.log(err.response)
                storage.dispatch({type: Dispatch.ON_UPDATE_SESSION_IMAGE_RESPONSE_FAIL})
            })
    },
	deleteNews: booth => {
		axios.delete(server + "/booths/" + booth.id)
			.then(response => {
				console.log("booth deleted", response)
				storage.dispatch({type: Dispatch.ON_DELETE_BOOTH_BY_ID_RESPONSE_SUCCESS, payload: booth})
			})
			.catch(err => {
				console.log(err.response)
				storage.dispatch({type: Dispatch.ON_DELETE_BOOTH_BY_ID_RESPONSE_FAIL})
			})
	},
}
