import storage from "../storage/storage"
import { push } from "connected-react-router"
import axios from "axios"

import { SnackBarVariant, generateSnackBar } from "../component/reusable/SnackBar"
import Dispatch from "../constant/dispatch";

export default {
	handleTokenReceived: token => {
		localStorage.setItem("jwtToken", token)
		axios.defaults.headers.common["Authorization"] = "Bearer " + token
		axios.defaults.headers.common["Content-Type"] = "application/json"
	},
	
	handleUserNotLoggedIn: props => {
		localStorage.setItem("tempUrl", props.location.pathname)
		storage.dispatch(push("/login"))
	},
	
	handleUserLogOut: () => {
		delete localStorage.jwtToken
		delete localStorage.tempUrl
	}
}