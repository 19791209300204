import moment from "moment"

class Event {
    constructor() {
        this.id = -1
        this.name = "" //ada //WAJIB
        this.type = "type1" //smtr gk ada
        this.start_time = moment() // ada //WAJIB
        this.end_time = moment() // ada //WAJIB
        this.pricingPlan = "plan3" //smtr gk ada
        this.event_banner_url = ""
        this.event_map_url = null //kosongin dlu
        this.icon = null // kosonign dlu
        this.venue = "" // nanti gw tambahin //WAJIB
        this.targetAudience = "targetAudience1" // smtr gk ada
        this.short_description = "" // nanti gw tambahin
        this.description = "" // ada
        this.price = 0 // smtr gk ada
        this.pic_name = "" // nanti gw tambahin
        this.pic_phone = "" // nanti gw tambahin
        this.pic_email = "" // nanti gw tambahin
        this.color_primary = "#fff"
        this.color_dark = "#fff"
        this.event_code = "ASDFAS"
        this.event_icon_url = null

        this.role_id = -1
    }

	static isDataValid(event) {
		if(
			event.hasOwnProperty("name") && event.hasOwnProperty("start_time") &&
            event.hasOwnProperty("end_time") && event.hasOwnProperty("venue")
		) {
			return event.name !== "" && event.venue !== ""
		}
		return false
	}
}

export default Event;
