import React, { Component } from 'react'
import { connect } from "react-redux"
import { compose } from "recompose"

import { withStyles } from '@material-ui/core/styles';
import {
	Grid,
	Tooltip,
	Button
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";

import Dispatch from "../../constant/dispatch";

const styles = theme => ({
	root: {
		display: 'flex',
	},
	dataTable: {
		width: "100%"
	},
	button: {
		margin: theme.spacing(1),
		fontSize: "12px"
	},
});

class DataTable extends Component {
	
	render() {
		const {
			title,
			classes,
			data,
			columns,
			createNew,
			onRowClick,
			onRowsDelete,
			...rest
		} = this.props
		
		const options = {
			selectableRows: "none",
			download: false,
			print: false,
			...rest
		};
		
		if(createNew) {
			options.customToolbar = () => <Tooltip title={"Create New"}>
				<Button className={classes.button} onClick={() => createNew()}>
					+ CREATE NEW
				</Button>
			</Tooltip>
		}
		
		if(onRowClick) {
			options.onRowClick = (rowData, rowMeta) => onRowClick(data.find(d => d.id === rowData[0]))
		}
		
		if(onRowsDelete) {
			options.onRowsDelete = rowsDeleted => rowsDeleted.data.forEach(row => onRowsDelete(data[row.dataIndex]))
			// options.onRowsDelete = rowsDeleted => console.log(rowsDeleted)
		}
		
		return <Grid className={classes.dataTable}>
			<MUIDataTable
				title={title}
				data={data}
				columns={columns}
				options={options}
			/>
		</Grid>
	}
}

const mapStateToProps = function(storage) {
	return {
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
	}
}

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(styles)
)(DataTable)
