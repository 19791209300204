class Question {
    constructor(id) {
        this.id = id //WAJIB
        this.prompt = "" //WAJIB
        this.type = "GUIDE" //WAJIB
        this.options = []
    }
	
	static isDataValid(question) {
		if(
		    question.hasOwnProperty("id") && question.hasOwnProperty("prompt")
		) {
			return question.prompt !== ""
		}
		return false
	}
}

export default Question;
