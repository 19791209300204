import axios from "axios"

import storage from "../storage/storage"
import Dispatch from "../constant/dispatch"
import server from "../constant/server"

export default {
	getSurveys: eventId => {
		axios.get(server + "/events/" + eventId + "/surveys")
			.then(response => {
				console.log("survey data", response)
				storage.dispatch({type: Dispatch.ON_GET_SURVEYS_RESPONSE_SUCCESS, payload: response.data.map(survey => {
					return {
						...survey,
						question_json: survey.question_json.map((question, index) => {
							return {
								...question,
								id: question.id ? question.id : index
							}
						})
					}
				})})
			})
			.catch(err => {
				console.log(err.response)
				storage.dispatch({type: Dispatch.ON_GET_SURVEYS_RESPONSE_FAIL})
			})
	},
	
	getSurveyResults: survey => {
		axios.get(server + "/answer/" + survey.id)
			.then(response => {
				console.log("survey answer data", response)
				storage.dispatch({type: Dispatch.ON_GET_SURVEY_RESULTS_RESPONSE_SUCCESS, payload: {
					...survey,
					answers: response.data.map(row => row.answer_json)
				}})
			})
			.catch(err => {
				console.log(err.response)
				storage.dispatch({type: Dispatch.ON_GET_SURVEY_RESULTS_RESPONSE_FAIL})
			})
	},
	
	createSurvey: survey => {
		delete survey.id
		console.log("debug", survey)
		axios.post(server + "/question", survey)
			.then(response => {
				console.log("survey created", response)
				storage.dispatch({
					type: Dispatch.ON_SAVE_CURRENT_SURVEY_RESPONSE_SUCCESS,
					payload: storage.getState().eventManager.currentEvent.id
				})
				storage.dispatch({type: Dispatch.CLOSE_VIEW_SURVEY_DIALOG})
			})
			.catch(err => {
				console.log(err.response)
				storage.dispatch({type: Dispatch.ON_SAVE_CURRENT_SURVEY_RESPONSE_FAIL})
			})
	},
	
	updateSurvey: survey => {
		axios.patch(server + "/question/" + survey.id, survey)
			.then(response => {
				console.log("survey updated", response)
				storage.dispatch({type: Dispatch.ON_SAVE_CURRENT_SURVEY_RESPONSE_SUCCESS, payload: storage.getState().eventManager.currentEvent.id})
				storage.dispatch({type: Dispatch.CLOSE_VIEW_SURVEY_DIALOG})
			})
			.catch(err => {
				console.log(err.response)
				storage.dispatch({type: Dispatch.ON_SAVE_CURRENT_SURVEY_RESPONSE_FAIL})
			})
	},
	
	deleteSurvey: survey => {
		axios.delete(server + "/question/" + survey.id)
			.then(response => {
				console.log("survey deleted", response)
				storage.dispatch({type: Dispatch.ON_DELETE_SURVEY_BY_ID_RESPONSE_SUCCESS, payload: survey})
			})
			.catch(err => {
				console.log(err.response)
				storage.dispatch({type: Dispatch.ON_DELETE_SURVEY_BY_ID_RESPONSE_FAIL})
			})
	},
}