import {Dialog, IconButton, SnackbarContent, Tooltip} from "@material-ui/core";
import clsx from "clsx";
import React, {useState} from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {FaEdit, FaTrashAlt, FaFileUpload} from "react-icons/fa";
import Button from "@material-ui/core/Button";
import storage from "../../storage/storage";
import Dispatch from "../../constant/dispatch";
import {Edit} from "@material-ui/icons";
import Modal from "@material-ui/core/Modal";
import {DialogActions, DialogContent, DialogTitle} from "./Dialog";
import KeyValueEditorModal from "../dashboard/Books/KeyValueEditorModal";
import NewImageUploader from "./NewImageUploader";

export default function KeyValueEditor(props) {

    const {className, ...other} = props;

    const [jsonData, setJsonData] = useState({...props.jsonData});
    const [toggler, setToggler] = useState(0)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [activeKey, setActiveKey] = useState("")
    const [activeValue, setActiveValue] = useState("")

    function createData(name, calories, fat, carbs, protein) {
        return {name, calories, fat, carbs, protein};
    }

    function triggerRender() {
        setToggler(toggler + 1)
    }

    function deleteRow(key) {

        console.log("deleting " + key + "....")
        delete jsonData[key];
        setJsonData(jsonData)
        triggerRender()

    }

    function openModal(key) {

        setIsModalOpen(true)

        if (key) {
            setActiveKey(key)
            setActiveValue(jsonData[key])
        } else {
            setActiveKey("")
            setActiveValue("")
        }
    }

    function closeModal() {

        setActiveKey("")
        setActiveValue("")
        setIsModalOpen(false)

    }

    function handleSaveData(key, value) {

        console.log(key + " value is " + value)

        editRow(key, value)
        closeModal()

    }

    function editRow(key, newValue) {

        let temp = {...jsonData}
        delete temp[activeKey]

        temp[key] = newValue

        console.log(key + " value is " + newValue)
        console.log(temp)

        setJsonData(temp)

        props.onChange(temp)
        triggerRender()

    }

    function onImageUploaded(activeKey, newValue){
        console.log("acitve key" + activeKey)
        editRow(activeKey, newValue)
    }

    return (
        <div>

            <KeyValueEditorModal
                activeKey={activeKey}
                activeValue={activeValue}
                isOpen={isModalOpen}
                onClose={() => closeModal()}
                handleSaveData={(k, v) => handleSaveData(k, v)}
            />

            <Paper style={{
                alignItems: "flex-end"
            }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{width: "30%"}}>Field</TableCell>
                            <TableCell style={{width: "50%"}} align="left">Content</TableCell>
                            <TableCell style={{width: "20%"}} align="left">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(jsonData).map((key) => (
                            <TableRow key={key}>
                                <TableCell component="th" scope="row">
                                    {key}
                                </TableCell>
                                <TableCell align="left">{jsonData[key]}</TableCell>
                                <TableCell align="left">
                                    <Tooltip title="Edit">
                                        <IconButton
                                            onClick={() => openModal(key)}>
                                            <FaEdit size={16}/>
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Delete">
                                        <IconButton
                                            onClick={() => deleteRow(key)}>
                                            <FaTrashAlt size={16}/>
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Upload Image">
                                        <NewImageUploader
                                            onImageCropperSubmit={(image) => {
                                                props.onImageUpload(image, key, (newValue)=>onImageUploaded(key, newValue))
                                            }}
                                        >
                                            <IconButton
                                                // onClick={() => console.log("Upload Image")}
                                            >
                                                <FaFileUpload size={16}/>
                                            </IconButton>
                                        </NewImageUploader>

                                    </Tooltip>


                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell component="th" scope="row"/>
                            <TableCell component="th" scope="row"/>
                            <TableCell component="th" scope="row">
                                <Button variant={"contained"} color={"primary"} onClick={() => openModal()}>
                                    + Add Row
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>


            </Paper>

        </div>
    );
}
