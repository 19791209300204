 // export default "http://192.168.1.2:2000"
// export default "https://intellivent.id/eduapi"

// export default "https://intellivent.id/test-api"
// export default "http://intellivent.id:2020"
//export default "https://api.intellivent.id/"
//export default "http://157.230.39.148:2020"
// export default "https://intellivent.id/ibf-api"

export default "https://neo-api.patjarmerah.com/"
// export default "https://patjarmerah-dev-api.intellivent.id/"
// export default "https://intellivent.id/patjarmerahSemarang"
