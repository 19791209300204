import React, { Component } from 'react'
import { connect } from "react-redux"
import { compose } from "recompose"

import { withStyles } from '@material-ui/core/styles';
import {
    Grid, Typography,
} from "@material-ui/core";
import Dispatch from "../../../constant/dispatch";
import ReactCursorPosition from "react-cursor-position";

const styles = theme => ({
	root: {
		display: 'flex',
	},
});

class Certificate extends Component {

	render() {
		const classes = this.props.classes
		return <div className={classes.root}>
			<Grid container spacing={4}>
                <Grid item xs={12}>
                    <ReactCursorPosition className="example">
                        <img
                            src="https://intellivent.sgp1.digitaloceanspaces.com/sertifikat.png"
                            alt="new"
                            style={{
                                width:"100%"
                            }}
                        />
                    </ReactCursorPosition>
					{`x: ${this.props.x} y:${this.props.y}`}
                </Grid>
			</Grid>
		</div>
	}
}

const mapStateToProps = function(storage) {
	return {
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
	}
}

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(styles)
)(Certificate)
