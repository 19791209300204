import React, { Component } from 'react'
import { connect } from "react-redux"
import { compose } from "recompose"

import { withStyles } from '@material-ui/core/styles';
import {
	Grid,
	Tooltip,
	Button,
	Typography,
	Card,
	CardActionArea,
	CardMedia,
	CardContent,
	CardActions,
	IconButton
} from "@material-ui/core";
import {
	DeleteOutline
} from '@material-ui/icons';
import { DropzoneDialog } from 'material-ui-dropzone'

const styles = theme => ({
	card: {
		maxWidth: 345,
	},
	media: {
		height: 140,
	},
});


class UploadImage extends Component {

	constructor(props) {
		super(props)
		this.state = {
			isOpen: false,
			files: [],
			binaries: []
		}
	}

	handleImageSave(files) {
		this.setState({
			isOpen: false,
			files: files,
			binaries: files.map(file => URL.createObjectURL(file))
		});
	}

	deleteImageWithIndex(i) {
		this.setState({
			files: this.state.files.filter((_, index) => index !== i),
			binaries: this.state.binaries.filter((_, index) => index !== i)
		})
	}

	render() {
		const { title, limit, classes } = this.props
		return <Grid>
			<Typography color="textSecondary" variant="caption">{title}</Typography>
			<br/>
			{/*{*/}
				{/*this.state.files.map((file, index) => <Card className={classes.card}>*/}
					{/*<CardMedia*/}
						{/*className={classes.media}*/}
						{/*image={this.state.binaries[0]}*/}
						{/*title={file.name}*/}
					{/*/>*/}
					{/*<CardActions>*/}
						{/*<Typography variant="body2" color="textSecondary" component="p">*/}
							{/*{file.name}*/}
						{/*</Typography>*/}
						{/*<Tooltip title="Delete">*/}
							{/*<IconButton onClick={() => this.deleteImageWithIndex(index)}>*/}
								{/*<DeleteOutline/>*/}
							{/*</IconButton>*/}
						{/*</Tooltip>*/}
					{/*</CardActions>*/}
				{/*</Card>)*/}
			{/*}*/}
            <Card className={classes.card}>
                <CardMedia
                    className={classes.media}
                    image={this.props.image}
                    // title={file.name}
                />
            </Card>
			<Button
				variant="outlined"
				color="primary"
				onClick={
					() => this.setState({isOpen: true})
				}
				style={{
					top:5
				}}
				className={classes.button}>
				UPLOAD
			</Button>

			<DropzoneDialog
				open={this.state.isOpen}
				onSave={files => {
					this.props.onImageUploaded(this.props.event, files)
					this.handleImageSave(files)
				}}
				style={{
					display:"flex",
					justifyContent : "center",
					alignItems : "center"
				}}
				acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
				showPreviews={true}
				maxFileSize={5000000}

				filesLimit={limit}
				onClose={() => this.setState({isOpen: false})}
				showFileNamesInPreview={true} />
		</Grid>
	}
}

const mapStateToProps = function(storage) {
	return {
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
	}
}

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(styles)
)(UploadImage)
