import React from "react"
import moment from "moment"
import {
	Tooltip,
	IconButton
} from "@material-ui/core";
import {
	Email,
	Send,
	Flag
} from '@material-ui/icons';
import storage from "../../../../storage/storage"
import Dispatch from "../../../../constant/dispatch"
import Typography from "@material-ui/core/Typography";
import _ from 'lodash'

export default [
	{
		name: "id",
		label: "id",
		options: {
			display: "false",
			filter: false,
			sort: false,
			viewColumns: false,
		}
	},
	{
		name: "full_name",
		label: "Name",
		options: {
			filter: false,
			sort: true,
			// customBodyRender: value => {
			//
			// 	const index = value.indexOf(" ");
			// 	return  value.substring(0,index) + " " + value.substring(index).replace(/./g, "*")
			// }
		}
	},
	{
		name: "date_of_birth",
		label: "Date of Birth",
		options: {
			filter: true,
			sort: true,
			customBodyRender: value => moment(value).format("DD/MM/YYYY")
		}
	},
	{
		name: "id",
		label: "Actions",
		options: {
			filter: false,
			sort: false,
			customBodyRender: value => <>
				<Tooltip title="Email (You need premium feature to unlock this)">
					<IconButton>
						<Email />
					</IconButton>
				</Tooltip>
				<Tooltip title="Notify (You need premium feature to unlock this)">
					<IconButton>
						<Send />
					</IconButton>
				</Tooltip>
				<Tooltip title="Mark">
					<IconButton>
						<Flag />
					</IconButton>
				</Tooltip>
			</>
		}
	},
]
