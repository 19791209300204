import React from "react"
import {
	Tooltip,
	IconButton
} from "@material-ui/core";
import {
    VerticalAlignBottom,
    Edit, Delete
} from '@material-ui/icons';
import Dispatch from "../../../../constant/dispatch";
import storage from "../../../../storage/storage";

export default [
	{
		name: "id",
		label: "id",
		options: {
			display: "false",
			filter: false,
			sort: false,
			viewColumns: false,
		}
	},
	{
		name: "title",
		label: "Title",
		options: {
			filter: false,
			sort: true,
		}
	},
	{
		name: "author",
		label: "Author",
		options: {
			filter: true,
			sort: true,
		}
	},
    {
        name: "publisher",
        label: "Publisher",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "price",
        label: "price",
        options: {
            filter: false,
            sort: true,
        }
    },
	{
		name: "id",
		label: "Options",
		options: {
			filter: false,
			sort: false,
			customBodyRender: (value) => {
				return <>
					<Tooltip title="Edit">
						<IconButton
							onClick={() => storage.dispatch({
								type: Dispatch.OPEN_VIEW_BOOK_DIALOG,
								payload: value
							})}>
							<Edit />
						</IconButton>
					</Tooltip>
                    <Tooltip title="Remove">
                        <IconButton
                            onClick={() => storage.dispatch({
                                type: Dispatch.ON_DELETE_BOOK_BY_ID_REQUEST,
                                payload: {...storage.getState().book.books.find(book=> book.id === value)}
                            })}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
				</>
			}
		}
	},
]
