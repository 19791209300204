import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { push } from "connected-react-router";

import { withStyles } from "@material-ui/core/styles";
import {
	Dialog,
	TextField,
	Typography,
	Slide,
	Grid,
	Divider,
	MenuItem,
	Button,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	Tooltip,
	Card,
	CardHeader,
	CardContent,
	CardActions,
} from "@material-ui/core";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import {
	Add,
	DeleteOutline
} from '@material-ui/icons';

import { DialogTitle, DialogContent, DialogActions } from "../../reusable/Dialog"

import Dispatch from "../../../constant/dispatch";
import { questionType } from "./config/questionDataDisplayConfig";
import {SnackBarVariant} from "../../reusable/SnackBar";
import QuestionModel from "../../../model/Question";

const styles = theme => ({
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	menu: {
		width: 200,
	},
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

class CreateEventDialog extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			...this.props.currentQuestion,
			selectedOption: {
				index: -1,
				prompt: ""
			},
			isError: false
		}
	}
	
	handleSaveData() {
		if(
			QuestionModel.isDataValid(this.state) &&
			(this.props.currentQuestion.type !== "MCQ" || this.props.currentQuestion.options.length !== 0)
		) {
			this.props.saveCurrentQuestion()
		} else {
			this.setState({isError: true})
			this.props.enqueueSnackBar({
				message: "Data is not complete",
				type: SnackBarVariant.ERROR,
			})
		}
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if(!prevProps.isOpen && this.props.isOpen) {
			this.setState({
				...this.props.currentQuestion
			})
		}
	}
	
	onClose() {
		this.props.closeDialog()
	}
	
	render() {
		const { classes, isOpen, currentQuestion } = this.props;
		
		return (
			<Dialog
				open={isOpen}
				maxWidth="sm"
				fullWidth={true}
				TransitionComponent={Transition}>
				<DialogTitle onClose={() => this.onClose()}>
					Question Details
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={0}>
						<Grid item xs={11}>
							<TextField
								label="Prompted Question"
								className={classes.textField}
								value={this.state.prompt}
								onChange={evt => this.setState({prompt: evt.target.value})}
								onBlur={evt => this.props.editCurrentQuestion("prompt", evt.target.value)}
								placeholder=""
								fullWidth
								margin="normal"
								error={this.state.isError && !this.state.prompt}
								InputLabelProps={{
									shrink: true,
								}} />
						</Grid>
					</Grid>
					<Grid container spacing={0}>
						<Grid item xs={5}>
							<TextField
								select
								fullWidth
								label="Question Type"
								className={classes.textField}
								value={currentQuestion.type}
								onChange={evt => this.props.editCurrentQuestion("type", evt.target.value)}
								SelectProps={{
									MenuProps: {
										className: classes.menu,
									},
								}}
								margin="normal">
								{questionType.map(type => (
									<MenuItem key={type.value} value={type.value}>
										{type.label}
									</MenuItem>
								))}
							</TextField>
						</Grid>
					</Grid>
					{
						currentQuestion.type === "MCQ" && <List className={classes.textField}>
							<Typography
								color={this.state.isError && !this.state.title ? "error" : "textSecondary"}
								variant="caption">
								Options
							</Typography>
							{
								currentQuestion.options.map((option, index) => <>
									<Divider/>
									{
										index === this.state.selectedOption.index ? <ListItem>
											<TextField
												className={classes.textField}
												value={this.state.selectedOption.prompt}
												onChange={evt => this.setState({selectedOption: {
													...this.state.selectedOption,
													prompt: evt.target.value
												}})}
												onBlur={evt => {
													this.props.editCurrentQuestion("options", currentQuestion.options.map((option, i) =>
														this.state.selectedOption.index === i ? evt.target.value : option
													).filter(option => option !== ""))
													this.setState({selectedOption: {
														index: -1,
														prompt: ""
													}})
												}}
												placeholder=""
												fullWidth
												autoFocus={true}
												margin="normal" />
											<Button
												variant="text"
												color="primary"
												onClick={() => {
													this.props.editCurrentQuestion("options", currentQuestion.options.map((option, i) =>
														this.state.selectedOption.index === i ? this.state.selectedOption.prompt : option
													).filter(option => option !== ""))
													this.setState({selectedOption: {
														index: -1,
														prompt: ""
													}})
												}}
												className={classes.button}>
												SAVE
											</Button>
										</ListItem> : <ListItem
											button
											onClick={() => this.setState({selectedChoices: {
												prompt: option,
												index: index
											}})}>
											<Typography color="textSecondary">
												{option + " "}
											</Typography>
											<ListItemSecondaryAction>
												<Tooltip title="Delete">
													<IconButton onClick={() => this.props.editCurrentQuestion(
														"options",
														currentQuestion.options.filter((_, i) => index !== i)
													)}>
														<DeleteOutline/>
													</IconButton>
												</Tooltip>
											</ListItemSecondaryAction>
										</ListItem>
									}
								</>)
							}
							<Divider/>
							<ListItem
								button
								onClick={() => {
									this.props.editCurrentQuestion("options", currentQuestion.options.concat(""))
									this.setState({selectedOption: {
										index: currentQuestion.options.length,
										prompt: ""
									}})
								}} >
								<Add/>
								<Typography color="textSecondary">Add an Option</Typography>
							</ListItem>
							<Divider/>
						</List>
					}
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						color="primary"
						onClick={() => this.handleSaveData()}
						className={classes.button}>
						SUBMIT
					</Button>
					<Button
						variant="text"
						color="primary"
						onClick={() => this.onClose()}
						className={classes.button}>
						CANCEL
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

const mapStateToProps = function(storage) {
	return {
		isOpen: storage.appState.isViewQuestionDialogOpen,
		currentQuestion: storage.survey.currentQuestion
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setUrl: url => dispatch(push(url)),
		editCurrentQuestion: (key, value) => dispatch({
			type: Dispatch.EDIT_CURRENT_QUESTION_VALUE,
			payload: {
				key: key,
				value: value
			}
		}),
		saveCurrentQuestion: () => dispatch({
			type: Dispatch.SAVE_CURRENT_QUESTION
		}),
		closeDialog: () => dispatch({
			type: Dispatch.CLOSE_VIEW_QUESTION_DIALOG
		}),
		enqueueSnackBar: snackBar => dispatch({
			type: Dispatch.ENQUEUE_SNACKBAR,
			payload: snackBar
		})
	}
}

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(styles),
)(CreateEventDialog);