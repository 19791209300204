import React, {Component} from 'react'
import {connect} from "react-redux"
import {compose} from "recompose"

import {withStyles} from '@material-ui/core/styles';
import {
    Grid,
    Card,
    CardContent,
    Tooltip,
    Typography, CardHeader, Button, Dialog, Slide
} from "@material-ui/core";

import {
    Form
} from "semantic-ui-react"
import {BlockPicker} from 'react-color';

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Paper from "@material-ui/core/Paper";
import UploadImage from "../../reusable/UploadImage";
import {DropzoneDialog} from "material-ui-dropzone";
import Palette from "../../../util/Palette";
import {IoIosAdd} from "react-icons/io";
import {DialogActions, DialogContent, DialogTitle} from "../../reusable/Dialog";

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Dispatch from "../../../constant/dispatch";
import NewImageUploader from "../../reusable/NewImageUploader";
import {KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import KeyboardDateInput from "@material-ui/pickers/_shared/KeyboardDateInput";

const styles = theme => ({
    root: {
        display: 'flex',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
});

class CompleteSetupGuide extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeStep : this.getInitialStep(),
            step1: {
                isUploadPictureOpen: false,
                isCropPictureOpen: false,
                crop: {
                    aspect: 1,
                    unit: '%',
                    width: 30,
                },
                imageBlob: null,
                imageUrl: null,
                src: null
            }
        }

        console.log("acitve step is "+ this.state.activeStep)

    }

    getInitialStep(){
        let steps  = this.getSteps()

        let activeStep = 0;

        for(let s in steps){
            let step = steps[s]
            // console.log("s " + s)
            if(step.completeCondition){
                activeStep++
            }else{
                return activeStep
            }
        }

    }

    getSteps() {

        const {classes,currentEvent} = this.props

        console.log("this props here is ", currentEvent)

        return [
            {
                label: "Complete Your Event Assets",
                completeCondition : !!currentEvent.event_icon_url,
                component: <>
                <div style={{
                        display : "flex",
                        flexDirection : "column"
                    }}>

                        <NewImageUploader
                            placeholder={"Recommended Minimum Size 300x300"}
                            src={this.props.currentEvent.event_icon_url}
                            aspect = {1}
                            onImageCropperSubmit={(image) => {
                                this.uploadImageIcon(image)
                            }}
                        />
                        Event Icon *

                        <NewImageUploader
                            style={{
                                marginTop : 20
                            }}
                            placeholder={"Recommended Minimum Size 1024x500"}
                            src={this.props.currentEvent.event_banner_url}
                            width={300}
                            height={150}
                            aspect = {16/9}
                            onImageCropperSubmit={(image) => {
                                this.uploadImageBanner(image)
                            }}

                        />
                        Event Banner<br/>

                        <NewImageUploader
                            style={{
                                marginTop : 20
                            }}
                            placeholder={"Recommended Minimum Size 1024x1024"}
                            src={this.props.currentEvent.event_map_url}

                            width={300}
                            height={150}

                            aspect = {null}

                            onImageCropperSubmit={(image) => {
                                this.uploadImageMap(image)
                            }}

                        />
                        Event Map<br/>


                    </div>
                </>,
            },
            {
                label: "Complete Your Venue Data",
                completeCondition : false,
                component: <Grid container>
                    <Grid item xs={12}>
                        <div style={{
                            fontSize : "16px",
                            marginTop : 12
                        }}>
                            Starting / Ending Date
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            {/*<KeyboardDateInput*/}
                            {/*    margin="normal"*/}
                            {/*    label="Start Date"*/}
                            {/*    value={currentEvent.start_time}*/}
                            {/*    onChange={date => this.setState({start_time: date})}*/}
                            {/*    className={classes.textField}*/}
                            {/*    error={!currentEvent.start_time}*/}
                            {/*    KeyboardButtonProps={{*/}
                            {/*        'aria-label': 'change time',*/}
                            {/*    }} />*/}
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            {/*<KeyboardDateInput*/}
                            {/*    margin="normal"*/}
                            {/*    label="End Date"*/}
                            {/*    value={currentEvent.end_time}*/}
                            {/*    onChange={date => this.setState({end_time: date})}*/}
                            {/*    className={classes.textField}*/}
                            {/*    error={!currentEvent.end_time}*/}
                            {/*    KeyboardButtonProps={{*/}
                            {/*        'aria-label': 'change time',*/}
                            {/*    }} />*/}
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12}>
                        <div style={{
                            fontSize : "16px",
                            marginTop : 12
                        }}>
                            Opening / Closing Time
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardTimePicker
                                margin="normal"
                                label="Open Time"
                                value={currentEvent.start_time}
                                onChange={date => this.setState({start_time: date})}
                                className={classes.textField}
                                error={!currentEvent.start_time}
                                KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                }} />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardTimePicker
                                margin="normal"
                                label="Close Time"
                                value={currentEvent.end_time}
                                onChange={date => this.setState({end_time: date})}
                                className={classes.textField}
                                error={!currentEvent.end_time}
                                KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                }} />
                        </MuiPickersUtilsProvider>
                    </Grid>

                </Grid>,
            },
            {
                label: "Check Your Event On Your Device !",
                completeCondition : false,
                component: <Grid container>
                    <Grid item xs={12}>
                        <ol>
                            <li>Download Intellivent Application <a href="#" onClick={()=> window.open("https://play.google.com/store/apps/details?id=com.intellivent.i.ibf", "_blank")}>Here</a></li>
                            <li>Select "Search Event"</li>
                            <li>Select "Search Event by Event Code"</li>
                            <li>
                                Enter the following Code
                            </li>
                            <h2>{currentEvent.event_code}</h2>

                        </ol>
                    </Grid>

                </Grid>,
            }

        ]
    }

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({
            ...this.state,
            step1: {
                ...this.state.step1,
                crop: {
                    ...crop
                },
                isUploadPictureOpen: false
            }
        });
    };

    uploadImageIcon(image) {
        this.props.uploadImage(this.props.event, "event_icon", image)
    }

    uploadImageBanner(image){
        this.props.uploadImage(this.props.event, "event_banner", image)
    }

    uploadImageMap(image){
        this.props.uploadImage(this.props.event, "event_map", image)
    }

    render() {

        const classes = this.props.classes

        // console.log("is this dialog open ", this.state.step1)

        return <div className={classes.root}>

            <Grid container spacing={4}>

                <Grid item xs={12}>
                    <Typography variant="h5" color="textPrimary" className={classes.textField}>
                        Complete your Setup
                    </Typography>
                    <div>* You Will need to fill in all required items before the event can be published</div>

                    <Card style={{
                        marginTop: 10
                    }}>
                        <Stepper activeStep={this.state.activeStep} orientation="vertical">
                            {this.getSteps().map((step, index) => (
                                <Step key={index}>
                                    <StepLabel>{step.label}</StepLabel>
                                    <StepContent>
                                        {step.component}
                                        <div className={classes.actionsContainer}>
                                            <div>
                                                <Button
                                                    disabled={this.state.activeStep === 0}
                                                    className={classes.button}
                                                    onClick={()=>{
                                                        this.setState({
                                                            activeStep : this.state.activeStep - 1
                                                        })
                                                    }}
                                                >
                                                    Back
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.button}
                                                    onClick={()=>{
                                                        this.setState({
                                                            activeStep : this.state.activeStep + 1
                                                        })
                                                    }}
                                                >
                                                    {this.state.activeStep === this.getSteps().length - 1 ? 'Finish' :step.completeCondition? "Next" : "Skip"}
                                                </Button>
                                            </div>
                                        </div>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                        {this.activeStep === this.getSteps().length && (
                            <Paper square elevation={0} className={classes.resetContainer}>
                                <Typography>All steps completed - you&apos;re finished</Typography>
                                <Button onClick={console.log("yoe")} className={classes.button}>
                                    Reset
                                </Button>
                            </Paper>
                        )}
                    </Card>

                </Grid>
            </Grid>
        </div>
    }
}

const mapStateToProps = function (storage) {
    return {
        currentEvent: storage.eventManager.currentEvent,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        uploadImage: (event, fieldKey, image) => dispatch({
            type: Dispatch.ON_UPDATE_EVENT_IMAGE_FROM_CROP_REQUEST,
            payload: {event, fieldKey, image}
        })
    }
}

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withStyles(styles)
)(CompleteSetupGuide)

