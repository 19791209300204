import {Dialog, TextField} from "@material-ui/core";
import {DialogActions, DialogContent, DialogTitle} from "../../reusable/Dialog";
import Button from "@material-ui/core/Button";
import React, {useEffect, useState} from "react";

export default function KeyValueEditorModal({activeKey : initialKey, activeValue : initialValue, isOpen, onClose, handleSaveData}) {

    const [activeKey, setActiveKey] = useState(initialKey)
    const [activeValue, setActiveValue] = useState(initialValue)

    useEffect(() => {
        setActiveKey(initialKey)
    }, [initialKey]);

    useEffect(() => {
        setActiveValue(initialValue)
    }, [initialValue]);

    const saveData = () =>{
        handleSaveData(activeKey, activeValue)
        setActiveValue("")
    }

    return <Dialog
        open={isOpen}
        maxWidth="xl">
        <DialogTitle onClose={() => onClose()}>
            Edit Data
        </DialogTitle>
        <DialogContent>

            <TextField
                label="Field"
                value={activeKey}
                onChange={evt => setActiveKey(evt.target.value)}
                placeholder=""
                fullWidth
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }} />

            <TextField
                label="Content"
                value={activeValue}
                onChange={evt => setActiveValue(evt.target.value)}
                placeholder=""
                fullWidth
                margin="normal"
                multiline={true}
                InputLabelProps={{
                    shrink: true,
                }} />

        </DialogContent>
        <DialogActions>
            <Button
                variant="text"
                color="primary"
                onClick={() => onClose()}>
                Cancel
            </Button>
            <Button
                variant="contained"
                color="primary"
                onClick={() => saveData()}>
                Save
            </Button>
        </DialogActions>

    </Dialog>

}
