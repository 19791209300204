class Survey {
    constructor() {
        this.id = -1
        this.title = "" //WAJIB
        this.owner = ""//WAJIB
        this.introduction = ""//WAJIB
        this.question_json = []//WAJIB
	    this.enabled = 1

	    this.answers = []
    }


	static isDataValid(survey) {
        console.log("adf")

		if(
			survey.hasOwnProperty("title") && survey.hasOwnProperty("owner") &&
			survey.hasOwnProperty("introduction") && survey.hasOwnProperty("question_json")
		) {
			return survey.title !== "" && survey.owner !== "" &&
				survey.introduction !== "" && survey.question_json.length > 0
		}
		return false
	}
}

export default Survey;
