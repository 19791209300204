import axios from "axios"

import storage from "../storage/storage"
import Dispatch from "../constant/dispatch"
import server from "../constant/server"
import UserAuth from "../util/UserAuth"


export default {
	getEventActivities: eventId => {
		axios.get(server + "/activities/event/" + eventId)
			.then(response => {
				console.log("activity data get!", response)
				storage.dispatch({type: Dispatch.ON_GET_EVENT_ACTIVITIES_RESPONSE_SUCCESS, payload: response.data})
			})
			.catch(err => {
				console.log(err.response)
				storage.dispatch({type: Dispatch.ON_GET_EVENT_ACTIVITIES_RESPONSE_FAIL})
			})
    },
    getEventCheckIns:  eventId => {
		axios.get(server + "/events/" + eventId + "/checkins")
			.then(response => {
				storage.dispatch({type: Dispatch.ON_GET_EVENT_CHECKINS_RESPONSE_SUCCESS, payload: response.data})
			})
			.catch(err => {
				console.log(err.response)
				storage.dispatch({type: Dispatch.ON_GET_EVENT_CHECKINS_RESPONSE_FAIL})
			})
    },
    getEventQRHistory: eventId => {
        axios.get(server + "/events/" + eventId + "/scan_history")
			.then(response => {
				console.log("qr data get!", response)
				storage.dispatch({type: Dispatch.ON_GET_EVENT_QR_SCAN_HISTORY_RESPONSE_SUCCESS, payload: response.data})
			})
			.catch(err => {
				console.log(err.response)
				storage.dispatch({type: Dispatch.ON_GET_EVENT_QR_SCAN_HISTORY_RESPONSE_FAIL})
			})
    }
}
