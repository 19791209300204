import React from "react";
import {connect} from "react-redux";
import {compose} from "recompose";
import {push} from "connected-react-router";

import {withStyles} from "@material-ui/core/styles";
import {
    Dialog,
    TextField,
    Typography,
    Slide,
    Grid,
    Divider,
    MenuItem,
    Button,
    List,
    ListItem,
    Card,
    CardHeader,
    CardContent,
    CardActions,
} from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardTimePicker
} from '@material-ui/pickers';
import {
    Add,
    KeyboardArrowRight
} from '@material-ui/icons';
import {DropzoneDialog} from 'material-ui-dropzone'
import QRCode from 'qrcode.react';

import {DialogTitle, DialogContent, DialogActions} from "./Dialog"

import Dispatch from "../../constant/dispatch";
import qrCode from "../../reducer/qrCodeReducer";
import Palette from "../../util/Palette";
import {IoIosAdd} from "react-icons/io";
import ImageDropzoneDialog from "./ImageDropzoneDialog";
import ImageCropperDialog from "./ImageCropperDialog";

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    menu: {
        width: 200,
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class NewImageUploader extends React.Component {

    constructor(props) {
        super(props)

        let defaultCrop = {
            unit:"%",
            width:100,
            height:100
        }
        if(this.props.aspect){
            defaultCrop={
                aspect: this.props.aspect,
                unit: '%',
                width: 30,
            }
        }
        this.state = {
            isImageCropperOpen: false,
            isUploaderOpen: false,
            crop: {
                ...defaultCrop
            },
        }
    }

    onThumbnailClick() {
        console.log("ayam called")
        this.setState({
            isUploaderOpen: true
        })
    }

    render() {

        // console.log("states" , this.state)

        return <>

            {
                this.props.children ?
                    <div onClick={()=>this.onThumbnailClick()} style={{
                        display : "inline"
                    }}>
                        {this.props.children}
                    </div>
                    :
                    <Card onClick={() => this.onThumbnailClick()}
                          style={{
                              height: this.props.height ? this.props.height : 120,
                              width: this.props.width ? this.props.width : 120,
                              marginTop: 5,
                          }}>
                        {this.props.src ? <img style={{
                            height: this.props.height ? this.props.height : 120,
                            width: this.props.width ? this.props.width : 120,
                            objectFit:'cover'
                        }} src={this.props.src} alt='preview'/> : <div style={{
                            color: Palette.grey,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            objectFit:'contain',
                            height: this.props.height ? this.props.height : 120,
                            width: this.props.width ? this.props.width : 120
                        }}>
                            <IoIosAdd size="30%" style={{
                                color: Palette.grey
                            }}/>
                            <div style={{
                                textAlign: "center"
                            }}>
                                {this.props.placeholder}
                            </div>
                        </div>}
                    </Card>
            }

            <ImageDropzoneDialog
                isOpen={this.state.isUploaderOpen}
                onClose={() => {
                    this.setState({
                        isUploaderOpen: false
                    })
                }}

                onImageUploaded={(image) => {
                    this.setState({
                        isUploaderOpen: false,
                        isImageCropperOpen: true,
                        imageCropperSrc : image
                    })
                }}
            />

            <ImageCropperDialog
                isOpen={this.state.isImageCropperOpen}
                onClose={() => {
                    this.setState({
                        isImageCropperOpen: false,
                        isUploaderOpen: false
                    })
                }}

                onNegativeButtonPressed={() => {
                    this.setState({
                        isImageCropperOpen: false,
                        isUploaderOpen: true
                    }, function () {
                        console.log("after state change to ", this.state)
                    })
                }}

                src={this.state.imageCropperSrc}
                crop={this.state.crop}
                onSubmit={() => {
                    this.props.onImageCropperSubmit(this.state.imageBlob)
                    this.setState({
                        isImageCropperOpen: false,
                        isUploaderOpen: false
                    })
                }}
                negativeButtonText={"Back"}
                onImageCropped={(blob) => this.setState({
                    imageBlob: blob,
                    imageUrl: URL.createObjectURL(blob),
                }, ()=>console.log("after change" , this.state))}
                onCropChange={(crop) => this.setState({
                    ...this.state,
                    crop: {
                        ...crop
                    },
                })}

            />

        </>
    }
}

export default compose(
    withStyles(styles),
)(NewImageUploader);
