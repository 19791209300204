import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { push } from "connected-react-router";

import { withStyles } from "@material-ui/core/styles";
import {
	Dialog,
	TextField,
	Slide,
	Grid,
	Button,
	Checkbox,
	Switch,
	Typography,
	Tooltip
} from "@material-ui/core";

import RichText from "../../reusable/RichText/"
import { DialogTitle, DialogContent, DialogActions } from "../../reusable/Dialog"

import Dispatch from "../../../constant/dispatch";
import {SnackBarVariant} from "../../reusable/SnackBar";
import NewsModel from "../../../model/News";
import {KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

import MomentUtils from '@date-io/moment';
import UploadImage from "../../reusable/UploadImage";
import { CheckBox } from "@material-ui/icons";

const styles = theme => ({
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	menu: {
		width: 200,
	},
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

class ViewNewsDialog extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			...this.props.currentNews,
			isError: false
		}
	}

	handleSaveData() {
		if(NewsModel.isDataValid(this.props.currentNews)) { //previously uses this state... but why? on this point this.state content is not same with this.props.currentAnnoucnement
			this.props.saveCurrentNews(this.props.eventId)
		} else {
			this.setState({...this.state, isError: true})
			this.props.enqueueSnackBar({
				message: "Data is not complete",
				type: SnackBarVariant.ERROR,
			})
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(!prevProps.isOpen && this.props.isOpen) {
			console.log(this.props.currentNews)
			this.setState({
				...this.props.currentNews
			})
		}
	}

	onClose() {
		this.props.closeDialog()
	}

	render() {
		const { classes, isOpen, currentNews } = this.props;
		let tooltipText = this.props.currentNews.shown === 1 ? "Click to hide news" : "Click to publish news"
		return (
			<Dialog
				open={isOpen}
				maxWidth="md"
				fullWidth={true}
				TransitionComponent={Transition}>
				<DialogTitle onClose={() => this.onClose()}>
					News Details
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={4}>
						<Grid item xs={7}>
							<TextField
								label="Title"
								className={classes.textField}
								value={this.state.title}
								onChange={evt => this.setState({...this.state, title: evt.target.value})}
								onBlur={evt => this.props.editCurrentNews("title", evt.target.value)}
								placeholder=""
								fullWidth
								margin="normal"
								error={this.state.isError && !this.state.name}
								InputLabelProps={{
									shrink: true,
								}} />
						</Grid>
					</Grid>
					<Grid container spacing={4}>
						<Grid item xs={11}>
							<RichText
								style={{margin: "16px 8px 8px 8px"}}
								label="Content"
								value={currentNews.content}
								onBlur={value => this.props.editCurrentNews("content", value)} />
						</Grid>
					</Grid>
					<Grid container spacing={4}>
						<Grid item xs={4} className={classes.textField}>
							<UploadImage
								event={currentNews}
								image={currentNews.image}
								onImageUploaded={(event, image)=>{this.props.uploadImage(event,"image",image)}}
								title="Image" limit={1} />
						</Grid>                 
						<Grid item xs={4} className={classes.textField} label="Yeah">
							<Typography color="textSecondary" variant="caption">Visibility</Typography>
							<br/>
							<Tooltip title={tooltipText}>
								<Switch 
									checked={this.props.currentNews.shown === 1} 
									onChange={() => {this.props.editCurrentNews("shown", 1-this.props.currentNews.shown)}}
									color="primary"
								/>
							</Tooltip>{this.props.currentNews.shown === 1 ? <span>Published</span>: <span>Draft</span>}	

						</Grid>
					</Grid> 


				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						color="primary"
						onClick={() => this.handleSaveData()}
						className={classes.button}>
						SUBMIT
					</Button>
					<Button
						variant="text"
						color="primary"
						onClick={() => this.onClose()}
						className={classes.button}>
						CANCEL
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

const mapStateToProps = function(storage) {
	return {
        isOpen: storage.appState.isViewNewsDialogOpen,
		currentNews: storage.news.currentNews,
		eventId: storage.eventManager.currentEvent.id,
		roleId: storage.eventManager.currentEvent.role_id
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setUrl: url => dispatch(push(url)),
		closeDialog: () => dispatch({
			type: Dispatch.CLOSE_VIEW_NEWS_DIALOG
		}),
		enqueueSnackBar: snackBar => dispatch({
			type: Dispatch.ENQUEUE_SNACKBAR,
			payload: snackBar
		}),
        editCurrentNews: (key, value) => {
			dispatch({
                type: Dispatch.EDIT_CURRENT_NEWS_VALUE,
                payload: {
                    key: key,
                    value: value
                }
            })
		},
        saveCurrentNews: (announcement) => dispatch({
            type: Dispatch.ON_SAVE_NEWS_REQUEST,
			payload:announcement
		}),
        uploadImage: (news, fieldKey, image) => dispatch({
            type: Dispatch.ON_UPDATE_NEWS_IMAGE_REQUEST,
            payload: {news, fieldKey, image}
        })
	}
}

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(styles),
)(ViewNewsDialog);
