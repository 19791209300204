import React from 'react';
import ReactDOM from 'react-dom';
import { Provider, ReactReduxContext } from "react-redux";
import { ConnectedRouter, push } from 'connected-react-router';

import App from './component/App';
import * as serviceWorker from './serviceWorker';
import storage, { history } from "./storage/storage";

storage.dispatch(push("/login"))

ReactDOM.render(<Provider store={storage} context={ReactReduxContext}>
  <ConnectedRouter history={history} context={ReactReduxContext}>
      <App />
  </ConnectedRouter>
</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
