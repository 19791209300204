import SessionAction from "../model/SessionAction"
import Session from "../model/Session"
import Dispatch from "../constant/dispatch"
import EventAction from "../model/EventAction";
import storage from "../storage/storage";

const defaultState = {
	currentSession: new Session(),
	sessions: [],
	archiveSession : [],
	currentRegistration: {
		full_name: "",
		payment_proof_url: "",
		amount: 0,
		total_price: 0
	},
	isSessionDataLoaded: false
}

function handleLocalChanges(state, action) {
	if(action.type === Dispatch.OPEN_VIEW_SESSION_DIALOG || action.type === Dispatch.OPEN_VIEW_SESSION_REGISTRATION_DIALOG) {

		let currentSession = typeof action.payload === "object" ? action.payload : state.sessions.find(session => session.id === action.payload)

		if(!currentSession){
			currentSession = typeof action.payload === "object" ? action.payload : state.archiveSession.find(session => session.id === action.payload)
		}

		return {
			...state,
			currentSession: currentSession,
		}
	} else if(action.type === Dispatch.CLOSE_VIEW_SESSION_DIALOG) {
		return {
			...state,
			currentSession: defaultState.currentSession
		}
	} else if(action.type === Dispatch.EDIT_CURRENT_SESSION_VALUE) {
		return {
			...state,
			currentSession: {
				...state.currentSession,
				[action.payload.key]: action.payload.value
			}
		}
	} else if(action.type === Dispatch.RETURN_TO_EVENT_MANAGER_PAGE) {
		return defaultState
	} else if(action.type === Dispatch.ON_UPDATE_SESSION_IMAGE_RESPONSE_SUCCESS){
        return {
            ...state,
            currentSession:{
                ...state.currentSession,
                [action.payload.fieldKey]: action.payload.location
            }
        }
    } else if(action.type === Dispatch.OPEN_VIEW_SESSION_REGISTRANT_PROOF_DIALOG) {
		return {
			...state,
			currentRegistration: action.payload
		}

	}
	return state
}

export default function reducer(state = defaultState, action) {
	if(action.type === Dispatch.ON_GET_SESSIONS_REQUEST) {
		SessionAction.getSession(action.payload)
	} else if(action.type === Dispatch.ON_GET_SESSIONS_ARCHIVE_REQUEST){
		SessionAction.getArchiveSession(action.payload)
	}
	else if(action.type === Dispatch.ON_SAVE_CURRENT_SESSION_REQUEST) {
		let session = {...state.currentSession}
		session.role_id = action.payload
		//Enforce this to become a json string
		session.additional_information = JSON.stringify(session.additional_information);
		session.template_variables = JSON.stringify(session.template_variables);
		session.id === -1 ? SessionAction.createSession(session) : SessionAction.updateSession(session)
	} else if(action.type === Dispatch.ON_DELETE_SESSION_BY_ID_REQUEST) {
		SessionAction.deleteSession({...action.payload})
	} else if(action.type === Dispatch.ON_GET_SESSIONS_RESPONSE_SUCCESS) {
        return {
            ...state,
            sessions: action.payload,
            isSessionDataLoaded: true
        }
    } else if(action.type === Dispatch.ON_GET_SESSIONS_ARCHIVE_RESPONSE_SUCCESS) {
		return {
			...state,
			archiveSession: action.payload,
			isSessionDataLoaded: true
		}
	} else if(action.type === Dispatch.ON_REGISTRATION_CHANGE_PAYMENT_STATUS_REQUEST) {
		if(action.payload.rejectionMessage){
			SessionAction.updateRegistrationState({...action.payload.registrationInfo}, action.payload.status, action.payload.rejectionMessage)
		}else{
			SessionAction.updateRegistrationState({...action.payload.registrationInfo}, action.payload.status)
		}
    } else if(action.type === Dispatch.dfs) {
        SessionAction.updateRegistrationState({...action.payload.registrationInfo}, action.payload.status)
    } else if(action.type === Dispatch.ON_REGISTRATION_CHANGE_PAYMENT_STATUS_REQUEST) {
        SessionAction.updateRegistrationState({...action.payload.registrationInfo}, action.payload.status, action.payload.rejectionMessage)
    } else if(action.type === Dispatch.ON_GET_SESSIONS_REGISTRATIONS_RESPONSE_SUCCESS) {
        return {
            ...state,
            currentSession: {
				...state.currentSession,
				registrations : action.payload
			}
        }
    }else if(action.type === Dispatch.ON_SAVE_CURRENT_SESSION_RESPONSE_SUCCESS) {
		SessionAction.getSession(action.payload)
	} else if(action.type === Dispatch.ON_DELETE_SESSION_BY_ID_RESPONSE_SUCCESS) {
		return {
			...state,
			sessions: state.sessions.filter(session => session.id !== action.payload.id)
		}
	} else if(action.type === Dispatch.ON_GET_QR_CODE_BY_SESSION_ID_RESPONSE_SUCCESS) {
		return {
			...state,
			sessions: state.sessions.map(session => session.id === action.payload.id ? action.payload : session)
		}

	} else if(action.type === Dispatch.ON_UPDATE_SESSION_IMAGE_REQUEST) {
        SessionAction.updateSessionImage(action.payload.session, action.payload.fieldKey, action.payload.image)
    } else if(action.type === Dispatch.LOG_OUT) {
		return defaultState
	} else if(action.type === Dispatch.ON_GET_SESSIONS_REGISTRATIONS_REQUEST) {
		console.log("session is ", action.payload)
		SessionAction.getRegistrations(action.payload)
	} else if(action.type === Dispatch.ON_REGISTRATION_CHANGE_PAYMENT_STATUS_RESPONSE_SUCCESS){
		console.log(state.currentSession)
		SessionAction.getRegistrations(state.currentSession)
        // storage.dispatch({type: Dispatch.ON_GET_SESSIONS_REGISTRATIONS_REQUEST, payload: state.currentSession})
    }
	return handleLocalChanges(state, action)
}
