import React, {Component} from 'react'
import {connect} from "react-redux"
import {compose} from "recompose"

import {push} from "connected-react-router"

import {withStyles} from '@material-ui/core/styles';
import {withSnackbar} from 'notistack'
import {
    Typography,
    CssBaseline,
    Avatar,
    AppBar,
    Toolbar,
    Grid,
    Button,
    Card,
    Paper,
    Divider,
    Menu,
    MenuItem
} from "@material-ui/core";
import {
    LibraryBooks,
    SupervisorAccount,
    ExitToApp
} from '@material-ui/icons';
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom"

import DataTable from "../reusable/DataTable"

import noData from "../../image/no-data.svg"
import columns from "./config/eventTableConfig"
import Dispatch from "../../constant/dispatch";
import UserAuth from "../../util/UserAuth"
import {Col, Row, Container} from "react-bootstrap";

import {FaExclamationCircle, FaPlus} from 'react-icons/fa';
import {IoIosAdd} from "react-icons/io";

import Palette from "../../util/Palette";

const StyledMenu = withStyles({
    paper: {
        border: "1px solid #d3d4d5",
        minWidth: "200px"
    },
})(props => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const drawerWidth = 240;
const styles = theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: theme.palette.primary.main
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(10),
        // paddingLeft: theme.spacing(24),
        // paddingRight: theme.spacing(24),
    },
    title: {
        flexGrow: 1
    },
    avatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: theme.palette.primary.dark,
    },
    toolbar: theme.mixins.toolbar,
    button: {
        margin: theme.spacing(1),
    },
});

class EventManager extends Component {

    constructor(props) {
        super(props)

        this.state = {
            anchor: null
        }

        if (!props.isUserLoggedIn) {
            UserAuth.handleUserNotLoggedIn(props)
            return
        }
        // if(!this.props.isEventDataLoaded) {
        this.props.getEvents()
        // }
    }

    render() {
        const {classes, currentUser, events} = this.props

        console.log("events count " + events.length)
        return <div className={classes.root}>
            <CssBaseline/>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <Typography className={classes.title} variant="h6" noWrap>
                        INTELLIVENT DASHBOARD
                    </Typography>
                    <Typography variant="body2" noWrap>
                        {currentUser.email}
                    </Typography>
                    <Avatar
                        className={classes.avatar}
                        onClick={e => this.setState({anchor: e.currentTarget})}>
                        {currentUser.email.substring(0, 2).toUpperCase()}
                    </Avatar>
                    <StyledMenu
                        id="menu"
                        anchorEl={this.state.anchor}
                        keepMounted
                        open={!!this.state.anchor}
                        onClose={() => this.setState({anchor: null})}
                    >
                        <MenuItem onClick={() => {
                            this.props.logOut()
                            this.props.setUrl("/login")
                        }}>Log Out</MenuItem>
                    </StyledMenu>
                </Toolbar>
            </AppBar>
            <div style={{
                paddingLeft: 0,
                paddingRight: 0,
                flex: 1
            }}>
                <Container fluid style={{
                    paddingTop: 64,
                    flex: 1,
                    height: "100vh",
                }}>
                    {
                        events.length === 0 ?
                            <Row float={"center"} style={{
                                alignItems: "center",
                                height: "100vh"
                            }}>
                                <Col
                                    lg={12} style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <img src={noData} height={100} style={{marginBottom: "10px"}}/>
                                    <Typography color="textSecondary">
                                        You have no events yet.
                                    </Typography>
                                    <Button color="primary" className={classes.button}
                                            onClick={() => this.props.openViewEventDialog()}>
                                        CREATE EVENT
                                    </Button>
                                </Col>
                            </Row>
                            :
                            <Row float={"center"} style={{
                                height: "100vh",
                                marginTop: 30
                            }}>
                                <Col xs={0} lg={2}/>
                                {
                                    events.map((event, index) => {
                                        return <>
                                            <Col lg={2} style={{
                                                height: 280,
                                                padding: 10
                                            }}>
                                                <Card style={{
                                                    display: 'flex',
                                                    height: 260,
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    padding: 10,
                                                    alignItems: 'center'
                                                }} onClick={() => {
                                                    this.props.setUrl("/dashboard?e=" + event.id)
                                                }}>
                                                    {event.event_icon_url ?
                                                        <div style={{
                                                            width: "60%",
                                                            height: "auto",
                                                        }}>
                                                            <img src={event.event_icon_url} style={{
                                                                width: "100%",
                                                                objectFit: 'contain',
                                                            }}/>
                                                        </div>
                                                        :
                                                        <FaExclamationCircle size="60%"/>
                                                    }
                                                    <div style={{
                                                        textAlign: 'center',
                                                    }}>{event.name}</div>
                                                    <div style={{
                                                        textAlign: 'center',
                                                        color: 'grey',
                                                        fontSize: 12
                                                    }}>{event.start_time.isSame(event.end_time) ?
                                                        event.start_time.format("DD MMMM YYYY") :
                                                        `${event.start_time.format("DD MMMM YYYY")} - ${event.end_time.format("DD MMMM YYYY")}`
                                                    }
                                                    </div>
                                                    <div>
                                                        Draft
                                                    </div>
                                                    <div style={{
                                                        position: "absolute",
                                                        height: 3,
                                                        bottom: 15,
                                                        left: "auto",
                                                        right: "auto",
                                                        width: "80%",
                                                        display: "flex",
                                                        flexDirection: "row"
                                                    }}>
                                                        <div style={{
                                                            backgroundColor: event.color_primary,
                                                            flex: 1
                                                        }}/>
                                                        <div style={{
                                                            backgroundColor: event.color_dark,
                                                            flex: 1
                                                        }}/>
                                                     </div>
                                                </Card>
                                            </Col>
                                            {index % 4 === 3 ? <><Col lg={2}/><Col lg={2}/></> : null}
                                        </>
                                    })
                                }
                                <Col lg={2} onClick={() => this.props.openViewEventDialog()}>
                                    <div style={{
                                        display: 'flex',
                                        height: 260,
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <IoIosAdd size="40%" style={{
                                            color: Palette.grey
                                        }}/>
                                        <p style={{
                                            textAlign: 'center',
                                            marginTop: 15,
                                            color: Palette.grey
                                        }}>Add New Event</p>
                                    </div>
                                </Col>
                            </Row>
                    }
                </Container>
            </div>
        </div>
    }
}

const mapStateToProps = function (storage) {
    return {
        currentUser: storage.user.currentUser,
        events: storage.eventManager.events,
        isUserLoggedIn: storage.user.isUserLoggedIn,
        isEventDataLoaded: storage.eventManager.isEventDataLoaded
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUrl: url => dispatch(push(url)),
        getEvents: () => dispatch({
            type: Dispatch.ON_GET_ALL_EVENTS_REQUEST
        }),
        openViewEventDialog: () => dispatch({
            type: Dispatch.OPEN_VIEW_EVENT_DIALOG
        }),
        setActiveEvent: event => dispatch({
            type: Dispatch.SET_CURRENT_EVENT,
            payload: event
        }),
        logOut: () => dispatch({
            type: Dispatch.LOG_OUT
        }),
    }
}

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withStyles(styles),
    withSnackbar
)(EventManager)
