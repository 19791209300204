import UserAction from "../model/UserAction"
import User from "../model/User"
import Dispatch from "../constant/dispatch"

const defaultState = {
	currentUser: new User(),
	isUserLoggedIn: false,
	pageState : {
		errorMessage : "",
		registerMode : false
	},
}


export default function reducer(state = defaultState, action) {
	if(action.type === Dispatch.ON_LOGIN_REQUEST) {
		UserAction.login(action.payload)
	} else if(action.type === Dispatch.ON_LOGIN_FROM_SESSION_REQUEST) {
		UserAction.loginWithToken()
	} else if(action.type === Dispatch.ON_LOGIN_RESPONSE_FAIL) {
		return {
			...state,
			pageState: {
				...state.pageState,
				errorMessage : action.payload.msg
			}
		}
	} else if(action.type === Dispatch.ON_REGISTER_REQUEST) {
		UserAction.register(action.payload)
	} else if(action.type === Dispatch.ON_REGISTER_RESPONSE_FAIL) {
		return {
			...state,
			pageState: {
				...state.pageState,
				errorMessage : action.payload.msg
			}
		}
	} else if(action.type === Dispatch.ON_REGISTER_RESPONSE_SUCCESS) {
		console.log(action.payload)
		return {
			...state,
		}
	} else if(action.type === Dispatch.ON_TOGGLE_LOGIN_PAGE_MODE) {
		return {
			...state,
			pageState: {
				...defaultState.pageState,
				registerMode : action.payload
			}
		}
	} else if(
		action.type === Dispatch.ON_LOGIN_RESPONSE_SUCCESS ||
		action.type === Dispatch.ON_LOGIN_FROM_SESSION_RESPONSE_SUCCESS
	) {
		return {
			...state,
			currentUser: action.payload,
			isUserLoggedIn: true,
			pageState: {
				...defaultState.pageState
			}
		}

	} else if(action.type === Dispatch.LOG_OUT) {
		return defaultState
	}
	return state
}
