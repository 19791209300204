import React, { Component } from 'react'
import { connect } from "react-redux"
import { compose } from "recompose"

import { withStyles } from '@material-ui/core/styles';
import {
	Grid,
	Typography,
	Button
} from "@material-ui/core";

import DataTable from "../../reusable/DataTable"

import noData from "../../../image/no-data.svg"
import columns from "./config/boothTableConfig";
import BoothModel from "../../../model/Booth";
import Dispatch from "../../../constant/dispatch";


const styles = theme => ({
	root: {
		display: 'flex',
	},
});

class Booth extends Component {
	
	constructor(props) {
		super(props)
		
		// if(!this.props.isBoothDataLoaded) {
			this.props.getBoothsData(this.props.currentEvent.id)
		// }
	}
	
	render() {
		const { classes, booths } = this.props
		
		return <div className={classes.root}>
			{
				booths.length === 0 ? <Grid
					container
					spacing={0}
					direction="column"
					alignItems="center"
					justify="center"
					style={{ minHeight: '80vh' }}>
					<Grid item xs={3} style={{ flexBasis: '0%' }}>
						<img src={noData} height={100} style={{marginBottom: "10px"}}/>
					</Grid>
					<Grid item xs={3} style={{ flexBasis: '0%' }}>
						<Typography color="textSecondary">
							You have no booths yet.
						</Typography>
					</Grid>
					<Grid item xs={"auto"} style={{ flexBasis: '0%' }}>
						<Button
							color="primary"
							className={classes.button}
							onClick={() => this.props.openViewBoothDialog(new BoothModel())}>
							CREATE BOOTH
						</Button>
					</Grid>
				</Grid> : <DataTable
					title="Booths"
					selectableRows="multiple"
					filter={false}
					viewColumns={true}
					data={booths}
					columns={columns}
					createNew={() => this.props.openViewBoothDialog(new BoothModel())}
					onRowsDelete={booth => this.props.deleteBooth(booth)} />
			}
		</div>
	}
}

const mapStateToProps = function(storage) {
	return {
		currentEvent: storage.eventManager.currentEvent,
		booths: storage.booth.booths,
		isBoothDataLoaded: storage.booth.isBoothDataLoaded
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		openViewBoothDialog: booth => dispatch({
			type: Dispatch.OPEN_VIEW_BOOTH_DIALOG,
			payload: booth
		}),
		getBoothsData: eventId => dispatch({
			type: Dispatch.ON_GET_BOOTHS_REQUEST,
			payload: eventId
		}),
		deleteBooth: booth => dispatch({
			type: Dispatch.ON_DELETE_BOOTH_BY_ID_REQUEST,
			payload: booth
		})
	}
}

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(styles)
)(Booth)
