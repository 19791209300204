import UserAction from "../model/UserAction"
import Dispatch from "../constant/dispatch"
import UserAuth from "../util/UserAuth";

const defaultState = {
    snackBar: {
        id: 1,
        message: "",
        type: "info"
    },

    isLoading: false,

    isViewQRDialogOpen: false,
    isViewEventDialogOpen: false,
    isViewBoothDialogOpen: false,
    isViewSessionDialogOpen: false,
    isViewSurveyDialogOpen: false,
    isViewQuestionDialogOpen: false,
    isViewSurveyResultDialogOpen: false,
    isViewAnnouncementDialogOpen: false,
    isViewBookDialogOpen : false,
    isViewNewsDialogOpen : false,
    isViewSessionRegistrationsDialogOpen : false,
    isViewSessionRegistrantProofDialogOpen: false
}

function handleDialogState(state, action) {
    if(action.type === Dispatch.OPEN_VIEW_QR_DIALOG) {
        return {
            ...state,
            isViewQRDialogOpen: true
        }
    } else if(action.type === Dispatch.CLOSE_VIEW_QR_DIALOG) {
        return {
            ...state,
            isViewQRDialogOpen: false
        }
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    } else if(action.type === Dispatch.OPEN_VIEW_EVENT_DIALOG) {
        return {
            ...state,
            isViewEventDialogOpen: true
        }
    } else if(action.type === Dispatch.CLOSE_VIEW_EVENT_DIALOG || action.type === Dispatch.SAVE_CURRENT_EVENT) {
        return {
            ...state,
            isViewEventDialogOpen: false
        }
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    } else if(action.type === Dispatch.OPEN_VIEW_BOOTH_DIALOG) {
        return {
            ...state,
            isViewBoothDialogOpen: true
        }
    }
    else if(action.type === Dispatch.CLOSE_VIEW_BOOTH_DIALOG) {
        return {
            ...state,
            isViewBoothDialogOpen: false
        }
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    } else if(action.type === Dispatch.OPEN_VIEW_BOOK_DIALOG) {
        return {
            ...state,
            isViewBookDialogOpen: true
        }
    }
    else if(action.type === Dispatch.CLOSE_VIEW_BOOK_DIALOG) {
        return {
            ...state,
            isViewBookDialogOpen: false
        }
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    else if(action.type === Dispatch.OPEN_VIEW_SESSION_DIALOG) {
        return {
            ...state,
            isViewSessionDialogOpen: true
        }
    } else if(action.type === Dispatch.CLOSE_VIEW_SESSION_DIALOG || action.type === Dispatch.SAVE_CURRENT_SESSION) {
        return {
            ...state,
            isViewSessionDialogOpen: false
        }
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    } else if(action.type === Dispatch.OPEN_VIEW_SURVEY_DIALOG) {
        return {
            ...state,
            isViewSurveyDialogOpen: true
        }
    } else if(action.type === Dispatch.CLOSE_VIEW_SURVEY_DIALOG || action.type === Dispatch.SAVE_CURRENT_SURVEY) {
        return {
            ...state,
            isViewSurveyDialogOpen: false
        }
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    } else if(action.type === Dispatch.OPEN_VIEW_QUESTION_DIALOG) {
        return {
            ...state,
            isViewQuestionDialogOpen: true
        }
    } else if(action.type === Dispatch.CLOSE_VIEW_QUESTION_DIALOG || action.type === Dispatch.SAVE_CURRENT_QUESTION) {
        return {
            ...state,
            isViewQuestionDialogOpen: false
        }
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    } else if(action.type === Dispatch.OPEN_VIEW_SURVEY_RESULT_DIALOG) {
        return {
            ...state,
            isViewSurveyResultDialogOpen: true
        }
    } else if(action.type === Dispatch.CLOSE_VIEW_SURVEY_RESULT_DIALOG) {
        return {
            ...state,
            isViewSurveyResultDialogOpen: false
        }
    } else if(action.type === Dispatch.OPEN_VIEW_ANNOUNCEMENT_DIALOG) {
        return {
            ...state,
            isViewAnnouncementDialogOpen: true
        }
    } else if(action.type === Dispatch.CLOSE_VIEW_ANNOUNCEMENT_DIALOG) {
        return {
            ...state,
            isViewAnnouncementDialogOpen: false
        }
    } else if(action.type === Dispatch.OPEN_VIEW_NEWS_DIALOG) {
        return {
            ...state,
            isViewNewsDialogOpen: true
        }
    } else if(action.type === Dispatch.CLOSE_VIEW_NEWS_DIALOG) {
        return {
            ...state,
            isViewNewsDialogOpen: false
        }
    } else if(action.type === Dispatch.OPEN_VIEW_SESSION_REGISTRATION_DIALOG) {
        return {
            ...state,
            isViewSessionRegistrationsDialogOpen: true
        }
    } else if(action.type === Dispatch.CLOSE_VIEW_SESSION_REGISTRATION_DIALOG) {
        return {
            ...state,
            isViewSessionRegistrationsDialogOpen: false
        }
    } else if(action.type === Dispatch.OPEN_VIEW_SESSION_REGISTRANT_PROOF_DIALOG) {
        return {
            ...state,
            isViewSessionRegistrantProofDialogOpen: true
        }
    } else if(action.type === Dispatch.CLOSE_VIEW_SESSION_REGISTRANT_PROOF_DIALOG) {
        return {
            ...state,
            isViewSessionRegistrantProofDialogOpen: false
        }
    }
    return state
}

export default function reducer(state = defaultState, action) {
    if(
        action.type.includes("ON_") && action.type.includes("_REQUEST")
    // action.type === Dispatch.ON_LOGIN_REQUEST ||
    // action.type === Dispatch.ON_LOGIN_FROM_SESSION_REQUEST ||
    // action.type === Dispatch.ON_GET_ALL_EVENTS_REQUEST ||
    // action.type === Dispatch.ON_GET_EVENT_BY_ID_REQUEST ||
    // action.type === Dispatch.ON_SAVE_EVENT_REQUEST ||
    // action.type === Dispatch.ON_GET_BOOTHS_REQUEST ||
    // action.type === Dispatch.ON_SAVE_CURRENT_BOOTH_REQUEST ||
    // action.type === Dispatch.ON_GET_SESSIONS_REQUEST ||
    // action.type === Dispatch.ON_SAVE_CURRENT_SESSION_REQUEST ||
    // action.type === Dispatch.ON_GET_SURVEYS_REQUEST ||
    // action.type === Dispatch.ON_SAVE_CURRENT_SURVEY_REQUEST ||
    // action.type === Dispatch.ON_DELETE_BOOTH_BY_ID_REQUEST ||
    // action.type === Dispatch.ON_DELETE_SESSION_BY_ID_REQUEST ||
    // action.type === Dispatch.ON_DELETE_SURVEY_BY_ID_REQUEST
    ) {
        return {
            ...state,
            isLoading: true
        }
    } else if(
        action.type.includes("ON_") && action.type.includes("_RESPONSE_")
    // action.type === Dispatch.ON_LOGIN_RESPONSE_SUCCESS ||
    // action.type === Dispatch.ON_LOGIN_RESPONSE_FAIL ||
    // action.type === Dispatch.ON_LOGIN_FROM_SESSION_RESPONSE_SUCCESS ||
    // action.type === Dispatch.ON_LOGIN_FROM_SESSION_RESPONSE_FAIL ||
    // action.type === Dispatch.ON_GET_ALL_EVENTS_RESPONSE_SUCCESS ||
    // action.type === Dispatch.ON_GET_ALL_EVENTS_RESPONSE_FAIL ||
    // action.type === Dispatch.ON_GET_EVENT_BY_ID_RESPONSE_SUCCESS ||
    // action.type === Dispatch.ON_GET_EVENT_BY_ID_RESPONSE_FAIL ||
    // action.type === Dispatch.ON_SAVE_EVENT_RESPONSE_SUCCESS ||
    // action.type === Dispatch.ON_SAVE_EVENT_RESPONSE_FAIL ||
    // action.type === Dispatch.ON_GET_BOOTHS_RESPONSE_SUCCESS ||
    // action.type === Dispatch.ON_GET_BOOTHS_RESPONSE_FAIL ||
    // action.type === Dispatch.ON_SAVE_CURRENT_BOOTH_RESPONSE_SUCCESS ||
    // action.type === Dispatch.ON_SAVE_CURRENT_BOOTH_RESPONSE_FAIL ||
    // action.type === Dispatch.ON_GET_SESSIONS_RESPONSE_SUCCESS ||
    // action.type === Dispatch.ON_GET_SESSIONS_RESPONSE_FAIL ||
    // action.type === Dispatch.ON_SAVE_CURRENT_SESSION_RESPONSE_SUCCESS ||
    // action.type === Dispatch.ON_SAVE_CURRENT_SESSION_RESPONSE_FAIL ||
    // action.type === Dispatch.ON_GET_SURVEYS_RESPONSE_SUCCESS ||
    // action.type === Dispatch.ON_GET_SURVEYS_RESPONSE_FAIL ||
    // action.type === Dispatch.ON_SAVE_CURRENT_SURVEY_RESPONSE_SUCCESS ||
    // action.type === Dispatch.ON_SAVE_CURRENT_SURVEY_RESPONSE_FAIL ||
    // action.type === Dispatch.ON_DELETE_BOOTH_BY_ID_RESPONSE_SUCCESS ||
    // action.type === Dispatch.ON_DELETE_BOOTH_BY_ID_RESPONSE_FAIL ||
    // action.type === Dispatch.ON_DELETE_SESSION_BY_ID_RESPONSE_SUCCESS ||
    // action.type === Dispatch.ON_DELETE_SESSION_BY_ID_RESPONSE_FAIL ||
    // action.type === Dispatch.ON_DELETE_SURVEY_BY_ID_RESPONSE_SUCCESS ||
    // action.type === Dispatch.ON_DELETE_SURVEY_BY_ID_RESPONSE_FAIL
    ) {
        return {
            ...state,
            isLoading: false
        }
    } else if(action.type === Dispatch.ENQUEUE_SNACKBAR) {
        return {
            ...state,
            snackBar: {
                ...action.payload,
                id: (state.snackBar.id + 1) % 10
            }
        }

    } else if(action.type === Dispatch.LOG_OUT) {
        UserAuth.handleUserLogOut()
        return {
            ...defaultState,
            snackBar: state.snackBar
        }
    }
    return handleDialogState(state, action)
}
