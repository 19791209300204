import axios from "axios";
import server from "../../constant/server";
import storage from "../../storage/storage";
import Dispatch from "../../constant/dispatch";
import {SnackBarVariant} from "../../component/reusable/SnackBar";

export default {
    downloadSessionDataForCSV: async sessionId => {
        try{
            let result = await axios.get(server + `/sessions/${sessionId}/registration/csv`)
            return result
        }catch(err){
            console.log(err.response)
        }

    },
}
