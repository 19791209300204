import React, { Component } from 'react'
import { connect } from "react-redux"
import { compose } from "recompose"
import { push } from "connected-react-router";

import { withStyles } from '@material-ui/core/styles';
import {
	Grid,
} from "@material-ui/core";

import { withSnackbar } from 'notistack'
import { SnackBarVariant, generateSnackBar } from "../../reusable/SnackBar"

import EventModel from "../../../model/Event"

import Dispatch from "../../../constant/dispatch";
import storage from "../../../storage/storage";
import DataTable from "../../reusable/DataTable";
import columns from "../Announcement/config/announcementTableConfig";
import SurveyModel from "../../../model/Survey";
import AnnouncementModel from "../../../model/Announcement";

const styles = theme => ({
	root: {
		display: 'flex',
	},
	pricingPlanCard: {
		marginRight: "18px"
	},
	button: {
		marginRight: "10px"
	}
});

class Announcement extends Component {

	constructor(props) {
		super(props)
		this.props.getAnnouncementData(this.props.currentEvent.id)
		this.props.getEventById(this.props.currentEvent.id)
		this.state = {
            ...this.props.currentEvent,
            isError: false
        }
	}

	handleSaveData() {
		if(EventModel.isDataValid(this.state)) {
			//this.props.saveCurrentEvent(this.state)
		} else {
			this.setState({isError: true})
			this.props.enqueueSnackBar({
				message: "Data is not complete",
				type: SnackBarVariant.ERROR,
			})
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(JSON.stringify(prevProps.currentEvent) !== JSON.stringify(this.props.currentEvent)) {
			this.setState({
				...this.props.currentEvent
			})
		}
	}

	render() {
		const { classes, announcements} = this.props
		return <div className={classes.root}>
			<Grid container spacing={4}>
				<Grid item xs={12}>
                    <DataTable
                        title="Announcements"
                        selectableRows="multiple"
                        filter={false}
                        data={announcements}
                        columns={columns}
                        createNew={() => this.props.openViewAnnouncementDialog(new AnnouncementModel())}
                        onRowsDelete={survey => this.props.deleteSurvey(survey)} />
				</Grid>
			</Grid>
		</div>
	}
}

const mapStateToProps = function(storage) {
	return {
		currentEvent: storage.eventManager.currentEvent,
		announcements : storage.announcement.announcements,
		isAnnouncementDataLoaded : storage.announcement.isAnnouncementDataLoaded
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setUrl: url => dispatch(push(url)),
		enqueueSnackBar: snackBar => dispatch({
			type: Dispatch.ENQUEUE_SNACKBAR,
			payload: snackBar
		}),
		getEventById: id => dispatch({
			type: Dispatch.ON_GET_EVENT_BY_ID_REQUEST,
			payload: id
		}),
        openViewAnnouncementDialog: announcement => dispatch({
            type: Dispatch.OPEN_VIEW_ANNOUNCEMENT_DIALOG,
            payload: announcement
        }),
        getAnnouncementData: eventId => dispatch({
            type: Dispatch.ON_GET_ANNOUNCEMENT_REQUEST,
            payload: eventId
        }),
	}
}

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(styles),
	withSnackbar
)(Announcement)
