import axios from "axios"
import {push} from "connected-react-router"

import storage from "../storage/storage"
import Dispatch from "../constant/dispatch"
import server from "../constant/server"
import UserAuth from "../util/UserAuth"
import {SnackBarVariant} from "../component/reusable/SnackBar"
import Book from "./Book"

export default {
    getBooks: eventId => {
        console.log("EVENTID", eventId)
        axios.get(server + `/events/${eventId}/items`)
            .then(response => {
                console.warn("BOOK BRUH", response.data)
                storage.dispatch({
                    type: Dispatch.ON_GET_BOOKS_RESPONSE_SUCCESS, payload: response.data.map(books => {
                        return {
                            ...books,
                            additional_information: books.additional_information instanceof Object ? books.additional_information : JSON.parse(books.additional_information)
                        }
                    })
                })
            })
            .catch(err => {
                console.log(err.response)
                storage.dispatch({type: Dispatch.ON_GET_BOOKS_RESPONSE_FAIL})
                if (err.response === undefined)
                    storage.dispatch({
                        type: Dispatch.ENQUEUE_SNACKBAR,
                        payload: {
                            id: Math.round(Math.random() * 1000),
                            message: "Failed to retrieve data",
                            type: SnackBarVariant.ERROR,
                            options: [{
                                text: "RETRY",
                                action: () => storage.dispatch({
                                    type: Dispatch.ON_GET_BOOKS_REQUEST,
                                    payload: eventId
                                })
                            }]
                        }
                    })
            })
    },
    createBook: book => {
        delete book.id
        let preparedBook = Book.getRequestCompatibleObject(book)
        axios.post(server + "/items", preparedBook)
            .then(response => {
                storage.dispatch({
                    type: Dispatch.ON_SAVE_CURRENT_BOOK_RESPONSE_SUCCESS,
                    payload: {
                        ...book,
                        id: response.data.insert_id
                    }
                })
                storage.dispatch({type: Dispatch.CLOSE_VIEW_BOOK_DIALOG})
            })
            .catch(err => {
                console.log(err.response)
                storage.dispatch({type: Dispatch.ON_SAVE_CURRENT_BOOK_RESPONSE_FAIL})
            })
    },
    updateBook: book => {

        // console.log("uploading ",JSON.stringify("woieiofjakdjfkldsj"))
        let preparedBook = Book.getRequestCompatibleObject(book)
        axios.patch(server + "/items/" + book.id, preparedBook)
            .then(response => {
                storage.dispatch({
                    type: Dispatch.ON_SAVE_CURRENT_BOOK_RESPONSE_SUCCESS,
                    payload: {
                        ...book,
                        id: book.id
                    }
                })
                storage.dispatch({type: Dispatch.CLOSE_VIEW_BOOK_DIALOG})
            })
            .catch(err => {
                console.log(err.response)
                storage.dispatch({type: Dispatch.ON_SAVE_CURRENT_BOOK_RESPONSE_FAIL})
            })
    },
    deleteBook: book => {

        axios.delete(server + "/items/" + book.id)
            .then(response => {
                storage.dispatch({
                    type: Dispatch.ON_DELETE_BOOK_BY_ID_RESPONSE_SUCCESS,
                    payload: {
                        ...book,
                        id: book.id
                    }
                })
                storage.dispatch({type: Dispatch.CLOSE_VIEW_BOOK_DIALOG})
            })
            .catch(err => {
                console.log(err.response)
                storage.dispatch({type: Dispatch.ON_DELETE_BOOK_BY_ID_RESPONSE_FAIL})
            })
    },
    updateBookImage: (book, fieldKey, image, cb) => {
        let formData = new FormData();
        // formData.append("fname", "event_icon.png")
        // formData.append('data',image)

        console.log('blob', image)
        formData.append('upload', image, image.name);

        const config = {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'content-type': 'multipart/form-data'
            }
        }
        axios.post(server + `/items/${book.id}/${fieldKey}/upload`, formData, config)
            .then(response => {

                cb(response.data.location)

                storage.dispatch({
                    type: Dispatch.ON_UPDATE_ITEM_IMAGE_RESPONSE_SUCCESS, payload: {
                        location: response.data.location,
                        fieldKey: fieldKey + "_url"
                    }
                })
                console.log("dispatch done")
            })
            .catch(err => {
                console.log(err)
                console.log(err.response)
                storage.dispatch({type: Dispatch.ON_UPDATE_ITEM_IMAGE_RESPONSE_FAIL})
            })
    },
}
