import React from "react"
import {
    Tooltip,
    IconButton, ListItemIcon
} from "@material-ui/core";
import {
    RemoveRedEye, RemoveRedEyeOutlined,
    Edit, VerticalAlignBottom,
    Delete
} from '@material-ui/icons';
import { FaEye , FaEyeSlash } from "react-icons/fa";
import Dispatch from "../../../../constant/dispatch";
import storage from "../../../../storage/storage";
import moment from "moment";
import { IconContext } from "react-icons";


export default [
    {
        name: "id",
        label: "id",
        options: {
            display: "false",
            filter: false,
            sort: false,
            viewColumns: false,
        }
    },
    {
        name: "title",
        label: "Announcement Name",
        options: {
            filter: false,
            sort: true,
        }
    },
    {
        name: "publish_time",
        label: "Publish Time",
        options: {
            filter: false,
            sort: true,
            customBodyRender: (value, meta) => {
            	// console.log(meta)
                return <>{moment(value).format("DD/MM/YYYY HH:mm")}</>
            }
        }
    },
    {
        name: "publish_time",
        label: "Status",
        options: {
            filter: false,
            sort: true,
            customBodyRender: (value) => {
                if(moment.utc().isSameOrAfter(value)){
                    return <>Published</>
                }else{
                    return <>Scheduled Publish</>
                }
            }
        }
    },
    {
        name: "id",
        label: "Options",
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value) => {
                let announcement = {...storage.getState().announcement.announcements.find(announcement => announcement.id === value)}
                console.log("Render Button" , announcement)
                let eyeLogo = announcement.shown ?  <IconContext.Provider value={{}}>
                    <div>
                        <FaEye/>
                    </div>
                </IconContext.Provider> : <IconContext.Provider value={{className: "icon", color:"#a3a3a3" }}>
                        <div>
                            <FaEyeSlash/>
                        </div>
                    </IconContext.Provider>
                let tooltip = announcement.shown ? "Hide (Currently shown)" : "Show (Currently hidden)"
                return <>
                    <Tooltip title="Edit">
                        <IconButton
                            onClick={() => storage.dispatch({
                                type: Dispatch.OPEN_VIEW_ANNOUNCEMENT_DIALOG,
                                payload: {...storage.getState().announcement.announcements.find(announcement => announcement.id === value)}
                            })}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove">
                        <IconButton
                            onClick={() => storage.dispatch({
                                type: Dispatch.OPEN_VIEW_ANNOUNCEMENT_DIALOG,
                                payload: {...storage.getState().announcement.announcements.find(announcement => announcement.id === value)}
                            })}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={tooltip}>
                        <IconButton
                            onClick={() => {
                                storage.dispatch({
                                    type: Dispatch.ON_TOGGLE_ANNOUNCEMENT_SHOWN_REQUEST,
                                    payload: value
                                })
                            }}>
                            {eyeLogo}
                        </IconButton>
                    </Tooltip>
				</>
            }
        }
    },

]
