import axios from "axios"
import { push } from "connected-react-router"

import storage from "../storage/storage"
import Dispatch from "../constant/dispatch"
import server from "../constant/server"
import UserAuth from "../util/UserAuth"
import { SnackBarVariant } from "../component/reusable/SnackBar"
import { AnnouncementOutlined } from "@material-ui/icons"

export default {
	getAnnouncements: eventId => {
		axios.get(server + "/events/" + eventId + "/announcement/admin")
			.then(response => {
				storage.dispatch({type: Dispatch.ON_GET_ANNOUNCEMENT_RESPONSE_SUCCESS, payload: response.data.map(announcements => {
					return {
						...announcements,
					}
				})})
			})
			.catch(err => {
				console.log(err.response)
				storage.dispatch({type: Dispatch.ON_GET_ANNOUNCEMENT_RESPONSE_FAIL})
				if(err.response === undefined)
				storage.dispatch({
					type: Dispatch.ENQUEUE_SNACKBAR,
					payload: {
						id: Math.round(Math.random()*1000),
						message: "Failed to retrieve data",
						type: SnackBarVariant.ERROR,
						options: [{
							text: "RETRY",
							action: () => storage.dispatch({
								type: Dispatch.ON_GET_ANNOUNCEMENT_REQUEST,
								payload: eventId
							})
						}]
					}
				})
			})
	},

	createAnnouncement: announcement => {
		delete announcement.id
		axios.post(server + "/announcements", announcement)
			.then(response => {
				console.log("announcement created", response)
				storage.dispatch({
					type: Dispatch.ON_SAVE_ANNOUNCEMENT_RESPONSE_SUCCESS,
					payload: storage.getState().eventManager.currentEvent.id
				})
				storage.dispatch({type: Dispatch.CLOSE_VIEW_ANNOUNCEMENT_DIALOG})
			})
			.catch(err => {
				console.log(err.response)
				storage.dispatch({type: Dispatch.ON_SAVE_ANNOUNCEMENT_RESPONSE_FAIL})
			})
	},

	updateAnnouncement: announcement => {
		axios.put(server + "/announcements/" + announcement.id,  announcement)
			.then(response => {
				storage.dispatch({type: Dispatch.ON_SAVE_ANNOUNCEMENT_RESPONSE_SUCCESS, payload: storage.getState().eventManager.currentEvent.id})
				storage.dispatch({type: Dispatch.CLOSE_VIEW_ANNOUNCEMENT_DIALOG})
			})
			.catch(err => {
				console.log(err.response)
				storage.dispatch({type: Dispatch.ON_SAVE_ANNOUNCEMENT_RESPONSE_FAIL})
			})
	},

	deleteBooth: booth => {
		axios.delete(server + "/booths/" + booth.id)
			.then(response => {
				console.log("booth deleted", response)
				storage.dispatch({type: Dispatch.ON_DELETE_BOOTH_BY_ID_RESPONSE_SUCCESS, payload: booth})
			})
			.catch(err => {
				console.log(err.response)
				storage.dispatch({type: Dispatch.ON_DELETE_BOOTH_BY_ID_RESPONSE_FAIL})
			})
	},
}
