import React from "react"
import moment from "moment"
import {
    Tooltip,
    IconButton
} from "@material-ui/core";
import {
    VerticalAlignBottom,
    Edit,
    Group,
    InsertDriveFile
} from '@material-ui/icons';
import storage from "../../../../storage/storage"
import Dispatch from "../../../../constant/dispatch"
import Session from "../../../../model/util/Session";
import {CSVLink} from "react-csv";

import Papa from "papaparse"

const getCSVData = async (id) => {
    let result = await Session.downloadSessionDataForCSV(id)
    console.log(result)
}

export default function(additionalAction){
    return [
        {
            name: "id",
            label: "id",
            options: {
                display: "true",
                filter: false,
                sort: false,
                viewColumns: true,
            }
        },
        {
            name: "session_name",
            label: "Session Name",
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "session_speaker",
            label: "Speaker Name",
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "start_time",
            label: "Date Held",
            options: {
                filter: true,
                sort: true,
                customBodyRender: value => value.format("DD/MM/YYYY")
            }
        },
        {
            name: "start_time",
            label: "Start Time",
            options: {
                filter: false,
                sort: true,
                customBodyRender: value => value.format("HH:mm")
            }
        },
        {
            name: "end_time",
            label: "End Time",
            options: {
                filter: false,
                sort: true,
                display: false,//ganti 24
                customBodyRender: value => value.format("HH:mm")
            }
        },
        {
            name: "price",
            label: "Price",
            options: {
                filter: false,
                sort: true,
                customBodyRender: value => <>
                    {value === 0 ? "Free" : "Rp. " + value}
                </>
            }
        },
        {
            name: "allow_registration",
            label: "Registration Open",
            options: {
                filter: false,
                sort: true,
                customBodyRender: value => <>
                    {value ? <span style={{color: "#137100"}}>OPEN</span> : <span style={{color: "#FF0000"}}>CLOSE</span>}
                </>
            }
        },
        {
            name: "zone",
            label: "Location",
            display: "False",
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "quota_label",
            label: "Quota",
            display: "False",
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "id",
            label: "Options",
            options: {
                filter: false,
                sort: false,
                customBodyRender: value => <>
                    <Tooltip title="Download QR">
                        <IconButton
                            onClick={() => {
                                storage.dispatch({
                                    type: Dispatch.OPEN_VIEW_QR_DIALOG,
                                    payload: {...storage.getState().session.sessions.find(session => session.id === value)},
                                    flag: "Session"
                                })
                            }}>
                            <VerticalAlignBottom/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Registration Management">
                        <IconButton
                            onClick={() => storage.dispatch({
                                type: Dispatch.OPEN_VIEW_SESSION_REGISTRATION_DIALOG,
                                payload: value
                            })}>
                            <Group/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit">
                        <IconButton
                            onClick={() => storage.dispatch({
                                type: Dispatch.OPEN_VIEW_SESSION_DIALOG,
                                payload: value
                            })}>
                            <Edit/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Download CSV">

                        <IconButton
                            onClick={async () => {
                                let result = await Session.downloadSessionDataForCSV(value)

                                let sessionData = result.data.map((obj)=>{
                                    return {
                                        FullName : obj.full_name,
                                        Email : obj.email
                                    }
                                })

                                let csvData = await Papa.unparse(sessionData, {delimiter : ";"})

                                let blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
                                let url = URL.createObjectURL(blob);

                                let a = document.createElement("a");
                                a.href = url;
                                a.download = `session-${value}-registered-user.csv`; //File name Here
                                a.click(); //Downloaded file

                            }}>
                            <InsertDriveFile/>
                        </IconButton>


                    </Tooltip>
                    {
                        additionalAction && additionalAction(value)
                    }
                </>
            }
        },
    ]

}
