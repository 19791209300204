import { applyMiddleware, createStore, compose } from 'redux'
import promise from "redux-promise-middleware"
import thunk from "redux-thunk"
import { createLogger } from "redux-logger"
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'

import createReducer from "../reducer"

export const history = createBrowserHistory({
  basename: "/"
})

function configureStore(preloadedState) {
  return createStore(
    createReducer(history),
    preloadedState,
    compose(
      applyMiddleware(
        promise(),
        thunk,
        createLogger({collapsed: true}),
        routerMiddleware(history)
      )
    )
  )
}

export default configureStore({})