import React from "react"
import {
    Tooltip,
    IconButton, ListItemIcon, Button
} from "@material-ui/core";
import {
    RemoveRedEye, RemoveRedEyeOutlined,
    Edit, VerticalAlignBottom,
    Delete
} from '@material-ui/icons';
import { FaEye , FaEyeSlash } from "react-icons/fa";
import Dispatch from "../../../../constant/dispatch";
import storage from "../../../../storage/storage";
import moment from "moment";
import { IconContext } from "react-icons";


export default [
    {
        name: "id",
        label: "id",
        options: {
            display: "false",
            filter: false,
            sort: false,
            viewColumns: false,
        }
    },
    {
        name: "title",
        label: "News Title",
        options: {
            filter: false,
            sort: true,
        }
    },
    {
        name: "created_at",
        label: "Created At",
        options: {
            filter: false,
            sort: true,
            customBodyRender: (value, meta) => {
                // console.log(meta)
                return <>{moment(value).format("DD/MM/YYYY HH:mm")}</>
            }
        }
    },
    {
        name: "shown",
        label: "Status",
        options: {
            filter: false,
            sort: true,
            customBodyRender: (value) => {

                //let tooltip = value ? "PRESS TO HIDE" : "PRESS TO PUBLISH"

                return <>
                    {value?<>Published</>:<>Draft</>}
                </>

            }
        }
    },
    {
        name: "id",
        label: "Options",
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value) => {
                let news = {...storage.getState().news.news.find(news => news.id === value)}
                console.log("Render Button" , news)

                return <>
                    <Tooltip title="Edit">
                        <IconButton
                            onClick={() => storage.dispatch({
                                type: Dispatch.OPEN_VIEW_NEWS_DIALOG,
                                payload: {...storage.getState().news.news.find(news => news.id === value)}
                            })}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                    {/**
                    <Tooltip title="Remove">
                        <IconButton
                            onClick={() => storage.dispatch({
                                type: Dispatch.OPEN_VIEW_ANNOUNCEMENT_DIALOG, //TODO: DELETE NEWS?!?!?!
                                payload: {...storage.getState().news.news.find(news => news.id === value)}
                            })}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                        **/}
                </>
            }
        }
    },

]
