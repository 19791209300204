import React, { Component } from 'react'
import { connect } from "react-redux"
import { compose } from "recompose"

import { withStyles } from '@material-ui/core/styles';
import {
	Grid,
	Typography,
	Button, Tooltip, IconButton
} from "@material-ui/core";

import DataTable from "../../reusable/DataTable"

import noData from "../../../image/no-data.svg"
import columns from "./config/sessionTableConfig";
import SessionModel from "../../../model/Session";
import Dispatch from "../../../constant/dispatch";
import InviteToSessionDialog from "./InviteToSessionDialog";
import storage from "../../../storage/storage";
import Papa from "papaparse";
import {
	PersonAdd
} from '@material-ui/icons';
const styles = theme => ({
	root: {
		display: 'flex',
	},
});

class Session extends Component {

	constructor(props) {
		super(props)

		this.state = {
			selectedSessionIdForInviting : null
		}
		// if(!this.props.isSessionDataLoaded) {
			this.props.getSessionsData(this.props.currentEvent.id)
		// }
	}

	render() {
		const { classes, sessions } = this.props

		return <div className={classes.root}>
			{
				sessions.length === 0 ? <Grid
					container
					spacing={0}
					direction="column"
					alignItems="center"
					justify="center"
					style={{ minHeight: '80vh' }}>
					<Grid item xs={3} style={{ flexBasis: '0%' }}>
						<img src={noData} height={100} style={{marginBottom: "10px"}}/>
					</Grid>
					<Grid item xs={3} style={{ flexBasis: '0%' }}>
						<Typography color="textSecondary">
							You have no sessions yet.
						</Typography>
					</Grid>
					<Grid item xs={"auto"} style={{ flexBasis: '0%' }}>
						<Button
							color="primary"
							className={classes.button}
							onClick={() => this.props.openViewSessionDialog(new SessionModel(this.props.currentEvent.roleId))}>
							CREATE SESSION
						</Button>
					</Grid>
				</Grid> : <DataTable
					title="Sessions"
					selectableRows="multiple"
					filter={false}
					viewColumns={true}
					data={sessions}
					columns={columns(value=><Tooltip title="Invite User">
						<IconButton
							onClick={() => {
								this.setState({
									...this.state,
									selectedSessionIdForInviting : value
								})
							}}>
							<PersonAdd/>
						</IconButton>
					</Tooltip>)}
					createNew={() => this.props.openViewSessionDialog(new SessionModel(this.props.currentEvent.roleId))}
					onRowsDelete={session => this.props.deleteSession(session)} />
			}
			<InviteToSessionDialog
				isOpen={!!this.state.selectedSessionIdForInviting}
				sessionId={this.state.selectedSessionIdForInviting}
				closeDialog={(success)=>{
					if(success){
						this.props.getSessionsData(this.props.currentEvent.id)
					}
					this.setState({
						...this.state,
						selectedSessionIdForInviting : null
					})
				}}
			/>
		</div>
	}
}

const mapStateToProps = function(storage) {
	return {
		currentEvent: storage.eventManager.currentEvent,
		sessions: storage.session.sessions,
		isSessionDataLoaded: storage.session.isSessionDataLoaded
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		openViewSessionDialog: session => dispatch({
			type: Dispatch.OPEN_VIEW_SESSION_DIALOG,
			payload: session
		}),
		getSessionsData: eventId => dispatch({
			type: Dispatch.ON_GET_SESSIONS_REQUEST,
			payload: eventId
		}),
		deleteSession: session => dispatch({
			type: Dispatch.ON_DELETE_SESSION_BY_ID_REQUEST,
			payload: session
		}),
	}
}

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(styles)
)(Session)
