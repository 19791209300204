import React from "react"
import {
	Tooltip,
	IconButton
} from "@material-ui/core";
import {
	VerticalAlignBottom,
	Edit
} from '@material-ui/icons';
import QRCode from 'qrcode'
import download from "image-downloader"
import Dispatch from "../../../../constant/dispatch";
import storage from "../../../../storage/storage";

import image from "../../../../image/no-data.svg"

export default [
	{
		name: "id",
		label: "id",
		options: {
			display: "false",
			filter: false,
			sort: false,
			viewColumns: false,
		}
	},
	{
		name: "name",
		label: "Booth Name",
		options: {
			filter: false,
			sort: true,
		}
	},
	{
		name: "zone",
		label: "Location",
		options: {
			filter: false,
			sort: true,
		}
	},
	{
		name: "id",
		label: "Options",
		options: {
			filter: false,
			sort: false,
			customBodyRender: (value) => {
				return <>
					<Tooltip title="Download QR">
						<IconButton
							onClick={() => {
								storage.dispatch({
									type: Dispatch.OPEN_VIEW_QR_DIALOG,
									payload: {...storage.getState().booth.booths.find(booth => booth.id === value)},
									flag: "Booth"
								})
							}}>
							<VerticalAlignBottom />
						</IconButton>
					</Tooltip>
					<Tooltip title="Edit">
						<IconButton
							onClick={() => storage.dispatch({
								type: Dispatch.OPEN_VIEW_BOOTH_DIALOG,
								payload: value
							})}>
							<Edit />
						</IconButton>
					</Tooltip>
				</>
			}
		}
	},
]
