import axios from "axios"
import {push} from "connected-react-router"

import storage from "../storage/storage"
import Dispatch from "../constant/dispatch"
import server from "../constant/server"
import UserAuth from "../util/UserAuth"
import {generateSnackBar, SnackBarVariant} from "../component/reusable/SnackBar";
import GlobalData from "../util/GlobalData";

export default {
    login: (data) => {
        axios.post(server + "/organizations/login", data)
            .then(response => {
                console.log("login success!", response)

                const {token, ...rest} = response.data

                GlobalData.token = token

                UserAuth.handleTokenReceived(token)
                storage.dispatch({type: Dispatch.ON_LOGIN_RESPONSE_SUCCESS, payload: rest})
            })
            .catch(err => {
                console.log(err.response)
                if (err.response) {
                    storage.dispatch({type: Dispatch.ON_LOGIN_RESPONSE_FAIL, payload: err.response.data})
                } else {
                    storage.dispatch({type: Dispatch.ON_LOGIN_RESPONSE_FAIL, payload: "Connection failed."})
                }
            })
    },

    register: (data) => {

        console.log("register data", data)

        axios.post(server + "/organizations/create", data)
            .then(response => {

                // storage.dispatch({type: Dispatch.ON_REGISTER_RESPONSE_SUCCESS, payload: response})
                const {token, ...rest} = response.data
                UserAuth.handleTokenReceived(token)
                storage.dispatch({type: Dispatch.ON_LOGIN_RESPONSE_SUCCESS, payload: rest})

            })
            .catch(err => {
                console.log(err.response)
                if (err.response) {
                    storage.dispatch({type: Dispatch.ON_REGISTER_RESPONSE_FAIL, payload: err.response.data})
                } else {
                    storage.dispatch({type: Dispatch.ON_REGISTER_RESPONSE_FAIL, payload: "Connection failed."})
                }
            })
    },

    loginWithToken: () => {
        axios.post(server + "/organizations/tokenLogin")
            .then(response => {
                console.log("login with token success!", response)

                const {token, ...rest} = response.data
                UserAuth.handleTokenReceived(token)
                storage.dispatch({type: Dispatch.ON_LOGIN_RESPONSE_SUCCESS, payload: rest})
            })
            .catch(err => {
                console.log(err.response)
                if (err.response) {
                    storage.dispatch({type: Dispatch.ON_LOGIN_RESPONSE_FAIL, payload: err.response.data})
                } else {
                    storage.dispatch({type: Dispatch.ON_LOGIN_RESPONSE_FAIL, payload: "Connection failed."})
                }

                storage.dispatch({
                    type: Dispatch.ENQUEUE_SNACKBAR,
                    payload: {
                        id: Math.round(Math.random() * 1000),
                        message: "An error occurred, please login again",
                        type: SnackBarVariant.ERROR
                    }
                })
            })
    },
}
