class Booth {
    constructor() {
        this.id = -1
        this.name = "" //ada //WAJIB
        this.owner = "" // bakal ada //WAJIB
        this.icon = null // bakal ada
        this.image = ""
		this.social_media_url = ""
		this.brochure_url = ""
        this.description = "" // ada //WAJIB
	    this.pic_name = "" // bakal ada
	    this.pic_phone = "" // bakal ada
	    this.pic_email = "" // bakal ada
        this.category = "" // fakultasnya apa, udah ada, sementara string input //WAJIB
        this.zone = "" // tempatnya dimana, contoh R315 //WAJIB
		this.enabled = 1

	    this.qr_codes = []
    }

    static isDataValid(booth) {

    	console.log(booth)

    	console.log("valid is " + booth.hasOwnProperty("name") && booth.hasOwnProperty("description") &&
            booth.hasOwnProperty("category") && booth.hasOwnProperty("zone"))

    	if(
    		booth.hasOwnProperty("name") && booth.hasOwnProperty("description") &&
		    booth.hasOwnProperty("category") && booth.hasOwnProperty("zone")
	    ) {
    		console.log("enter true", booth.description)
			//TODO: Booth description empty constraint released for IBF
			/* && booth.description !== ""*/
    		return booth.name !== "" &&
			    booth.category !== "" && booth.zone !== ""
	    }else{
    		console.log("enter false")
		}
    	return false
    }
}

export default Booth;
