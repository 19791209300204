import React from "react";

import {
    Dialog,
    TextField,
    Typography,
    Slide,
    Grid,
    Divider,
    MenuItem,
    Button,
    List,
    ListItem,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    FormGroup,
    FormControlLabel,
    Checkbox
} from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardTimePicker
} from '@material-ui/pickers';
import {
    Add,
    KeyboardArrowRight
} from '@material-ui/icons';
import moment from "moment";
import MomentUtils from '@date-io/moment';

import {DialogTitle, DialogContent, DialogActions} from "../../reusable/Dialog"

import {SnackBarVariant} from "../../reusable/SnackBar";
import SessionModel from "../../../model/Session";
import UploadImage from "../../reusable/UploadImage";
import RichText from "../../reusable/RichText";
import SessionDAO from "../../../dao/SessionDAO";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const classes = {}

class InviteToSessionDialog extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            entries: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
            emailTemplate : "FREE_PM_PHYSICAL_ACCEPTED"
        }
    }

    async handleSaveData() {
        try {
            let formattedEntries = []
            for (let e of this.state.entries) {
                if (e.email && e.full_name) {
                    formattedEntries.push(e)
                }
            }
            let result = await SessionDAO.inviteToSession(this.props.sessionId, formattedEntries, this.state.emailTemplate)
            console.log("INVITE RES", result)
            this.onClose(true)
        } catch (e) {
            alert("Keslahan Terjadi")
            console.log(e)
        }
    }

    onClose(success) {
        this.setState({isError: false})
        this.props.closeDialog(success)
    }

    render() {
        const {isOpen} = this.props;

        return (
            <Dialog
                open={isOpen}
                maxWidth="md"
                fullWidth={true}>
                <DialogTitle onClose={() => this.onClose()}>
                    Invite To Session
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <TextField
                                label="Email Template"
                                className={classes.textField}
                                value={this.state.emailTemplate}
                                onChange={evt => this.setState({...this.state, emailTemplate: evt.target.value})}
                                placeholder=""
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }} />
                        </Grid>
                        {
                            [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((obj, key) => {
                                return <>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Email"
                                            value={this.state.entries[key].email}
                                            onChange={evt => {
                                                let entries = [...this.state.entries]
                                                entries[key].email = evt.target.value
                                                this.setState({
                                                    ...this.state,
                                                    entries
                                                })
                                            }}
                                            placeholder=""
                                            fullWidth
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}/>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField
                                            label="Fullname"
                                            value={this.state.entries[key].full_name}
                                            onChange={evt => {
                                                let entries = [...this.state.entries]
                                                entries[key].full_name = evt.target.value
                                                this.setState({
                                                    ...this.state,
                                                    entries
                                                })
                                            }}
                                            placeholder=""
                                            fullWidth
                                            margin="normal"
                                            error={this.state.isError && !this.state.session_speaker}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}/>
                                    </Grid>
                                    <Grid item xs={1}>
                                        Paying
                                        <Checkbox
                                            color={"success"}
                                            value={this.state.entries[key].paying}
                                            onChange={evt => {
                                                let entries = [...this.state.entries]
                                                entries[key].paying = !this.state.entries[key].paying
                                                this.setState({
                                                    ...this.state,
                                                    entries
                                                })
                                            }}
                                            placeholder=""
                                            fullWidth
                                            margin="normal"
                                            error={this.state.isError && !this.state.session_speaker}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}/>
                                    </Grid>
                                </>
                            })
                        }

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.handleSaveData()}
                    >
                        SUBMIT
                    </Button>
                    <Button
                        variant="text"
                        color="primary"
                        onClick={() => this.onClose()}
                    >
                        CANCEL
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}


export default InviteToSessionDialog
