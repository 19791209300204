import Book from "../model/Book";
import Dispatch from "../constant/dispatch";
import BookAction from "../model/BookAction";
import BoothAction from "../model/BoothAction";
import EventAction from "../model/EventAction";

const defaultState = {
    currentBook: new Book(),
    books: [],

    isBookDataLoaded: false
}


function handleLocalChanges(state, action) {

    if (action.type === Dispatch.OPEN_VIEW_BOOK_DIALOG) {
        return {
            ...state,
            currentBook: typeof action.payload === "object" ?
                action.payload :
                state.books.find(book => book.id === action.payload)
        }
    } else if (action.type === Dispatch.CLOSE_VIEW_BOOK_DIALOG) {
        return {
            ...state,
            currentBook: defaultState.currentBook
        }
    } else if (action.type === Dispatch.EDIT_CURRENT_BOOK_VALUE) {
        return {
            ...state,
            currentBook: {
                ...state.currentBook,
                [action.payload.key]: action.payload.value
            }
        }
    } else if (action.type === Dispatch.ON_DELETE_BOOK_BY_ID_RESPONSE_SUCCESS) {
        return {
            ...state,
            books: state.books.filter(book => book.id !== action.payload.id)
        }
    } else if (action.type === Dispatch.ON_SAVE_CURRENT_BOOK_RESPONSE_SUCCESS) {
        return {
            ...state,
            books: state.books.find(b => b.id === action.payload.id) ?
                state.books.map(books => books.id === action.payload.id ? action.payload : books) :
                state.books.concat(action.payload)
        }
    }

    return state
}

export default function reducer(state = defaultState, action) {
    if (action.type === Dispatch.ON_GET_BOOKS_REQUEST) {
        BookAction.getBooks(action.payload)
    } else if (action.type === Dispatch.ON_SAVE_CURRENT_BOOK_REQUEST) {
        let book = {...state.currentBook}
        book.role_id = action.payload
        book.id === -1 ? BookAction.createBook(book) : BookAction.updateBook(book)
    } else if (action.type === Dispatch.ON_GET_BOOKS_RESPONSE_SUCCESS) {
        return {
            ...state,
            books: action.payload,
            isBookDataLoaded: true
        }
    } else if (action.type === Dispatch.ON_UPDATE_ITEM_IMAGE_REQUEST) {
        BookAction.updateBookImage(action.payload.event, action.payload.fieldKey, action.payload.image, action.payload.cb)
    } else if (action.type === Dispatch.ON_UPDATE_ITEM_IMAGE_RESPONSE_SUCCESS) {
        console.log("SUCC TO " + action.payload)
    } else if (action.type === Dispatch.ON_DELETE_BOOK_BY_ID_REQUEST) {
        BookAction.deleteBook({...action.payload})
    } else if (action.type === Dispatch.LOG_OUT) {
        return defaultState
    }
    return handleLocalChanges(state, action)
}
