import React, {Component} from 'react'
import {connect} from "react-redux"
import {compose} from "recompose"

import {withStyles} from '@material-ui/core/styles';
import {withSnackbar} from 'notistack'

import {
    Grid,
    Paper,
    Typography,
    Checkbox,
    CssBaseline,
    CardMedia,
    Avatar,
    FormHelperText,
    FormControlLabel,
    TextField
} from "@material-ui/core";

import {push} from "connected-react-router";
import Dispatch from "../../constant/dispatch";
import SessionModel from "../../model/Session";
import UserAuth from "../../util/UserAuth";
import storage from "../../storage/storage";

import 'bootstrap/dist/css/bootstrap.min.css';
import {Carousel, CarouselItem, Col, Container, Row, Button, Form} from 'react-bootstrap';
import intelliventLogo from "../../asset/logo-dark.png"
import Palette from "../../util/Palette";

import bannerAd from "../../asset/4.jpg"
import banner1Phone from "../../asset/banner_1_phone.PNG"
import banner1Text from "../../asset/banner_1_text.PNG"

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
    },
    form: {
        width: "100%",
        marginTop: theme.spacing(1)
    },
    media: {
        height: 100,
        paddingTop: '56.25%', // 16:9
    },
    submit: {
        marginTop: theme.spacing(3)
    }
});

class NewLogin extends Component {

    constructor(props) {
        super(props)

        this.state = {
            // email: "sgu@sgu.ac.id",
            // password: "sgunumber1",
            email: "",
            password: "",
            rememberMe: false,
            emailIsInvalid: false,
            passwordIsInvalid: false,
            registerCredential: {
                email: "",
                name: "",
                password: "",
                confirmPassword: "",
            },
        }

        if (localStorage.jwtToken) {
            console.log("YOU HAVE A TOKEN MY FRIEND")
            storage.dispatch({type: Dispatch.ON_LOGIN_FROM_SESSION_REQUEST})
        }
    }

    // componentDidMount() {
    // 	if(localStorage.jwtToken) {
    // 		if (!isEmpty(this.props.pages.currentPage)) {
    // 			this.props.history.push(this.props.pages.currentPage.route);
    // 		} else {
    // 			this.props.history.push("/dashboard");
    // 		}
    // 	}
    // }
    //
    // componentWillReceiveProps(nextProps) {
    // 	if (nextProps.users.isAuthenticated) {
    // 		if (!isEmpty(this.props.pages.currentPage)) {
    // 			this.props.history.push(this.props.pages.currentPage.route);
    // 		} else {
    // 			this.props.history.push("/dashboard");
    // 		}
    // 	}
    //
    // 	if (nextProps.errors) {
    // 		this.setState({ errors: nextProps.errors });
    // 	}
    // }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isUserLoggedIn && !prevProps.isUserLoggedIn) {
            if (localStorage.tempUrl) {
                this.props.setUrl(localStorage.tempUrl)
            } else {
                this.props.setUrl("/event-manager")
            }
        }
    }

    togglePage(payload){
        storage.dispatch({type: Dispatch.ON_TOGGLE_LOGIN_PAGE_MODE, payload : payload})
    }

    login() {
        this.props.login(this.state)
        // this.props.setUrl("/event-manager")
    }

    register(e){

        console.log("attempting to register")
        // console.log("curr state", this.state.registerCredential)

        const form = e.currentTarget;
        e.preventDefault();

        storage.dispatch({
            type  : Dispatch.ON_REGISTER_RESPONSE_FAIL,
            payload : {
                msg : ""
            }
        })

        if (form.checkValidity() === false) {
            return storage.dispatch({
                type  : Dispatch.ON_REGISTER_RESPONSE_FAIL,
                payload : {
                    msg : "Please fill in all fields"
                }
            })
        }

        if(this.state.registerCredential.password !== this.state.registerCredential.confirmPassword){
            return storage.dispatch({
                type  : Dispatch.ON_REGISTER_RESPONSE_FAIL,
                payload : {
                    msg : "Passwords not matching"
                }
            })
        }

        storage.dispatch({
            type  : Dispatch.ON_REGISTER_REQUEST,
            payload : {
                ...this.state.registerCredential
            }
        })

    }

    render() {
        const {classes} = this.props

        return (

            <Container fluid style={{
                paddingLeft: 0,
                paddingRight: 0,
                height: '100vh'
            }}>
                <Row style={{
                    height: '100vh'
                }}>
                    <Col xs={9} style={{
                        height: '100vh',
                        backgroundImage: `url(${bannerAd})`,
                        backgroundSize : `cover`
                    }}>

                    </Col>
                    <Col xs={3} style={{
                        display: "flex",
                        alignItems: 'center'
                    }}>
                        <div style={{
                            paddingRight: 30,
                            paddingTop: 50,
                            flex: 1,
                            flexDirection: 'column',
                            display: "flex",
                            justifyContent: "center"
                        }}
                        >
                            <p style={{
                                fontFamily: "Montserrat",
                                fontStyle: "Black",
                                fontSize: 30,
                            }}>
                                INTELLIVENT

                            </p>

                            {/*<p style={{*/}
                            {/*    fontFamily: "Montserrat",*/}
                            {/*    fontStyle: "Bold",*/}
                            {/*    fontSize: 30,*/}
                            {/*}}>*/}
                            {/*    ramein*/}
                            {/*    <br/>*/}
                            {/*    semarak*/}
                            {/*    <br/>*/}
                            {/*    hive*/}
                            {/*</p>*/}


                            <Form style={{
                                flex: 1
                            }} onSubmit={(e)=>{
                                if(this.props.pageState.registerMode){
                                    this.register(e)
                                }else{
                                    e.preventDefault()
                                }
                            }}>
                                {this.props.pageState.registerMode ?
                                    <>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                required
                                                value={this.state.registerCredential.email}
                                                onChange={evt => this.setState({
                                                    ...this.state,
                                                    registerCredential: {
                                                        ...this.state.registerCredential,
                                                        email: evt.target.value,
                                                    },
                                                })}
                                                type="email"
                                                placeholder="Enter email"/>
                                        </Form.Group>

                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Organization Name</Form.Label>
                                            <Form.Control
                                                required
                                                value={this.state.registerCredential.name}
                                                onChange={evt => this.setState({
                                                    ...this.state,
                                                    registerCredential: {
                                                        ...this.state.registerCredential,
                                                        name: evt.target.value,
                                                    },
                                                })}
                                                type="text"
                                                placeholder="Organization Name"/>
                                        </Form.Group>

                                        <Form.Group controlId="formBasicPassword">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control
                                                required
                                                value={this.state.registerCredential.password}
                                                onChange={evt => this.setState({
                                                    ...this.state,
                                                    registerCredential: {
                                                        ...this.state.registerCredential,
                                                        password: evt.target.value,
                                                    },
                                                })}
                                                type="password" placeholder="Password"/>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicPassword">
                                            <Form.Label>Confirm Password</Form.Label>
                                            <Form.Control
                                                required
                                                // onKeyPress={event => {
                                                //         if (event.key === "Enter" && this.state.email && this.state.password) {
                                                //             this.register();
                                                //         }
                                                // }}
                                                value={this.state.registerCredential.confirmPassword}
                                                onChange={evt => this.setState({
                                                    ...this.state,
                                                    registerCredential: {
                                                        ...this.state.registerCredential,
                                                        confirmPassword: evt.target.value,
                                                    },
                                                })}
                                                type="password" placeholder="Confirm Password"/>
                                        </Form.Group>

                                        {/*<Form.Group controlId="formBasicCheckbox">*/}
                                        {/*	<Form.Check type="checkbox" label="Check me out" />*/}
                                        {/*</Form.Group>*/}
                                        <Button block variant="primary"
                                                type="submit"
                                                // onClick={() => this.register()}
                                        >Register</Button>

                                        <p style={{
                                            color:Palette.red,
                                            textAlign : "center"
                                        }}>
                                            {this.props.pageState.errorMessage}
                                            <br/>
                                        </p>

                                        <p style={{
                                            textAlign: "center"
                                        }}>
                                            <a style={{
                                                color: Palette.grey,
                                                flex: 1
                                            }} href={"#"}
                                               onClick={() => {
                                                   this.togglePage(false)
                                               }}
                                            >Already have an account? Login Here!</a>
                                        </p>
                                    </>
                                    :
                                    <>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                value={this.state.email}
                                                onChange={evt => this.setState({email: evt.target.value})}
                                                type="email"
                                                placeholder="Enter email"/>
                                            {/*<Form.Text className="text-muted">*/}
                                            {/*	We'll never share your email with anyone else.*/}
                                            {/*</Form.Text>*/}
                                        </Form.Group>

                                        <Form.Group controlId="formBasicPassword">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control
                                                onKeyPress={event => {
                                                    if (event.key === "Enter" && this.state.email && this.state.password) {
                                                        this.login();
                                                    }
                                                }}
                                                value={this.state.password}
                                                onChange={evt => this.setState({password: evt.target.value})}
                                                type="password" placeholder="Password"/>
                                        </Form.Group>
                                        {/*<Form.Group controlId="formBasicCheckbox">*/}
                                        {/*	<Form.Check type="checkbox" label="Check me out" />*/}
                                        {/*</Form.Group>*/}
                                        <Button type="submit" block variant="primary"
                                                onClick={() => this.login()}
                                        >Login</Button>

                                        <p style={{
                                            color:Palette.red,
                                            textAlign : "center"
                                        }}>
                                            {this.props.pageState.errorMessage}
                                            {/*{this.props.pageState.errorMessage ? JSON.stringify(this.props.pageState.errorMessage) : null}*/}
                                            <br/>
                                        </p>
                                    </>
                                }


                            </Form>

                            <p style={{
                                alignSelf: "center",
                                marginTop: 50
                            }}>
                                © Intellivent 2020
                            </p>


                        </div>
                    </Col>
                </Row>
            </Container>

        )
    }
}

const mapStateToProps = function (storage) {
    return {
        currentUser: storage.user.currentUser,
        isUserLoggedIn: storage.user.isUserLoggedIn,
        pageState : storage.user.pageState
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUrl: url => dispatch(push(url)),
        login: data => dispatch({
            type: Dispatch.ON_LOGIN_REQUEST,
            payload: data
        }),
    }
}

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withStyles(styles),
    withSnackbar
)(NewLogin)
