import React from "react";
import {connect} from "react-redux";
import {compose} from "recompose";
import {push} from "connected-react-router";

import {withStyles} from "@material-ui/core/styles";
import {
    Dialog,
    TextField,
    Typography,
    Slide,
    Grid,
    Divider,
    MenuItem,
    Button,
    List,
    ListItem,
    Card,
    CardHeader,
    CardContent,
    CardActions,
} from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardTimePicker
} from '@material-ui/pickers';
import {
    Add,
    KeyboardArrowRight
} from '@material-ui/icons';
import {DropzoneDialog} from 'material-ui-dropzone'

import RichText from "../../reusable/RichText/"
import UploadImage from "../../reusable/UploadImage"
import {DialogTitle, DialogContent, DialogActions} from "../../reusable/Dialog"

import Dispatch from "../../../constant/dispatch";
import {SnackBarVariant} from "../../reusable/SnackBar";
import BookModel from "../../../model/Book";
import KeyValueEditor from "../../reusable/KeyValueEditor";

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    menu: {
        width: 200,
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ViewBookDialog extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            ...this.props.currentBook,
            isError: false,
            currentBook : {...this.props.currentBook},
            additional_information : {
                dumdum : "tes"
            }
        }
    }

    handleSaveData() {
        if (BookModel.isDataValid(this.state)) {
            this.props.saveCurrentBook(this.props.roleId)
        } else {
            this.setState({isError: true})
            this.props.enqueueSnackBar({
                message: "Data is not complete",
                type: SnackBarVariant.ERROR,
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.isOpen && this.props.isOpen) {
            this.setState({
                ...this.props.currentBooth,
                currentBook  : {...this.props.currentBook}
            })
        }
    }

    onClose() {
        this.props.closeDialog()
    }

    onUploadImage(image, key, cb) {
        console.log("maimaimi uploading " + image + " key " + key)
        console.log("current book is  ",  this.state)
        this.props.uploadImage(this.state, "image", image, (result)=>{
            console.log("hore " +result)
            cb(result)
        })
    }

    onAdditionalInfoUpdated(newStructure){
        this.setState({
            additional_information : newStructure
        })
        this.props.editCurrentBook("additional_information", newStructure)
    }

    render() {
        const {classes, isOpen} = this.props;


        return (
            <Dialog
                open={isOpen}
                maxWidth="md"
                fullWidth={true}
                TransitionComponent={Transition}>
                <DialogTitle onClose={() => this.onClose()}>
                    Book Details
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={4}>
                        <Grid item xs={7}>
                            <TextField
                                label="Book Title"
                                className={classes.textField}
                                value={this.state.title}
                                onChange={evt => this.setState({title: evt.target.value})}
                                onBlur={evt => this.props.editCurrentBook("title", evt.target.value)}
                                placeholder=""
                                fullWidth
                                margin="normal"
                                error={this.state.isError && !this.state.name}
                                InputLabelProps={{
                                    shrink: true,
                                }}/>
                        </Grid>
                        {/*<Grid item xs={7}>*/}
                        {/*<TextField*/}
                        {/*label="Booth Owner"*/}
                        {/*className={classes.textField}*/}
                        {/*value={this.state.owner}*/}
                        {/*onChange={evt => this.setState({owner: evt.target.value})}*/}
                        {/*onBlur={evt => this.props.editCurrentBook("owner", evt.target.value)}*/}
                        {/*placeholder=""*/}
                        {/*fullWidth*/}
                        {/*margin="normal"*/}
                        {/*InputLabelProps={{*/}
                        {/*shrink: true,*/}
                        {/*}} />*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={7} className={classes.textField}>*/}
                        {/*<UploadImage title="Booth Picture" limit={1} />*/}
                        {/*</Grid>*/}
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={5}>
                            <TextField
                                label="Price"
                                className={classes.textField}
                                value={this.state.price}
                                onChange={evt => this.setState({price: evt.target.value})}
                                onBlur={evt => this.props.editCurrentBook("price", evt.target.value)}
                                placeholder=""
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}/>
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                label="Category"
                                className={classes.textField}
                                value={this.state.category}
                                onChange={evt => this.setState({category: evt.target.value})}
                                onBlur={evt => this.props.editCurrentBook("category", evt.target.value)}
                                placeholder=""
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}/>
                        </Grid>

                        <Grid item xs={12}>
                            <KeyValueEditor
                                onChange={(newValue)=>this.onAdditionalInfoUpdated(newValue)}
                                onImageUpload={(image,key,cb)=>this.onUploadImage(image,key,cb)}
                                jsonData={this.state.additional_information}/>
                        </Grid>


                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.handleSaveData()}
                        className={classes.button}>
                        SUBMIT
                    </Button>
                    <Button
                        variant="text"
                        color="primary"
                        onClick={() => this.onClose()}
                        className={classes.button}>
                        CANCEL
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = function (storage) {
    return {
        isOpen: storage.appState.isViewBookDialogOpen,
        currentBooth: storage.book.currentBook,
        roleId: storage.eventManager.currentEvent.role_id
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUrl: url => dispatch(push(url)),
        editCurrentBook: (key, value) => dispatch({
            type: Dispatch.EDIT_CURRENT_BOOK_VALUE,
            payload: {
                key: key,
                value: value
            }
        }),
        saveCurrentBook: roleId => dispatch({
            type: Dispatch.ON_SAVE_CURRENT_BOOK_REQUEST,
            payload: roleId
        }),
        closeDialog: () => dispatch({
            type: Dispatch.CLOSE_VIEW_BOOK_DIALOG
        }),
        enqueueSnackBar: snackBar => dispatch({
            type: Dispatch.ENQUEUE_SNACKBAR,
            payload: snackBar
        }),
        uploadImage: (event, fieldKey, image,cb) => dispatch({
            type: Dispatch.ON_UPDATE_ITEM_IMAGE_REQUEST,
            payload: {event, fieldKey, image,cb}
        })
    }
}

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles),
)(ViewBookDialog);
