import StatisticAction from "../model/StatisticAction"
import Dispatch from "../constant/dispatch"

const defaultState = {
    eventActivities: [],
    scanHistory: [],
    checkIns: []
}

export default function reducer(state = defaultState, action) {
    if(action.type === Dispatch.ON_GET_EVENT_ACTIVITIES_REQUEST) {
        StatisticAction.getEventActivities(action.payload)
    } else if (action.type === Dispatch.ON_GET_EVENT_ACTIVITIES_RESPONSE_SUCCESS) {
        return {
            ...state,
            eventActivities: action.payload
        }
    } else if (action.type === Dispatch.ON_GET_EVENT_ACTIVITIES_RESPONSE_FAIL) {

    } else if (action.type === Dispatch.ON_GET_EVENT_CHECKINS_REQUEST) {
        StatisticAction.getEventCheckIns(action.payload)
    } else if (action.type === Dispatch.ON_GET_EVENT_CHECKINS_RESPONSE_SUCCESS) {
        return {
            ...state,
            checkIns: action.payload
        }
    } else if (action.type === Dispatch.ON_GET_EVENT_CHECKINS_RESPONSE_FAIL) { 

    } else if (action.type === Dispatch.ON_GET_EVENT_QR_SCAN_HISTORY_REQUEST) {
        StatisticAction.getEventQRHistory(action.payload)
    } else if (action.type === Dispatch.ON_GET_EVENT_QR_SCAN_HISTORY_RESPONSE_SUCCESS) {
        return {
            ...state,
            scanHistory: action.payload
        }
    } else if (action.type === Dispatch.ON_GET_EVENT_QR_SCAN_HISTORY_RESPONSE_FAIL) { 

    }


    return {
        ...state
    }
}