import React from "react"
import {
	Tooltip,
	IconButton
} from "@material-ui/core";
import {
	RemoveRedEye,
	Edit
} from '@material-ui/icons';
import Dispatch from "../../../../constant/dispatch";
import storage from "../../../../storage/storage";

export default [
	{
		name: "id",
		label: "id",
		options: {
			display: "false",
			filter: false,
			sort: false,
			viewColumns: false,
		}
	},
	{
		name: "title",
		label: "Survey Name",
		options: {
			filter: false,
			sort: true,
		}
	},
	{
		name: "question_json",
		label: "Total Questions",
		options: {
			filter: false,
			sort: true,
			customBodyRender: value => value.length
		}
	},
	{
		name: "id",
		label: "Options",
		options: {
			filter: false,
			sort: false,
			customBodyRender: value => <>
				<Tooltip title="View Responses">
					<IconButton
						onClick={() => storage.dispatch({
							type: Dispatch.OPEN_VIEW_SURVEY_RESULT_DIALOG,
							payload: value
						})}>
						<RemoveRedEye />
					</IconButton>
				</Tooltip>
				<Tooltip title="Edit">
					<IconButton
						onClick={() => storage.dispatch({
							type: Dispatch.OPEN_VIEW_SURVEY_DIALOG,
							payload: value
						})}>
						<Edit />
					</IconButton>
				</Tooltip>
			</>
		}
	},
]