import moment from "moment"

class Session {
    constructor(roleId) {
        this.id = -1
        this.session_name = "" //ada, namanya session_name //WAJIB
        this.session_speaker = ""// ada, namanyau session_speaker //WAJIB
        this.date = moment()// ini gk ada, krn ketauan dari timestart ama time end
        this.start_time = moment() // ada, namanya start_time //WAJIB
        this.end_time = moment() // ada, namanya end_time //WAJIB
        this.type = "type1" // sementara gk ada
        this.quota = 0 // ada
        this.price = 0 // kosongin dlu
        this.max_ticket_per_buy = 1
        this.description = "" // ada //WAJIB
        this.zone = "" // ada, namanya zone //WAJIB
	    this.enabled = 1
        this.role_id = roleId
        this.image_url = ""
        this.registrations = []
	    this.qr_codes = []
        this.online_mode = false
        this.online_payment = true
        this.additional_information = {}
        this.allow_registration = true
        this.template_variables = {}
        this.stream_url = null
    }

	static isDataValid(session) {
		if(
			session.hasOwnProperty("session_name") && session.hasOwnProperty("session_speaker") &&
			session.hasOwnProperty("start_time") && session.hasOwnProperty("end_time") &&
			session.hasOwnProperty("description") && session.hasOwnProperty("zone")
		) {
			return session.session_name !== "" && session.session_speaker !== "" &&
				session.description !== "" && session.zone !== ""
		}
		return false
	}
}

export default Session;
