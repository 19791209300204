import moment from "moment"
import Dispatch from "../constant/dispatch"
import Event from "../model/Event"
import EventAction from "../model/EventAction"

const defaultState = {
	currentEvent: new Event(),
	events: [],

	isEventDataLoaded: false
}

function handleLocalChanges(state, action) {
	if(action.type === Dispatch.OPEN_CREATE_EVENT_DIALOG) {
		return {
			...state,
			currentEvent: new Event()
		}
	} else if(action.type === Dispatch.CLOSE_CREATE_EVENT_DIALOG) {
		return {
			...state,
			currentEvent: defaultState.currentEvent
		}
	} else if(action.type === Dispatch.SAVE_CURRENT_EVENT) {

		EventAction.createEvent(state.currentEvent)


	} else if(action.type === Dispatch.EDIT_CURRENT_EVENT_VALUE) {
		return {
			...state,
			currentEvent: {
				...state.currentEvent,
				[action.payload.key]: action.payload.value
			}
		}
	} else if(action.type === Dispatch.SET_CURRENT_EVENT) {
		return {
			...state,
			currentEvent: typeof action.payload === "number" ?
				state.events.find(event => event.id === action.payload) :
				action.payload
		}
	} else if(action.type === Dispatch.ON_UPDATE_EVENT_IMAGE_BANNER_RESPONSE_SUCCESS){
		return {
			...state,
			currentEvent:{
				...state.currentEvent,
				event_banner_url:action.payload
			}
		}
	} else if(action.type === Dispatch.ON_UPDATE_EVENT_IMAGE_BANNER_RESPONSE_SUCCESS){
        return {
            ...state,
            currentEvent:{
                ...state.currentEvent,
                event_banner_url:action.payload
            }
        }
    } else if(action.type === Dispatch.ON_UPDATE_EVENT_IMAGE_RESPONSE_SUCCESS){
        return {
            ...state,
            currentEvent:{
                ...state.currentEvent,
                [action.payload.fieldKey]: action.payload.location
            }
        }
    }
	return state
}


export default function reducer(state = defaultState, action) {
	if(action.type === Dispatch.ON_GET_ALL_EVENTS_REQUEST) {
		EventAction.getEvents()
	} else if(action.type === Dispatch.ON_GET_EVENT_BY_ID_REQUEST) {
		EventAction.getEventById(action.payload)
	} else if(action.type === Dispatch.RETURN_TO_EVENT_MANAGER_PAGE){
		return defaultState
	} else if(action.type === Dispatch.ON_SAVE_EVENT_REQUEST) {
		EventAction.updateEvent({...action.payload})
	} else if(action.type === Dispatch.ON_GET_ALL_EVENTS_RESPONSE_SUCCESS) {
		return {
			...state,
			events: action.payload,
			isEventDataLoaded: true
		}
	} else if(action.type === Dispatch.ON_GET_EVENT_BY_ID_RESPONSE_SUCCESS) {
		return {
			...state,
			currentEvent: action.payload,
		}
	} else if(action.type === Dispatch.ON_CREATE_EVENT_RESPONSE_SUCCESS){
		EventAction.getEvents()
		/*return {
			...state,
			events: state.events.concat(action.payload),
			currentEvent: action.payload
		}*/
	} else if(action.type === Dispatch.ON_SAVE_EVENT_RESPONSE_SUCCESS) {

		return {
			...state,
			events: state.events.map(event => event.id === action.payload.id ? action.payload : event),
			currentEvent: action.payload
		}

	} else if(action.type === Dispatch.LOG_OUT) {
		return defaultState
	} else if(action.type === Dispatch.ON_UPDATE_EVENT_IMAGE_BANNER_REQUEST){
		EventAction.updateEventBanner(action.payload.event, action.payload.image)
	} else if(action.type === Dispatch.ON_UPDATE_EVENT_IMAGE_REQUEST){
		EventAction.updateEventImage(action.payload.event, action.payload.fieldKey, action.payload.image)
	} else if(action.type === Dispatch.ON_UPDATE_EVENT_IMAGE_FROM_CROP_REQUEST){
		EventAction.updateEventImageFromCrop(action.payload.event, action.payload.fieldKey, action.payload.image)
	}
	return handleLocalChanges(state, action)
}
