export const questionType = [
	{
		value: "GUIDE",
		label: "Additional Explanation"
	},
	{
		value: "OPTION",
		label: "Likert Scale Question"
	},
	{
		value: "MCQ",
		label: "Multiple Choice Question"
	},
	{
		value: "OPENENDED",
		label: "Open Ended Question"
	}
]
