import React from "react"
import clsx from 'clsx';
import {
	IconButton,
	Button,
	Snackbar,
	SnackbarContent
} from "@material-ui/core";
import {
	Clear,
	CheckCircle,
	Warning,
	Error,
	Info
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { amber, green, blue, red } from '@material-ui/core/colors';

export const SnackBarVariant = {
	INFO: "info",
	SUCCESS: "success",
	ERROR: "error"
}

export function generateSnackBar(props, message, variant, options=[]) {
	props.enqueueSnackbar(
		message,
		{
			key: Math.round(Math.random()*1000),
			variant: variant,
			action: key => <>
				{
					options.map(option => <Button
						variant="text"
						color="secondary"
						onClick={() => {
							option.action()
							props.closeSnackbar(key)
						}}>
						{option.text}
					</Button>)
				}
				<IconButton onClick={() => props.closeSnackbar(key)}>
					<Clear color="secondary"/>
				</IconButton>
			</>
		}
	)
}

const variantIcon = {
	success: CheckCircle,
	warning: Warning,
	error: Error,
	info: Info,
};

const useStyles = makeStyles(theme => ({
	success: {
		backgroundColor: green[600],
	},
	error: {
		backgroundColor: red[700]
	},
	info: {
		backgroundColor: blue[700],
	},
	warning: {
		backgroundColor: amber[700],
	},
	icon: {
		fontSize: 20,
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: theme.spacing(1),
	},
	message: {
		display: 'flex',
		alignItems: 'center',
	},
}));

export default function SnackBar(props) {
	const classes = useStyles();
	const { className, message, variant, ...other } = props;
	const Icon = variantIcon[variant];
	
	return (
		<SnackbarContent
			className={clsx(classes[variant], className)}
			aria-describedby="client-snackbar"
			message={<span id="client-snackbar" className={classes.message}>
				<Icon className={clsx(classes.icon, classes.iconVariant)} />
				{message}
            </span>}
			{...other}
		/>
	);
}