import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { push } from "connected-react-router";

import { withStyles } from "@material-ui/core/styles";
import {
	Dialog,
	TextField,
	Typography,
	Slide,
	Grid,
	Divider,
	MenuItem,
	Button,
	List,
	ListItem,
	Card,
	CardHeader,
	CardContent,
	CardActions,
} from "@material-ui/core";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
	KeyboardTimePicker
} from '@material-ui/pickers';
import {
	Add,
	KeyboardArrowRight
} from '@material-ui/icons';
import { DropzoneDialog } from 'material-ui-dropzone'
import QRCode from 'qrcode.react';

import { DialogTitle, DialogContent, DialogActions } from "./Dialog"

import Dispatch from "../../constant/dispatch";
import qrCode from "../../reducer/qrCodeReducer";

const styles = theme => ({
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	menu: {
		width: 200,
	},
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

class ViewQRDialog extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			name: "",
			owner: "",
			description: "",
		}
	}
	
	onClose() {
		this.props.closeDialog()
	}
	
	static downloadQR(qrId) {
		const canvas = document.getElementById(qrId);
		const pngUrl = canvas
			.toDataURL("image/png")
			.replace("image/png", "image/octet-stream");
		let downloadLink = document.createElement("a");
		downloadLink.href = pngUrl;
		downloadLink.download = qrId + ".png";
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	}
	
	render() {
		const { classes, isOpen, currentData } = this.props;
		console.log("CURRDATA",currentData)
		return (
			<Dialog
				open={isOpen}
				maxWidth="md"
				fullWidth={true}
				TransitionComponent={Transition}>
				<DialogTitle onClose={() => this.onClose()}>
					Generated QR
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={4}>
						{
							currentData.qr_codes.map(qr => <Grid
								item
								xs={Math.max(12/currentData.qr_codes.length, 3)}
								direction={"column"}
								alignItems={"center"}
								style={{display: "flex"}}>
								<Typography variant={"caption"} color={"textSecondary"} style={{paddingBottom: 8}}>
									{qr.type_description || "-"}
								</Typography>
								<QRCode
									id={qr.code}
									value={qr.code}
									level={"L"}
								/>
								<Button
									variant="text"
									color="primary"
									onClick={() => ViewQRDialog.downloadQR(qr.code)}
									className={classes.button}>
									Download QR
								</Button>
							</Grid>)
						}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						variant="text"
						color="primary"
						onClick={() => this.onClose()}
						className={classes.button}>
						CLOSE
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

const mapStateToProps = function(storage) {
	return {
		isOpen: storage.appState.isViewQRDialogOpen,
		currentData: storage.qrCode.currentData
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setUrl: url => dispatch(push(url)),
		closeDialog: () => dispatch({
			type: Dispatch.CLOSE_VIEW_QR_DIALOG
		})
	}
}

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(styles),
)(ViewQRDialog);