import axios from "axios"
import { push } from "connected-react-router"
import moment from "moment"

import storage from "../storage/storage"
import Dispatch from "../constant/dispatch"
import server from "../constant/server"
import UserAuth from "../util/UserAuth"
import { SnackBarVariant } from "../component/reusable/SnackBar"

export default {
	getEvents: () => {
		axios.get(server + "/events")
			.then(response => {
				console.log("received events", response)
				storage.dispatch({type: Dispatch.ON_GET_ALL_EVENTS_RESPONSE_SUCCESS, payload: response.data.map(event => {
					return {
						...event,
                        short_description : response.short_description ? response.short_description : "",
                        start_time: moment(event.start_time),
						end_time: moment(event.end_time)
					}
				})})
			})
			.catch(err => {
				console.log(err.response)
				storage.dispatch({type: Dispatch.ON_GET_EVENT_BY_ID_RESPONSE_FAIL})
			})
	},

	getEventById: id => {
		axios.get(server + "/events/" + id)
			.then(response => {
				console.log("received event", response)
				storage.dispatch({type: Dispatch.ON_GET_EVENT_BY_ID_RESPONSE_SUCCESS, payload: {
					...response.data,
					short_description : response.short_description ? response.short_description : "",
					start_time: moment(response.data.start_time),
					end_time: moment(response.data.end_time)
				}})
			})
			.catch(err => {
				console.log(err.response)
				storage.dispatch({type: Dispatch.ON_GET_EVENT_BY_ID_RESPONSE_FAIL})
			})
	},

	updateEventBanner : (event, image)=>{
        let formData = new FormData();
        formData.append('upload',image[0])
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        axios.post(server + "/events/" + event.id+"/event_banner/upload", formData, config)
            .then(response => {
                storage.dispatch({type: Dispatch.ON_UPDATE_EVENT_IMAGE_BANNER_RESPONSE_SUCCESS, payload:response.data.location})
            })
            .catch(err => {
                console.log(err.response)
                storage.dispatch({type: Dispatch.ON_UPDATE_EVENT_IMAGE_BANNER_RESPONSE_FAIL})
            })
	},

	updateEventImageFromCrop : (event, fieldKey, image) =>{
		let formData = new FormData();
		// formData.append("fname", "event_icon.png")
		// formData.append('data',image)

		console.log('blob', image)
		formData.append('upload', image, image.name);

		const config = {
			headers: {
				'Access-Control-Allow-Origin': '*',
				'content-type': 'multipart/form-data'
			}
		}

		axios.post(server + `/events/${event.id}/${fieldKey}/upload`, formData, config)
			.then(response => {
				storage.dispatch({type: Dispatch.ON_UPDATE_EVENT_IMAGE_RESPONSE_SUCCESS, payload:{
						location : response.data.location,
						fieldKey : fieldKey+"_url"
					}})
			})
			.catch(err => {
				console.log(err.response)
				storage.dispatch({type: Dispatch.ON_UPDATE_EVENT_IMAGE_RESPONSE_FAIL})
			})
	},

    updateEventImage: (event, fieldKey, image)=>{
        let formData = new FormData();
        formData.append('upload',image[0])
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        axios.post(server + `/events/${event.id}/${fieldKey}/upload`, formData, config)
            .then(response => {
                storage.dispatch({type: Dispatch.ON_UPDATE_EVENT_IMAGE_RESPONSE_SUCCESS, payload:{
					location : response.data.location,
					fieldKey : fieldKey+"_url"
				}})
            })
            .catch(err => {
                console.log(err.response)
                storage.dispatch({type: Dispatch.ON_UPDATE_EVENT_IMAGE_RESPONSE_FAIL})
            })
    },

	updateEvent: event => {
		const roleIdHolder = event.role_id
		delete event.role_id
		event.start_time = event.start_time.format()
		event.end_time = event.end_time.format()
		axios.patch(server + "/events/" + event.id, event)
			.then(response => {
				console.log("updated event", response)
				storage.dispatch({type: Dispatch.ON_SAVE_EVENT_RESPONSE_SUCCESS, payload: {
						...event,
						role_id: roleIdHolder,
						start_time: moment(event.start_time),
						end_time: moment(event.end_time)
					}
				})
				storage.dispatch({
					type: Dispatch.ENQUEUE_SNACKBAR,
					payload: {
						id: Math.round(Math.random()*1000),
						message: "Event configuration saved.",
						type: SnackBarVariant.SUCCESS
					}
				})
			})
			.catch(err => {
				console.log(err.response)
				storage.dispatch({type: Dispatch.ON_SAVE_EVENT_RESPONSE_FAIL})
			})
	},

	createEvent : event => {
		axios.post(server + "/events", event)
			.then(response => {
				storage.dispatch({type: Dispatch.ON_CREATE_EVENT_RESPONSE_SUCCESS, payload: {
						...event,
						// role_id: roleIdHolder,
						start_time: moment(event.start_time),
						end_time: moment(event.end_time)
					}
				})
				storage.dispatch({
					type: Dispatch.ENQUEUE_SNACKBAR,
					payload: {
						id: Math.round(Math.random()*1000),
						message: "Event Successfully Created",
						type: SnackBarVariant.SUCCESS
					}
				})
			})
			.catch(err => {
				console.log(err.response)
				storage.dispatch({type: Dispatch.ON_SAVE_EVENT_RESPONSE_FAIL})
			})
	}
}
