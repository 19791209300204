import axios from "axios"
import { push } from "connected-react-router"

import storage from "../storage/storage"
import Dispatch from "../constant/dispatch"
import server from "../constant/server"
import UserAuth from "../util/UserAuth"
import { SnackBarVariant } from "../component/reusable/SnackBar"

export default {
	getBooths: eventId => {
		axios.get(server + "/events/" + eventId + "/booths")
			.then(response => {
				console.log("booth data", response)
				storage.dispatch({type: Dispatch.ON_GET_BOOTHS_RESPONSE_SUCCESS, payload: response.data.map(booth => {
					return {
						...booth,
						qr_codes: []
					}
				})})
			})
			.catch(err => {
				console.log(err.response)
				storage.dispatch({type: Dispatch.ON_GET_BOOTHS_RESPONSE_FAIL})
				if(err.response === undefined)
				storage.dispatch({
					type: Dispatch.ENQUEUE_SNACKBAR,
					payload: {
						id: Math.round(Math.random()*1000),
						message: "Failed to retrieve data",
						type: SnackBarVariant.ERROR,
						options: [{
							text: "RETRY",
							action: () => storage.dispatch({
								type: Dispatch.ON_GET_BOOTHS_REQUEST,
								payload: eventId
							})
						}]
					}
				})
			})
	},

	createBooth: booth => {
		delete booth.id
		axios.post(server + "/booths", booth)
			.then(response => {
				console.log("booth created", response)
				storage.dispatch({
					type: Dispatch.ON_SAVE_CURRENT_BOOTH_RESPONSE_SUCCESS,
					payload: {
						...booth,
						id: response.data.insert_id
					}
				})
				storage.dispatch({type: Dispatch.CLOSE_VIEW_BOOTH_DIALOG})
			})
			.catch(err => {
				console.log(err.response)
				storage.dispatch({type: Dispatch.ON_SAVE_CURRENT_BOOTH_RESPONSE_FAIL})
			})
	},

	updateBooth: booth => {
		axios.patch(server + "/booths/" + booth.id, booth)
			.then(response => {
				console.log("booth updated", response)
				storage.dispatch({type: Dispatch.ON_SAVE_CURRENT_BOOTH_RESPONSE_SUCCESS, payload: booth})
				storage.dispatch({type: Dispatch.CLOSE_VIEW_BOOTH_DIALOG})
			})
			.catch(err => {
				console.log(err.response)
				storage.dispatch({type: Dispatch.ON_SAVE_CURRENT_BOOTH_RESPONSE_FAIL})
			})
	},

	deleteBooth: booth => {
		axios.delete(server + "/booths/" + booth.id)
			.then(response => {
				console.log("booth deleted", response)
				storage.dispatch({type: Dispatch.ON_DELETE_BOOTH_BY_ID_RESPONSE_SUCCESS, payload: booth})
			})
			.catch(err => {
				console.log(err.response)
				storage.dispatch({type: Dispatch.ON_DELETE_BOOTH_BY_ID_RESPONSE_FAIL})
			})
	},
}
