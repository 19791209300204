import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { push } from "connected-react-router";

import { withStyles } from "@material-ui/core/styles";
import {
    Dialog,
    TextField,
    Typography,
    Slide,
    Grid,
    Divider,
    MenuItem,
    Button,
    List,
    ListItem,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    ListItemSecondaryAction,
    IconButton,
    Tooltip,
    AppBar,
    Toolbar, Paper
} from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import {
    Add,
    KeyboardArrowRight,
    DeleteOutline,
    Close
} from '@material-ui/icons';

import { DialogTitle, DialogContent, DialogActions } from "../../reusable/Dialog"

import Question from "../../../model/Question"
import Dispatch from "../../../constant/dispatch";
import PieChart from "../../reusable/Chart/PieChart";
import BarChart from "../../reusable/Chart/BarChart";

const styles = theme => ({
    listStyle: {
        maxHeight: 300,
        overflowY: "auto"
    },
});

class TextList extends React.Component {
    
    
    render() {
        const { classes, data } = this.props;

        return (
            <List className={classes.listStyle}>
                {
                    data.map((text, index) => <div key={index}>
                        <ListItem>
	                        <Typography variant="caption">{text}</Typography>
                        </ListItem>
                        {
                            index + 1 !== data.length && <Divider/>
                        }
                    </div>)
                }
            </List>
        );
    }
}

const mapStateToProps = function(storage) {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles),
)(TextList);
