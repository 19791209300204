import React, { Component } from 'react'
import { connect } from "react-redux"
import { compose } from "recompose"

import { withStyles } from '@material-ui/core/styles';
import {
	Grid,
} from "@material-ui/core";
import Dispatch from "../../../constant/dispatch";

const styles = theme => ({
	root: {
		display: 'flex',
	},
});

class Events extends Component {
	
	render() {
		const classes = this.props.classes
		
		return <div className={classes.root}>
			Content
		</div>
	}
}

const mapStateToProps = function(storage) {
	return {
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		testo: value => dispatch({
			type: Dispatch.TEST,
			payload: value
		})
	}
}

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(styles)
)(Events)
