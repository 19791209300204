import React, { Component } from 'react'
import { connect } from "react-redux"
import { compose } from "recompose"
import { push } from "connected-react-router";
import ColorPicker from 'material-ui-color-picker'
import { withStyles } from '@material-ui/core/styles';
import {
    Grid,
    TextField,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Button,
    Typography,
    Divider,
    List,
    ListItem,
    Menu,
    MenuItem,
    IconButton
} from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardTimePicker,
} from '@material-ui/pickers';
import {
    Clear
} from '@material-ui/icons';
import moment from "moment";
import MomentUtils from '@date-io/moment';
import { withSnackbar } from 'notistack'
import { SnackBarVariant, generateSnackBar } from "../../reusable/SnackBar"
import UploadImage from "../../reusable/UploadImage"
import RichText from "../../reusable/RichText/"

import EventModel from "../../../model/Event"

import { eventTypes, pricingPlan, targetAudience } from "../../eventManager/config/eventDataDisplayConfig"
import Dispatch from "../../../constant/dispatch";
import storage from "../../../storage/storage";
import {DropzoneArea} from "material-ui-dropzone";
import AnnouncementAction from "../../../model/AnnouncementAction";
import EventAction from "../../../model/EventAction";

const styles = theme => ({
    root: {
        display: 'flex',
    },
    pricingPlanCard: {
        marginRight: "18px"
    },
    button: {
        marginRight: "10px"
    }
});

class EventDetail extends Component {

    constructor(props) {
        super(props)
        this.state = {
            ...this.props.currentEvent,
            isError: false
        }

        this.props.getEventById(this.props.currentEvent.id)
    }

    handleSaveData() {
        if(EventModel.isDataValid(this.state)) {
            this.props.saveCurrentEvent(this.state)
        } else {
            this.setState({isError: true})
            this.props.enqueueSnackBar({
                message: "Data is not complete",
                type: SnackBarVariant.ERROR,
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(JSON.stringify(prevProps.currentEvent) !== JSON.stringify(this.props.currentEvent)) {
            this.setState({
                ...this.props.currentEvent
            })
        }
    }

    render() {
        const { classes, currentEvent } = this.props

        return <div className={classes.root}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Typography color="textPrimary" variant="h5">Event Configuration</Typography>
                    {/*<Typography color="textSecondary" variant="body2">*/}
                    {/*[insert explanation here]*/}
                    {/*Lorem Ipsum is simply dummy text of the printing and typesetting industry.*/}
                    {/*Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,*/}
                    {/*when an unknown printer took a galley of type and scrambled it to make a type*/}
                    {/*specimen book. It has survived not only five centuries, but also the leap*/}
                    {/*into electronic typesetting, remaining essentially unchanged. It was popularised*/}
                    {/*in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,*/}
                    {/*and more recently with desktop publishing software like Aldus PageMaker including*/}
                    {/*versions of Lorem Ipsum.*/}
                    {/*</Typography>*/}
                </Grid>
                <Grid item xs={7}>
                    <TextField
                        label="Event Name"
                        className={classes.textField}
                        value={this.state.name}
                        onChange={evt => this.setState({name: evt.target.value})}
                        placeholder=""
                        fullWidth
                        error={this.state.isError && !this.state.name}
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }} />
                </Grid>
                <Grid item xs={5}>
                    {/*<TextField*/}
                    {/*select*/}
                    {/*fullWidth*/}
                    {/*label="Event Type"*/}
                    {/*className={classes.textField}*/}
                    {/*value={this.state.type}*/}
                    {/*onChange={evt => this.setState({type: evt.target.value})}*/}
                    {/*SelectProps={{*/}
                    {/*MenuProps: {*/}
                    {/*className: classes.menu,*/}
                    {/*},*/}
                    {/*}}*/}
                    {/*margin="normal">*/}
                    {/*{eventTypes.map(option => (*/}
                    {/*<MenuItem key={option.value} value={option.value}>*/}
                    {/*{option.label}*/}
                    {/*</MenuItem>*/}
                    {/*))}*/}
                    {/*</TextField>*/}
                </Grid>
                <Grid item xs="auto">
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            format="DD/MM/YYYY"
                            margin="normal"
                            value={this.state.start_time}
                            onChange={date => this.setState({start_time: date})}
                            className={classes.textField}
                            label="Event Start Date"
                            error={this.state.isError && !this.state.start_time}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs="auto">
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            format="DD/MM/YYYY"
                            margin="normal"
                            value={this.state.end_time}
                            onChange={date => this.setState({end_time: date})}
                            className={classes.textField}
                            label="Event End Date"
                            error={this.state.isError && !this.state.end_time}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item container xs={12}>
                    {/*<Grid item xs={12}>*/}
                    {/*<Typography color="textSecondary" variant="caption">Pricing Plan</Typography>*/}
                    {/*</Grid>*/}
                    {/*{*/}
                    {/*pricingPlan.map((plan, index) => <Grid item xs={3} key={"pricingPlan"+index}>*/}
                    {/*<Card className={index !== pricingPlan.length-1 ? classes.pricingPlanCard : ""}>*/}
                    {/*<CardHeader title={<Typography variant="subtitle1">{plan.title}</Typography>}/>*/}
                    {/*<CardContent>*/}
                    {/*<List>*/}
                    {/*<Divider/>*/}
                    {/*{*/}
                    {/*plan.content.map((content, i) => <div key={content+i}>*/}
                    {/*<ListItem>*/}
                    {/*<Typography variant="caption">{content}</Typography>*/}
                    {/*</ListItem>*/}
                    {/*<Divider/>*/}
                    {/*</div>)*/}
                    {/*}*/}
                    {/*</List>*/}
                    {/*</CardContent>*/}
                    {/*<CardActions>*/}
                    {/*<Button*/}
                    {/*fullWidth*/}
                    {/*variant={plan.value === this.state.pricingPlan ? "contained" : "outlined"}*/}
                    {/*color="primary"*/}
                    {/*onClick={() => this.setState({pricingPlan: plan.value})}*/}
                    {/*className={classes.button}>*/}
                    {/*CHOOSE PLAN*/}
                    {/*</Button>*/}
                    {/*</CardActions>*/}
                    {/*</Card>*/}
                    {/*</Grid>)*/}
                    {/*}*/}
                </Grid>
                <Grid item xs="auto">
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardTimePicker
                            margin="normal"
                            label="Open Time"
                            value={this.state.start_time}
                            onChange={date => this.setState({start_time: date})}
                            className={classes.textField}
                            error={this.state.isError && !this.state.start_time}
                            KeyboardButtonProps={{
                                'aria-label': 'change time',
                            }} />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardTimePicker
                            margin="normal"
                            label="Close Time"
                            value={this.state.end_time}
                            onChange={date => this.setState({end_time: date})}
                            className={classes.textField}
                            error={this.state.isError && !this.state.end_time}
                            KeyboardButtonProps={{
                                'aria-label': 'change time',
                            }} />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={11} className={classes.textField}>
                    <UploadImage
                        event={this.props.currentEvent}
						image={this.props.currentEvent.event_banner_url}
                        onImageUploaded={(event, image)=>{this.props.uploadImage(event,"event_banner", image)}}
                        title="Event Banner" limit={1} />
                </Grid>
                <Grid item xs={11} className={classes.textField}>
                    <UploadImage
                        event={this.props.currentEvent}
                        image={this.props.currentEvent.event_map_url}
                        onImageUploaded={(event, image)=>{this.props.uploadImage(event,"event_map",image)}}
                        title="Event Map" limit={1} />
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        id="venue"
                        label="Event Venue"
                        multiline
                        fullWidth
                        rows="4"
                        value={this.state.venue}
                        onChange={evt => this.setState({venue: evt.target.value})}
                        className={classes.textField}
                        error={this.state.isError && !this.state.venue}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }} />
                </Grid>
                <Grid item xs={3}>
                    {/*<TextField*/}
                    {/*select*/}
                    {/*fullWidth*/}
                    {/*label="Target Audience"*/}
                    {/*className={classes.textField}*/}
                    {/*value={this.state.targetAudience}*/}
                    {/*onChange={evt => this.setState({targetAudience: evt.target.value})}*/}
                    {/*SelectProps={{*/}
                    {/*MenuProps: {*/}
                    {/*className: classes.menu,*/}
                    {/*},*/}
                    {/*}}*/}
                    {/*margin="normal">*/}
                    {/*{targetAudience.map(option => (*/}
                    {/*<MenuItem key={option.value} value={option.value}>*/}
                    {/*{option.label}*/}
                    {/*</MenuItem>*/}
                    {/*))}*/}
                    {/*</TextField>*/}
                </Grid>
                <Grid item xs={11}>
                    <TextField
                        label="Short Description"
                        className={classes.textField}
                        value={this.state.short_description}
                        onChange={evt => this.setState({
                            short_description: evt.target.value.length > 20 ? this.state.short_description : evt.target.value
                        })}
                        placeholder=""
                        fullWidth
                        helperText={this.state.short_description.length + "/20 (Max 20 characters.)"}
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }} />
                </Grid>

                {/*<Grid item xs="auto">*/}
                {/*<DropzoneArea*/}
                {/*onChange={files=>this.setState({files: files})}*/}
                {/*/>*/}
                {/*</Grid>*/}
                <Grid item xs={12}/>
                <Grid item xs/>
                <Grid item xs={"auto"}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.handleSaveData()}
                        className={classes.button}>
                        SAVE CHANGES
                    </Button>
                    <Button
                        variant="text"
                        color="primary"
                        onClick={() => this.setState({...this.props.currentEvent})}>
                        RESET
                    </Button>
                </Grid>
            </Grid>
        </div>
    }
}

const mapStateToProps = function(storage) {
    return {
        currentEvent: storage.eventManager.currentEvent
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUrl: url => dispatch(push(url)),
        saveCurrentEvent: event => dispatch({
            type: Dispatch.ON_SAVE_EVENT_REQUEST,
            payload: event
        }),
        enqueueSnackBar: snackBar => dispatch({
            type: Dispatch.ENQUEUE_SNACKBAR,
            payload: snackBar
        }),
        getEventById: id => dispatch({
            type: Dispatch.ON_GET_EVENT_BY_ID_REQUEST,
            payload: id
        }),
        uploadImageBanner: (event, image) => dispatch({
            type: Dispatch.ON_UPDATE_EVENT_IMAGE_BANNER_REQUEST,
            payload: {event,image}
        }),
        uploadImage: (event, fieldKey, image) => dispatch({
            type: Dispatch.ON_UPDATE_EVENT_IMAGE_REQUEST,
            payload: {event, fieldKey, image}
        })
        // {
        //     type: Dispatch.ON_GET_EVENT_BY_ID_REQUEST,
        //         payload: id
        //     EventAction.updateEventBanner(event,image)
        // }
    }
}

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withStyles(styles),
    withSnackbar
)(EventDetail)
