import React, { Component } from 'react'
import { connect } from "react-redux"
import { compose } from "recompose"

import { push } from "connected-react-router"

import { withStyles } from '@material-ui/core/styles';
import {
	Typography,
	CssBaseline,
	Avatar,
	AppBar,
	Toolbar,
	Drawer,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
} from "@material-ui/core";
import {
	LibraryBooks,
	SupervisorAccount,
	ExitToApp
} from '@material-ui/icons';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"

import Header from "./Header"
import About from "./about/About"
import Events from "./events/Events"

import Dispatch from "../../constant/dispatch";
import Login from "../login/Login";

const styles = theme => ({
	root: {
		display: 'flex',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	toolbar: theme.mixins.toolbar,
});

class Home extends Component {
	
	render() {
		const classes = this.props.classes
		
		return <div className={classes.root}>
			<CssBaseline />
			<Header/>
			<main className={classes.content}>
				<div className={classes.toolbar} />
				<Router>
					<Switch location={this.props.location}>
						<Route exact path="/" component={About} />
						<Route path="/about" component={About} />
						<Route path="/events" component={Events} />
					</Switch>
				</Router>
			</main>
		</div>
	}
}

const mapStateToProps = function(storage) {
	return {
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setUrl: url => dispatch(push(url))
	}
}

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(styles)
)(Home)
