export default {
	TEST: "TEST",

	ON_LOGIN_REQUEST: "ON_LOGIN_REQUEST",
	ON_LOGIN_RESPONSE_SUCCESS: "ON_LOGIN_RESPONSE_SUCCESS",
	ON_LOGIN_RESPONSE_FAIL: "ON_LOGIN_RESPONSE_FAIL",

	ON_TOGGLE_LOGIN_PAGE_MODE : "ON_TOGGLE_LOGIN_PAGE_MODE",

	ON_REGISTER_REQUEST: "ON_REGISTER_REQUEST",
	ON_REGISTER_RESPONSE_SUCCESS : "ON_REGISTER_RESPONSE_SUCCESS",
	ON_REGISTER_RESPONSE_FAIL : "ON_REGISTER_RESPONSE_FAIL",

	ON_LOGIN_FROM_SESSION_REQUEST: "ON_LOGIN_FROM_SESSION_REQUEST",
	ON_LOGIN_FROM_SESSION_RESPONSE_SUCCESS: "ON_LOGIN_FROM_SESSION_RESPONSE_SUCCESS",
	ON_LOGIN_FROM_SESSION_RESPONSE_FAIL: "ON_LOGIN_FROM_SESSION_RESPONSE_FAIL",

	ON_GET_ALL_EVENTS_REQUEST: "ON_GET_ALL_EVENT_REQUEST",
	ON_GET_ALL_EVENTS_RESPONSE_SUCCESS: "ON_GET_ALL_EVENT_RESPONSE_SUCCESS",
	ON_GET_ALL_EVENTS_RESPONSE_FAIL: "ON_GET_ALL_EVENT_RESPONSE_FAIL",

	ON_GET_EVENT_BY_ID_REQUEST: "ON_GET_EVENT_BY_ID_REQUEST",
	ON_GET_EVENT_BY_ID_RESPONSE_SUCCESS: "ON_GET_EVENT_BY_ID_RESPONSE_SUCCESS",
	ON_GET_EVENT_BY_ID_RESPONSE_FAIL: "ON_GET_EVENT_BY_ID_RESPONSE_FAIL",

    ON_UPDATE_EVENT_IMAGE_BANNER_REQUEST: "ON_UPDATE_EVENT_IMAGE_BANNER_REQUEST",
    ON_UPDATE_EVENT_IMAGE_BANNER_RESPONSE_SUCCESS: "ON_UPDATE_EVENT_IMAGE_BANNER_RESPONSE_SUCCESS",
    ON_UPDATE_EVENT_IMAGE_BANNER_RESPONSE_FAIL: "ON_UPDATE_EVENT_IMAGE_BANNER_RESPONSE_FAIL",

    ON_UPDATE_EVENT_IMAGE_REQUEST: "ON_UPDATE_EVENT_IMAGE_REQUEST",
    ON_UPDATE_EVENT_IMAGE_RESPONSE_SUCCESS: "ON_UPDATE_EVENT_IMAGE_RESPONSE_SUCCESS",
    ON_UPDATE_EVENT_IMAGE_RESPONSE_FAIL: "ON_UPDATE_EVENT_IMAGE_RESPONSE_FAIL",

	ON_UPDATE_EVENT_IMAGE_FROM_CROP_REQUEST: "ON_UPDATE_EVENT_IMAGE_FROM_CROP_REQUEST",
	ON_UPDATE_EVENT_IMAGE_FROM_CROP_RESPONSE_SUCCESS: "ON_UPDATE_EVENT_IMAGE_FROM_CROP_RESPONSE_SUCCESS",
	ON_UPDATE_EVENT_IMAGE_FROM_CROP_RESPONSE_FAIL: "ON_UPDATE_EVENT_IMAGE_FROM_CROP_RESPONSE_FAIL",

	ON_SAVE_EVENT_REQUEST: "ON_SAVE_EVENT_REQUEST",
	ON_SAVE_EVENT_RESPONSE_SUCCESS: "ON_SAVE_EVENT_RESPONSE_SUCCESS",
	ON_SAVE_EVENT_RESPONSE_FAIL: "ON_SAVE_EVENT_RESPONSE_FAIL",

	ON_CREATE_EVENT_RESPONSE_SUCCESS : "ON_CREATE_EVENT_RESPONSE_SUCCESS",

	ON_GET_BOOTHS_REQUEST: "ON_GET_BOOTHS_REQUEST",
	ON_GET_BOOTHS_RESPONSE_SUCCESS: "ON_GET_BOOTHS_RESPONSE_SUCCESS",
	ON_GET_BOOTHS_RESPONSE_FAIL: "ON_GET_BOOTHS_RESPONSE_FAIL",

    ON_GET_BOOKS_REQUEST: "ON_GET_BOOKS_REQUEST",
    ON_GET_BOOKS_RESPONSE_SUCCESS: "ON_GET_BOOKS_RESPONSE_SUCCESS",
    ON_GET_BOOKS_RESPONSE_FAIL: "ON_GET_BOOKS_RESPONSE_FAIL",

	ON_SAVE_CURRENT_BOOTH_REQUEST: "ON_SAVE_CURRENT_BOOTH_REQUEST",
	ON_SAVE_CURRENT_BOOTH_RESPONSE_SUCCESS: "ON_SAVE_CURRENT_BOOTH_RESPONSE_SUCCESS",
	ON_SAVE_CURRENT_BOOTH_RESPONSE_FAIL: "ON_SAVE_CURRENT_BOOTH_RESPONSE_FAIL",

    ON_SAVE_CURRENT_BOOK_REQUEST: "ON_SAVE_CURRENT_BOOK_REQUEST",
    ON_SAVE_CURRENT_BOOK_RESPONSE_SUCCESS: "ON_SAVE_CURRENT_BOOK_RESPONSE_SUCCESS",
    ON_SAVE_CURRENT_BOOK_RESPONSE_FAIL: "ON_SAVE_CURRENT_BOOK_RESPONSE_FAIL",

    ON_DELETE_BOOTH_BY_ID_REQUEST: "ON_DELETE_BOOTH_BY_ID_REQUEST",
	ON_DELETE_BOOTH_BY_ID_RESPONSE_SUCCESS: "ON_DELETE_BOOTH_BY_ID_RESPONSE_SUCCESS",
	ON_DELETE_BOOTH_BY_ID_RESPONSE_FAIL: "ON_DELETE_BOOTH_BY_ID_RESPONSE_FAIL",

    ON_DELETE_BOOK_BY_ID_REQUEST: "ON_DELETE_BOOK_BY_ID_REQUEST",
    ON_DELETE_BOOK_BY_ID_RESPONSE_SUCCESS: "ON_DELETE_BOOK_BY_ID_RESPONSE_SUCCESS",
    ON_DELETE_BOOK_BY_ID_RESPONSE_FAIL: "ON_DELETE_BOOK_BY_ID_RESPONSE_FAIL",

    ON_GET_SESSIONS_REQUEST: "ON_GET_SESSIONS_REQUEST",
	ON_GET_SESSIONS_RESPONSE_SUCCESS: "ON_GET_SESSIONS_RESPONSE_SUCCESS",
	ON_GET_SESSIONS_RESPONSE_FAIL: "ON_GET_SESSIONS_RESPONSE_FAIL",

	ON_GET_SESSIONS_ARCHIVE_REQUEST: "ON_GET_SESSIONS_ARCHIVE_REQUEST",
	ON_GET_SESSIONS_ARCHIVE_RESPONSE_SUCCESS: "ON_GET_SESSIONS_ARCHIVE_RESPONSE_SUCCESS",
	ON_GET_SESSIONS_ARCHIVE_RESPONSE_FAIL: "ON_GET_SESSIONS_ARCHIVE_RESPONSE_FAIL",

    ON_GET_SESSIONS_REGISTRATIONS_REQUEST: "ON_GET_SESSIONS_REGISTRATIONS_REQUEST",
    ON_GET_SESSIONS_REGISTRATIONS_RESPONSE_SUCCESS: "ON_GET_SESSIONS_REGISTRATIONS_RESPONSE_SUCCESS",
    ON_GET_SESSIONS_REGISTRATIONS_RESPONSE_FAIL: "ON_GET_SESSIONS_REGISTRATIONS_RESPONSE_FAIL",

	ON_SAVE_CURRENT_SESSION_REQUEST: "ON_SAVE_CURRENT_SESSION_REQUEST",
	ON_SAVE_CURRENT_SESSION_RESPONSE_SUCCESS: "ON_SAVE_CURRENT_SESSION_RESPONSE_SUCCESS",
	ON_SAVE_CURRENT_SESSION_RESPONSE_FAIL: "ON_SAVE_CURRENT_SESSION_RESPONSE_FAIL",

    ON_UPDATE_SESSION_IMAGE_REQUEST: "ON_UPDATE_SESSION_IMAGE_REQUEST",
    ON_UPDATE_SESSION_IMAGE_RESPONSE_SUCCESS: "ON_UPDATE_SESSION_IMAGE_RESPONSE_SUCCESS",
    ON_UPDATE_SESSION_IMAGE_RESPONSE_FAIL: "ON_UPDATE_SESSION_IMAGE_RESPONSE_FAIL",

	ON_UPDATE_NEWS_IMAGE_REQUEST: "ON_UPDATE_NEWS_IMAGE_REQUEST",
	ON_UPDATE_NEWS_IMAGE_RESPONSE_SUCCESS: "ON_UPDATE_NEWS_IMAGE_RESPONSE_SUCCESS",
	ON_UPDATE_NEWS_IMAGE_RESPONSE_FAIL: "ON_UPDATE_NEWS_IMAGE_RESPONSE_FAIL",

	ON_UPDATE_ITEM_IMAGE_REQUEST: "ON_UPDATE_ITEM_IMAGE_REQUEST",
	ON_UPDATE_ITEM_IMAGE_RESPONSE_SUCCESS: "ON_UPDATE_ITEM_IMAGE_RESPONSE_SUCCESS",
	ON_UPDATE_ITEM_IMAGE_RESPONSE_FAIL: "ON_UPDATE_ITEM_IMAGE_RESPONSE_FAIL",

	ON_DELETE_SESSION_BY_ID_REQUEST: "ON_DELETE_SESSION_BY_ID_REQUEST",
	ON_DELETE_SESSION_BY_ID_RESPONSE_SUCCESS: "ON_DELETE_SESSION_BY_ID_RESPONSE_SUCCESS",
	ON_DELETE_SESSION_BY_ID_RESPONSE_FAIL: "ON_DELETE_SESSION_BY_ID_RESPONSE_FAIL",

	ON_GET_QR_CODE_BY_BOOTH_ID_REQUEST: "ON_GET_QR_CODE_BY_BOOTH_ID_REQUEST",
	ON_GET_QR_CODE_BY_BOOTH_ID_RESPONSE_SUCCESS: "ON_GET_QR_CODE_BY_BOOTH_ID_RESPONSE_SUCCESS",
	ON_GET_QR_CODE_BY_BOOTH_ID_RESPONSE_FAIL: "ON_GET_QR_CODE_BY_BOOTH_ID_RESPONSE_FAIL",

	ON_GET_QR_CODE_BY_SESSION_ID_REQUEST: "ON_GET_QR_CODE_BY_SESSION_ID_REQUEST",
	ON_GET_QR_CODE_BY_SESSION_ID_RESPONSE_SUCCESS: "ON_GET_QR_CODE_BY_SESSION_ID_RESPONSE_SUCCESS",
	ON_GET_QR_CODE_BY_SESSION_ID_RESPONSE_FAIL: "ON_GET_QR_CODE_BY_SESSION_ID_RESPONSE_FAIL",

	ON_GET_SURVEYS_REQUEST: "ON_GET_SURVEYS_REQUEST",
	ON_GET_SURVEYS_RESPONSE_SUCCESS: "ON_GET_SURVEYS_RESPONSE_SUCCESS",
	ON_GET_SURVEYS_RESPONSE_FAIL: "ON_GET_SURVEYS_RESPONSE_FAIL",

	ON_SAVE_CURRENT_SURVEY_REQUEST: "ON_SAVE_CURRENT_SURVEY_REQUEST",
	ON_SAVE_CURRENT_SURVEY_RESPONSE_SUCCESS: "ON_SAVE_CURRENT_SURVEY_RESPONSE_SUCCESS",
	ON_SAVE_CURRENT_SURVEY_RESPONSE_FAIL: "ON_SAVE_CURRENT_SURVEY_RESPONSE_FAIL",

	ON_DELETE_SURVEY_BY_ID_REQUEST: "ON_DELETE_SURVEY_BY_ID_REQUEST",
	ON_DELETE_SURVEY_BY_ID_RESPONSE_SUCCESS: "ON_DELETE_SURVEY_BY_ID_RESPONSE_SUCCESS",
	ON_DELETE_SURVEY_BY_ID_RESPONSE_FAIL: "ON_DELETE_SURVEY_BY_ID_RESPONSE_FAIL",

	ON_GET_SURVEY_RESULTS_REQUEST: "ON_GET_SURVEY_RESULTS_REQUEST",
	ON_GET_SURVEY_RESULTS_RESPONSE_SUCCESS: "ON_GET_SURVEY_RESULTS_RESPONSE_SUCCESS",
	ON_GET_SURVEY_RESULTS_RESPONSE_FAIL: "ON_GET_SURVEY_RESULTS_RESPONSE_FAIL",

	ON_GET_EVENT_ACTIVITIES_REQUEST: "ON_GET_EVENT_ACTIVITIES_REQUEST",
	ON_GET_EVENT_ACTIVITIES_RESPONSE_SUCCESS: "ON_GET_EVENT_ACTIVITIES_RESPONSE_SUCCESS",
	ON_GET_EVENT_ACTIVITIES_RESPONSE_FAIL: "ON_GET_EVENT_ACTIVITIES_RESPONSE_FAIL",

	ON_GET_EVENT_CHECKINS_REQUEST: "ON_GET_EVENT_CHECKINS_REQUEST",
	ON_GET_EVENT_CHECKINS_RESPONSE_SUCCESS: "ON_GET_EVENT_CHECKINS_RESPONSE_SUCCESS",
	ON_GET_EVENT_CHECKINS_RESPONSE_FAIL: "ON_GET_EVENT_CHECKINS_RESPONSE_FAIL",

	ON_GET_EVENT_QR_SCAN_HISTORY_REQUEST: "ON_GET_EVENT_QR_SCAN_HISTORY_REQUEST",
	ON_GET_EVENT_QR_SCAN_HISTORY_RESPONSE_SUCCESS: "ON_GET_EVENT_QR_SCAN_HISTORY_RESPONSE_SUCCESS",
	ON_GET_EVENT_QR_SCAN_HISTORY_RESPONSE_FAIL: "ON_GET_EVENT_QR_SCAN_HISTORY_RESPONSE_FAIL",

    ON_GET_ANNOUNCEMENT_REQUEST: "ON_GET_ANNOUNCEMENT_REQUEST",
    ON_GET_ANNOUNCEMENT_RESPONSE_SUCCESS: "ON_GET_ANNOUNCEMENT_RESPONSE_SUCCESS",
    ON_GET_ANNOUNCEMENT_RESPONSE_FAIL: "ON_GET_ANNOUNCEMENT_RESPONSE_FAIL",

    ON_GET_NEWS_REQUEST: "ON_GET_NEWS_REQUEST",
    ON_GET_NEWS_RESPONSE_SUCCESS: "ON_GET_NEWS_RESPONSE_SUCCESS",
    ON_GET_NEWS_RESPONSE_FAIL: "ON_GET_NEWS_RESPONSE_FAIL",
	ON_TOGGLE_NEWS_SHOWN_REQUEST: "ON_TOGGLE_NEWS_SHOWN_REQUEST",

    OPEN_VIEW_QR_DIALOG: "OPEN_VIEW_QR_DIALOG",
	CLOSE_VIEW_QR_DIALOG: "CLOSE_VIEW_QR_DIALOG",

	OPEN_VIEW_EVENT_DIALOG: "OPEN_VIEW_EVENT_DIALOG",
	CLOSE_VIEW_EVENT_DIALOG: "CLOSE_VIEW_EVENT_DIALOG",
	SAVE_CURRENT_EVENT: "SAVE_CURRENT_EVENT",
	EDIT_CURRENT_EVENT_VALUE: "EDIT_CURRENT_EVENT_VALUE",
	SET_CURRENT_EVENT: "SET_CURRENT_EVENT",


	OPEN_VIEW_BOOTH_DIALOG: "OPEN_VIEW_BOOTH_DIALOG",
	CLOSE_VIEW_BOOTH_DIALOG: "CLOSE_VIEW_BOOTH_DIALOG",
	EDIT_CURRENT_BOOTH_VALUE: "EDIT_CURRENT_BOOTH_VALUE",

    OPEN_VIEW_BOOK_DIALOG: "OPEN_VIEW_BOOK_DIALOG",
    CLOSE_VIEW_BOOK_DIALOG: "CLOSE_VIEW_BOOK_DIALOG",
    EDIT_CURRENT_BOOK_VALUE: "EDIT_CURRENT_BOOK_VALUE",

	OPEN_VIEW_SESSION_DIALOG: "OPEN_VIEW_SESSION_DIALOG",
	CLOSE_VIEW_SESSION_DIALOG: "CLOSE_VIEW_SESSION_DIALOG",
	SAVE_CURRENT_SESSION: "SAVE_CURRENT_SESSION",
	EDIT_CURRENT_SESSION_VALUE: "EDIT_CURRENT_SESSION_VALUE",

	OPEN_VIEW_SURVEY_DIALOG: "OPEN_VIEW_SURVEY_DIALOG",
	CLOSE_VIEW_SURVEY_DIALOG: "CLOSE_VIEW_SURVEY_DIALOG",
	SAVE_CURRENT_SURVEY: "SAVE_CURRENT_SURVEY",
	EDIT_CURRENT_SURVEY_VALUE: "EDIT_CURRENT_SURVEY_VALUE",
	OPEN_VIEW_QUESTION_DIALOG: "OPEN_VIEW_QUESTION_DIALOG",
	CLOSE_VIEW_QUESTION_DIALOG: "CLOSE_VIEW_QUESTION_DIALOG",
	SAVE_CURRENT_QUESTION: "SAVE_CURRENT_QUESTION",
	EDIT_CURRENT_QUESTION_VALUE: "EDIT_CURRENT_QUESTION_VALUE",
	OPEN_VIEW_SURVEY_RESULT_DIALOG: "OPEN_VIEW_SURVEY_RESULT_DIALOG",
	CLOSE_VIEW_SURVEY_RESULT_DIALOG: "CLOSE_VIEW_SURVEY_RESULT_DIALOG",

	RETURN_TO_EVENT_MANAGER_PAGE: "RETURN_TO_EVENT_MANAGER_PAGE",
	ENQUEUE_SNACKBAR: "ENQUEUE_SNACKBAR",

    OPEN_VIEW_ANNOUNCEMENT_DIALOG: "OPEN_VIEW_ANNOUNCEMENT_DIALOG",
    CLOSE_VIEW_ANNOUNCEMENT_DIALOG: "CLOSE_VIEW_ANNOUNCEMENT_DIALOG",

    OPEN_VIEW_NEWS_DIALOG: "OPEN_VIEW_NEWS_DIALOG",
    CLOSE_VIEW_NEWS_DIALOG: "CLOSE_VIEW_NEWS_DIALOG",

    OPEN_VIEW_SESSION_REGISTRATION_DIALOG: "OPEN_VIEW_SESSION_REGISTRATION_DIALOG",
    CLOSE_VIEW_SESSION_REGISTRATION_DIALOG: "CLOSE_VIEW_SESSION_REGISTRATION_DIALOG",

	OPEN_VIEW_SESSION_REGISTRANT_PROOF_DIALOG: "OPEN_VIEW_SESSION_REGISTRANT_PROOF_DIALOG",
	CLOSE_VIEW_SESSION_REGISTRANT_PROOF_DIALOG: "CLOSE_VIEW_SESSION_REGISTRANT_PROOF_DIALOG",

	ON_SAVE_ANNOUNCEMENT_REQUEST: "ON_SAVE_ANNOUNCEMENT_REQUEST",
	ON_SAVE_ANNOUNCEMENT_RESPONSE_SUCCESS: "ON_SAVE_ANNOUNCEMENT_RESPONSE_SUCCESS",
	ON_SAVE_ANNOUNCEMENT_RESPONSE_FAIL: "ON_SAVE_ANNOUNCEMENT_RESPONSE_FAIL",

    ON_SAVE_NEWS_REQUEST: "ON_SAVE_NEWS_REQUEST",
    ON_SAVE_NEWS_RESPONSE_SUCCESS: "ON_SAVE_NEWS_RESPONSE_SUCCESS",
    ON_SAVE_NEWS_RESPONSE_FAIL: "ON_SAVE_NEWS_RESPONSE_FAIL",

	ON_REGISTRATION_CHANGE_PAYMENT_STATUS_REQUEST:"ON_REGISTRATION_CHANGE_PAYMENT_STATUS_REQUEST",
	ON_REGISTRATION_CHANGE_PAYMENT_STATUS_RESPONSE_SUCCESS:"ON_REGISTRATION_CHANGE_PAYMENT_STATUS_RESPONSE_SUCCESS",
	ON_REGISTRATION_CHANGE_PAYMENT_STATUS_RESPONSE_FAIL:"ON_REGISTRATION_CHANGE_PAYMENT_STATUS_RESPONSE_FAIL",

	ON_TOGGLE_ANNOUNCEMENT_SHOWN_REQUEST: "ON_TOGGLE_ANNOUNCEMENT_SHOWN_REQUEST",

    EDIT_CURRENT_ANNOUNCEMENT_VALUE: "EDIT_CURRENT_ANNOUNCEMENT_VALUE",

	EDIT_CURRENT_NEWS_VALUE: "EDIT_CURRENT_NEWS_VALUE",

	LOG_OUT: "LOG_OUT"
}
