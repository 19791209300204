import React from "react";
import {connect} from "react-redux";
import {compose} from "recompose";
import {push} from "connected-react-router";

import {withStyles} from "@material-ui/core/styles";
import {
    Dialog,
    TextField,
    Slide,
    Grid,
    Button,
    Stepper,
    StepLabel,
    Step
} from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import {DialogTitle, DialogContent, DialogActions} from "../reusable/Dialog"
// import dialogStyles from "../../styles/dialog"
import {eventTypes, pricingPlan} from "./config/eventDataDisplayConfig"

import Dispatch from "../../constant/dispatch";

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    menu: {
        width: 200,
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ViewEventDialog extends React.Component {

    STEPS = [0,1]

    constructor(props) {
        super(props)
        this.state = {
            name: "",
            step: 0
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.isOpen && this.props.isOpen) {
            this.setState({name: this.props.currentEvent.name})
        }
    }

    onClose() {
    	this.setState({
			step : 0
		})
        this.props.closeDialog()
    }

    increaseStep(){
        if(this.state.step + 1 < this.STEPS.length){
            this.setState({
                step : this.state.step + 1
            })
        }else{
            this.props.saveCurrentEvent()
        }
	}

	decreaseStep(){
        if(this.state.step > 0){
            this.setState({
                step : this.state.step -1
            })
        }else{
            this.onClose()
        }
	}


    renderModalFooter(){

        const {classes, isOpen, currentEvent} = this.props;

        return  <DialogActions>
            <div style={{
                position:"absolute",
                left : 16,
                bottom : 0,
                display : "flex",
                flexDirection : "row"
            }}>
                Progress :
                <Stepper alternativeLabel activeStep={this.state.step} style={{
                    display :"flex",
                    flexDirection:"row",
                    justifyContent:"center",
                    alignItems:'center',
                    padding : 0
                }}>
                    {this.STEPS.map(label => (
                        <Step key={label}>
                            <StepLabel/>
                        </Step>
                    ))}
                </Stepper>
            </div>

            {/*<div style={{*/}
            {/*    position : "absolute",*/}
            {/*    left : 16*/}
            {/*}}>*/}
            {/*    Step {this.state.step} of 2*/}
            {/*</div>*/}
            <Button
                color="primary"
                onClick={() => this.decreaseStep()}
                className={classes.button}>
                {this.state.step === 0 ? "Cancel" : "Back"}
            </Button>
            <Button
                color="primary"
                onClick={() => this.increaseStep()}
                className={classes.button}>
                {this.state.step < this.STEPS.length-1? "Next" : "Submit"}
            </Button>
        </DialogActions>
    }

    renderForm1() {
		const {classes, isOpen, currentEvent} = this.props;

		return <>
            <DialogTitle onClose={() => this.onClose()}>
                We need some basic data for your event !
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={4}>
                    <Grid item xs={11}>
                        <TextField
                            label="Event Name"
                            className={classes.textField}
                            value={this.state.name}
                            onChange={evt => this.setState({name: evt.target.value})}
                            onBlur={evt => this.props.editCurrentEvent("name", evt.target.value)}
                            placeholder=""
							fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}/>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={6}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                format="DD/MM/YYYY"
                                margin="normal"
                                value={currentEvent.start_time}
                                variant={"outlined"}
                                onChange={date => this.props.editCurrentEvent("start_time", date)}
                                className={classes.textField}
                                label="Event Start Date"
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                format="DD/MM/YYYY"
                                margin="normal"
                                variant={"outlined"}
                                value={currentEvent.end_time}
                                onChange={date => this.props.editCurrentEvent("end_time", date)}
                                className={classes.textField}
                                label="Event End Date"
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={11}>
                        <TextField
                            label="Event Location"
                            className={classes.textField}
                            value={this.state.venue}
                            onChange={evt => this.setState({venue: evt.target.value})}
                            onBlur={evt => this.props.editCurrentEvent("venue", evt.target.value)}
                            placeholder=""
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}/>
                    </Grid>
                </Grid>
            </DialogContent>
            {this.renderModalFooter()}
        </>
    }

    renderForm2(){

		const {classes, isOpen, currentEvent} = this.props;

		return <>
			<DialogTitle onClose={() => this.onClose()}>
				Tell us more about your Event !
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={4}>
					<Grid item xs={11}>
						<TextField
							label="Event Description"
							className={classes.textField}
							value={this.state.description}
							multiline
							rows={8}
							onChange={evt => this.setState({description: evt.target.value})}
							onBlur={evt => this.props.editCurrentEvent("description", evt.target.value)}
							placeholder=""
							fullWidth
							variant={"outlined"}
							InputLabelProps={{
								shrink: true,
							}}/>
					</Grid>
				</Grid>
			</DialogContent>
            {this.renderModalFooter()}
        </>
	}

	renderFormBody(step) {
		switch(step) {
			case 0 :
				return this.renderForm1()
			case 1 :
				return this.renderForm2()
			default :
				return null
		}
	}

    render() {
        const {classes, isOpen, currentEvent} = this.props;

        return (
            <Dialog
                open={isOpen}
                maxWidth="sm"
                fullWidth={true}
                TransitionComponent={Transition}>
                {
					this.renderFormBody(this.state.step)
                }
            </Dialog>
        );
    }
}

const mapStateToProps = function (storage) {
    return {
        isOpen: storage.appState.isViewEventDialogOpen,
        currentEvent: storage.eventManager.currentEvent
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUrl: url => dispatch(push(url)),
        editCurrentEvent: (key, value) => dispatch({
            type: Dispatch.EDIT_CURRENT_EVENT_VALUE,
            payload: {
                key: key,
                value: value
            }
        }),
        saveCurrentEvent: () => dispatch({
            type: Dispatch.SAVE_CURRENT_EVENT
        }),
        closeDialog: () => dispatch({
            type: Dispatch.CLOSE_VIEW_EVENT_DIALOG
        })
    }
}

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles),
)(ViewEventDialog);
