import React, { Component } from 'react'
import { connect } from "react-redux"
import { compose } from "recompose"

import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack'

import { SnackBarVariant, generateSnackBar } from "./reusable/SnackBar"

const styles = theme => ({
});

class SnackBarManager extends Component {
	
	componentDidUpdate(prevProps) {
		if(this.props.snackBar.id !== prevProps.snackBar.id) {
			generateSnackBar(this.props, this.props.snackBar.message, this.props.snackBar.type, this.props.snackBar.options)
		}
	}
	
	render() {
		return <div />
	}
}

const mapStateToProps = function(storage) {
	return {
		snackBar: storage.appState.snackBar
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
	}
}

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(styles),
	withSnackbar
)(SnackBarManager)
