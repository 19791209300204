import BoothAction from "../model/BoothAction"
import Booth from "../model/Booth"
import Dispatch from "../constant/dispatch"

const defaultState = {
	currentBooth: new Booth(),
	booths: [],

	isBoothDataLoaded: false
}

function handleLocalChanges(state, action) {
	if(action.type === Dispatch.OPEN_VIEW_BOOTH_DIALOG) {
		return {
			...state,
			currentBooth: typeof action.payload === "object" ?
				action.payload :
				state.booths.find(booth => booth.id === action.payload)
		}
	} else if(action.type === Dispatch.CLOSE_VIEW_BOOTH_DIALOG) {
		return {
			...state,
			currentBooth: defaultState.currentBooth
		}
	} else if(action.type === Dispatch.EDIT_CURRENT_BOOTH_VALUE) {
		return {
			...state,
			currentBooth: {
				...state.currentBooth,
				[action.payload.key]: action.payload.value
			}
		}
	} else if(action.type === Dispatch.RETURN_TO_EVENT_MANAGER_PAGE) {
		return defaultState
	}
	return state
}

export default function reducer(state = defaultState, action) {
	if(action.type === Dispatch.ON_GET_BOOTHS_REQUEST) {
		BoothAction.getBooths(action.payload)
	} else if(action.type === Dispatch.ON_SAVE_CURRENT_BOOTH_REQUEST) {
		let booth = {...state.currentBooth}
		booth.role_id = action.payload
		booth.id === -1 ? BoothAction.createBooth(booth) : BoothAction.updateBooth(booth)
	} else if(action.type === Dispatch.ON_DELETE_BOOTH_BY_ID_REQUEST) {
		BoothAction.deleteBooth({...action.payload})
	} else if(action.type === Dispatch.ON_GET_BOOTHS_RESPONSE_SUCCESS) {
		return {
			...state,
			booths: action.payload,
			isBoothDataLoaded: true
		}
	} else if(action.type === Dispatch.ON_SAVE_CURRENT_BOOTH_RESPONSE_SUCCESS) {
		return {
			...state,
			booths: state.booths.find(b => b.id === action.payload.id) ?
				state.booths.map(booth => booth.id === action.payload.id ? action.payload : booth) :
				state.booths.concat(action.payload)
		}
	} else if(action.type === Dispatch.ON_DELETE_BOOTH_BY_ID_RESPONSE_SUCCESS) {
		return {
			...state,
			booths: state.booths.filter(booth => booth.id !== action.payload.id)
		}
	} else if(action.payload === Dispatch.ON_GET_QR_CODE_BY_BOOTH_ID_RESPONSE_SUCCESS) {
		return {
			...state,
			booths: state.booths.map(booth => booth.id === action.payload.id ? action.payload : booth)
		}

	} else if(action.type === Dispatch.LOG_OUT) {
		return defaultState
	}
	return handleLocalChanges(state, action)
}
