import AnnouncementAction from "../model/AnnouncementAction"
import Dispatch from "../constant/dispatch"
import Announcement from "../model/Announcement";
import EventAction from "../model/EventAction";

const defaultState = {
	currentAnnouncement: new Announcement(),
	announcements: [],
	isAnnouncementDataLoaded: false
}

function handleLocalChanges(state, action) {
	if(action.type === Dispatch.OPEN_VIEW_ANNOUNCEMENT_DIALOG) {
		return {
			...state,
            currentAnnouncement: typeof action.payload === "object" ?
				action.payload :
				state.booths.find(announcement => announcement.id === action.payload)
		}
	} else if(action.type === Dispatch.CLOSE_VIEW_ANNOUNCEMENT_DIALOG) {
		return {
			...state,
            currentAnnouncement: defaultState.currentAnnouncement
		}
	} else if(action.type === Dispatch.RETURN_TO_EVENT_MANAGER_PAGE) {
		return defaultState
	} else if(action.type === Dispatch.EDIT_CURRENT_ANNOUNCEMENT_VALUE) {
        return {
            ...state,
            currentAnnouncement: {
                ...state.currentAnnouncement,
                [action.payload.key]: action.payload.value
            }
        }
    }
	return state
}

export default function reducer(state = defaultState, action) {
	if(action.type === Dispatch.ON_GET_ANNOUNCEMENT_REQUEST) {
		AnnouncementAction.getAnnouncements(action.payload)
	} else if(action.type === Dispatch.ON_GET_ANNOUNCEMENT_RESPONSE_SUCCESS){
		return {
			...state,
			announcements: action.payload,
			isAnnouncementDataLoaded: true
		}
    } else if(action.type === Dispatch.ON_SAVE_ANNOUNCEMENT_RESPONSE_SUCCESS) {
        AnnouncementAction.getAnnouncements(action.payload)
    } else if (action.type === Dispatch.ON_SAVE_ANNOUNCEMENT_REQUEST) {
		let announcement = {...state.currentAnnouncement}
		let eventId = action.payload
		announcement.event_id = eventId
		console.log("Hmmmmmm...", announcement)
		announcement.id === -1 ? AnnouncementAction.createAnnouncement(announcement) : AnnouncementAction.updateAnnouncement(announcement)
	} else if (action.type === Dispatch.ON_SAVE_ANNOUNCEMENT_RESPONSE_FAIL) {

	} else if (action.type === Dispatch.ON_TOGGLE_ANNOUNCEMENT_SHOWN_REQUEST) {
		let announcementId = action.payload
		let announcements = [...state.announcements]
		console.log(announcements)
		let selectedAnnouncement = announcements.find(announcement =>{ 
			return announcement.id === announcementId
		})
		selectedAnnouncement.shown = !selectedAnnouncement.shown
		AnnouncementAction.updateAnnouncement(selectedAnnouncement)
	}
	return handleLocalChanges(state, action)
}
