import axios from "axios"
import { push } from "connected-react-router"

import storage from "../storage/storage"
import Dispatch from "../constant/dispatch"
import server from "../constant/server"
import UserAuth from "../util/UserAuth"
import { SnackBarVariant } from "../component/reusable/SnackBar"

export default {
	getQrByBooth: booth => {
		axios.get(server + "/qr/booth/" + booth.id)
			.then(response => {
				console.log("booth qr data", response)
				storage.dispatch({
					type: Dispatch.ON_GET_QR_CODE_BY_BOOTH_ID_RESPONSE_SUCCESS,
					payload: {
                        ...booth,
                        qr_codes: response.data
                    }
				})
			})
			.catch(err => {
				console.log(err.response)
				storage.dispatch({type: Dispatch.ON_GET_QR_CODE_BY_BOOTH_ID_RESPONSE_FAIL})
			})
	},
	
	getQrBySession: session => {
		axios.get(server + "/qr/session/" + session.id)
			.then(response => {
				console.log("session qr data", response)
				storage.dispatch({
					type: Dispatch.ON_GET_QR_CODE_BY_SESSION_ID_RESPONSE_SUCCESS,
					payload: {
						...session,
						qr_codes: response.data
					}
				})
			}).catch(err => {
				console.log(err.response)
				storage.dispatch({type: Dispatch.ON_GET_QR_CODE_BY_SESSION_ID_RESPONSE_FAIL})
			})
	},
}