import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { push } from "connected-react-router";

import { withStyles } from "@material-ui/core/styles";
import {
    Dialog,
    TextField,
    Typography,
    Slide,
    Grid,
    Divider,
    MenuItem,
    Button,
    List,
    ListItem,
    Card,
    CardHeader,
    CardContent,
    CardActions,
} from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardTimePicker
} from '@material-ui/pickers';
import {
    Add,
    KeyboardArrowRight
} from '@material-ui/icons';
import moment from "moment";
import MomentUtils from '@date-io/moment';

import { DialogTitle, DialogContent, DialogActions } from "../../reusable/Dialog"

import Question from "../../../model/Question"
import Dispatch from "../../../constant/dispatch";
import { sessionTypes } from "./config/sessionDataDisplayConfig";
import {SnackBarVariant} from "../../reusable/SnackBar";
import SessionModel from "../../../model/Session";
import UploadImage from "../../reusable/UploadImage";
import columns from "./config/sessionRegistrationsDataDisplayConfig";
import DataTable from "../../reusable/DataTable";
import _ from "lodash";
import storage from "../../../storage/storage"

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    menu: {
        width: 200,
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ViewSessionRegistrantProofDialog extends React.Component {

    constructor(props) {
        super(props)
    }


    onClose() {
        this.props.closeDialog()
    }

    render() {
        const { classes, isOpen, currentRegistration, currentSession} = this.props;


        return (<>
                {
                    !currentRegistration.transaction_info &&
                        <Dialog
                            open={isOpen}
                            maxWidth="lg"
                            fullWidth={true}
                            TransitionComponent={Transition}>
                            <DialogTitle onClose={() => this.onClose()}>
                                Payment Proof for {currentSession.session_name}
                            </DialogTitle>
                            <DialogContent>
                                <Grid container spacing={4}>
                                    <Grid item xs={2}>
                                        Name:
                                    </Grid>
                                    <Grid item xs={4}>
                                        {currentRegistration.full_name}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={4}>
                                    <Grid item xs={2}>
                                        Email:
                                    </Grid>
                                    <Grid item xs={4}>
                                        {currentRegistration.email}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={4}>
                                    <Grid item xs={2}>
                                        Ticket Count:
                                    </Grid>
                                    <Grid item xs={4}>
                                        {currentRegistration.amount}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={4}>
                                    <Grid item xs={2}>
                                        Price to pay:
                                    </Grid>
                                    <Grid item xs={4}>
                                        {currentRegistration.total_price}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={4}>
                                    <Grid item xs={2}>
                                        Proof Image:
                                    </Grid>
                                    <Grid item xs={4}>
                                        <img src={currentRegistration.payment_proof_url}/>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                {
                                    (currentRegistration.status !== "ACCEPTED" && currentRegistration.status !== "REJECTED") &&
                                    <Button variant="outlined" color="primary"
                                            onClick={() => this.props.handleAcceptRegistration(currentRegistration)}>
                                        ACCEPT PAYMENT
                                    </Button>
                                }
                                <Button
                                    variant="text"
                                    color="primary"
                                    onClick={() => this.onClose()}
                                    className={classes.button}>
                                    CLOSE
                                </Button>
                            </DialogActions>
                        </Dialog>
                }
                {
                    !!currentRegistration.transaction_info &&
                    <Dialog
                        open={isOpen}
                        maxWidth="lg"
                        fullWidth={true}
                        TransitionComponent={Transition}>
                        <DialogTitle onClose={() => this.onClose()}>
                            Transaction info for {currentSession.session_name}
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={4}>
                                <Grid item xs={2}>
                                    Name:
                                </Grid>
                                <Grid item xs={4}>
                                    {currentRegistration.full_name}
                                </Grid>
                            </Grid>
                            <Grid container spacing={4}>
                                <Grid item xs={2}>
                                    Email:
                                </Grid>
                                <Grid item xs={4}>
                                    {currentRegistration.email}
                                </Grid>
                            </Grid>
                            <Grid container spacing={4}>
                                <Grid item xs={2}>
                                    Ticket Count:
                                </Grid>
                                <Grid item xs={4}>
                                    {currentRegistration.amount}
                                </Grid>
                            </Grid>
                            <Grid container spacing={4}>
                                <Grid item xs={2}>
                                    Price to pay:
                                </Grid>
                                <Grid item xs={4}>
                                    {currentRegistration.total_price}
                                </Grid>
                            </Grid>
                            {
                                Object.keys(currentRegistration.transaction_info).map(key =>
                                    <Grid container spacing={4}>
                                        <Grid item xs={2}>
                                            {key}:
                                        </Grid>
                                        <Grid item xs={4}>
                                            {currentRegistration.transaction_info[key]}
                                        </Grid>
                                    </Grid>
                                )
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="text"
                                color="primary"
                                onClick={() => this.onClose()}
                                className={classes.button}>
                                CLOSE
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
            </>

        );
    }
}

const mapStateToProps = function(storage) {
    return {
        isOpen: storage.appState.isViewSessionRegistrantProofDialogOpen,
        currentRegistration: storage.session.currentRegistration,
        currentSession: storage.session.currentSession,
        roleId: storage.eventManager.currentEvent.role_id
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeDialog: () => dispatch({
            type: Dispatch.CLOSE_VIEW_SESSION_REGISTRANT_PROOF_DIALOG
        }),
        handleAcceptRegistration: (registration) => {
            storage.dispatch({
                type: Dispatch.ON_REGISTRATION_CHANGE_PAYMENT_STATUS_REQUEST,
                payload: {
                    registrationInfo : registration,
                    status : "ACCEPTED"
                }
            })
            storage.dispatch({
                type: Dispatch.CLOSE_VIEW_SESSION_REGISTRANT_PROOF_DIALOG
            })
        }
    }
}

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles),
)(ViewSessionRegistrantProofDialog);
