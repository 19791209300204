import React, { Component } from 'react'
import { connect } from "react-redux"
import { compose } from "recompose"
import { push } from "connected-react-router";

import { withStyles } from '@material-ui/core/styles';
import {
	Grid,
	TextField,
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Button,
	Typography,
	Divider,
	List,
	ListItem,
	Menu,
	MenuItem,
	IconButton
} from "@material-ui/core";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
	KeyboardTimePicker
} from '@material-ui/pickers';
import {
	Clear
} from '@material-ui/icons';
import moment from "moment";
import MomentUtils from '@date-io/moment';
import { withSnackbar } from 'notistack'
import { SnackBarVariant, generateSnackBar } from "../../reusable/SnackBar"
import UploadImage from "../../reusable/UploadImage"
import RichText from "../../reusable/RichText/"

import EventModel from "../../../model/Event"

import { eventTypes, pricingPlan, targetAudience } from "../../eventManager/config/eventDataDisplayConfig"
import Dispatch from "../../../constant/dispatch";
import storage from "../../../storage/storage";

const styles = theme => ({
	root: {
		display: 'flex',
	},
	pricingPlanCard: {
		marginRight: "18px"
	},
	button: {
		marginRight: "10px"
	}
});

class Polling extends Component {

	constructor(props) {
		super(props)
		this.state = {
			...this.props.currentEvent,
			isError: false
		}

		this.props.getEventById(this.props.currentEvent.id)
	}

	handleSaveData() {
		if(EventModel.isDataValid(this.state)) {
			this.props.saveCurrentEvent(this.state)
		} else {
			this.setState({isError: true})
			this.props.enqueueSnackBar({
				message: "Data is not complete",
				type: SnackBarVariant.ERROR,
			})
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(JSON.stringify(prevProps.currentEvent) !== JSON.stringify(this.props.currentEvent)) {
			this.setState({
				...this.props.currentEvent
			})
		}
	}

	render() {
		const { classes } = this.props

		return <div className={classes.root}>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<Typography color="textPrimary" variant="h5">Halo</Typography>
					<Typography color="textSecondary" variant="body2">
						[insert explanation here]
						Lorem Ipsum is simply dummy text of the printing and typesetting industry.
						Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
						when an unknown printer took a galley of type and scrambled it to make a type
						specimen book. It has survived not only five centuries, but also the leap
						into electronic typesetting, remaining essentially unchanged. It was popularised
						in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
						and more recently with desktop publishing software like Aldus PageMaker including
						versions of Lorem Ipsum.
					</Typography>
				</Grid>
			</Grid>
		</div>
	}
}

const mapStateToProps = function(storage) {
	return {
		currentEvent: storage.eventManager.currentEvent
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setUrl: url => dispatch(push(url)),
		saveCurrentEvent: event => dispatch({
			type: Dispatch.ON_SAVE_EVENT_REQUEST,
			payload: event
		}),
		enqueueSnackBar: snackBar => dispatch({
			type: Dispatch.ENQUEUE_SNACKBAR,
			payload: snackBar
		}),
		getEventById: id => dispatch({
			type: Dispatch.ON_GET_EVENT_BY_ID_REQUEST,
			payload: id
		}),
	}
}

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(styles),
	withSnackbar
)(Polling)
