import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { push } from "connected-react-router";

import { withStyles } from "@material-ui/core/styles";
import {
    Dialog,
    TextField,
    Typography,
    Slide,
    Grid,
    Divider,
    MenuItem,
    Button,
    List,
    ListItem,
    Card,
    CardHeader,
    CardContent,
    CardActions,
} from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardTimePicker
} from '@material-ui/pickers';
import {
    Add,
    KeyboardArrowRight
} from '@material-ui/icons';
import moment from "moment";
import MomentUtils from '@date-io/moment';

import { DialogTitle, DialogContent, DialogActions } from "../../reusable/Dialog"

import Question from "../../../model/Question"
import Dispatch from "../../../constant/dispatch";
import { sessionTypes } from "./config/sessionDataDisplayConfig";
import {SnackBarVariant} from "../../reusable/SnackBar";
import SessionModel from "../../../model/Session";
import UploadImage from "../../reusable/UploadImage";
import columns from "./config/sessionRegistrationsDataDisplayConfig";
import DataTable from "../../reusable/DataTable";
import _ from "lodash";

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    menu: {
        width: 200,
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ViewSessionRegistrationsDialog extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            ...this.props.currentSession,
            isError: false,
            rejectMessageDialogOpen: false,
            rejectMessage: ""
        }
    }

    handleSaveData() {
        if(SessionModel.isDataValid(this.state)) {
            this.props.saveCurrentSession(this.props.roleId)
        } else {
            this.setState({isError: true})
            this.props.enqueueSnackBar({
                message: "Data is not complete",
                type: SnackBarVariant.ERROR,
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!prevProps.isOpen && this.props.isOpen) {
            this.setState({
                ...this.props.currentSession
            })

            if(this.props.isOpen && this.props.currentSession){
                this.props.getRegistrationsData(this.props.currentSession)
            }
        }
    }

    onClose() {
        this.props.closeDialog()
    }

    render() {
        const { classes, isOpen, currentSession } = this.props;

        let total = _.sumBy(currentSession.registrations, (obj)=> {
            return obj.status === "ACCEPTED" ? obj.amount : 0
        })

        return (<>
            <Dialog
                open={isOpen}
                maxWidth="lg"
                fullWidth={true}
                TransitionComponent={Transition}>
                <DialogTitle onClose={() => this.onClose()}>
                    Registrations
                </DialogTitle>
                <DialogContent>
                    <DataTable
                        title={currentSession.quota>0?`Quota (${total}/${currentSession.quota})`:`Total Registrasi : ${currentSession.registrations?currentSession.registrations.length:0}`}
                        filter={true}
                        viewColumns={true}
                        data={currentSession.registrations}
                        columns={columns}
                        createNew={() => this.props.openViewSessionDialog(new SessionModel())}
                        onRowsDelete={session => this.props.deleteSession(session)} />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="text"
                        color="primary"
                        onClick={() => this.onClose()}
                        className={classes.button}>
                        CLOSE
                    </Button>
                </DialogActions>
            </Dialog>
            </>
        );
    }
}

const mapStateToProps = function(storage) {
    return {
        isOpen: storage.appState.isViewSessionRegistrationsDialogOpen,
        currentSession: storage.session.currentSession,
        roleId: storage.eventManager.currentEvent.role_id
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getRegistrationsData: session => {
            dispatch({
                type: Dispatch.ON_GET_SESSIONS_REGISTRATIONS_REQUEST,
                payload: session
            })
        },
        closeDialog: () => dispatch({
            type: Dispatch.CLOSE_VIEW_SESSION_REGISTRATION_DIALOG
        }),
    }
}

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles),
)(ViewSessionRegistrationsDialog);
