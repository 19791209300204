import QRCodeAction from "../model/QRCodeAction"
import Session from "../model/Session"
import Dispatch from "../constant/dispatch"

const defaultState = {
	currentData: new Session(),
	flag: "Session"
}

export default function reducer(state = defaultState, action) {
	if(action.type === Dispatch.OPEN_VIEW_QR_DIALOG) {
		if(action.payload.qr_codes.length === 0) {
			console.log("Fetching qr by: " + action.flag, action.payload)
			action.flag === "Booth" ? QRCodeAction.getQrByBooth(action.payload) : QRCodeAction.getQrBySession(action.payload)
		} else {
			return {
				...state,
				currentData: action.payload,
			}
		}
	} else if(action.type === Dispatch.CLOSE_VIEW_BOOTH_DIALOG) {
		return {
			...state,
			currentData: defaultState.currentData
		}
	} else if(
		action.type === Dispatch.ON_GET_QR_CODE_BY_BOOTH_ID_RESPONSE_SUCCESS ||
		action.type === Dispatch.ON_GET_QR_CODE_BY_SESSION_ID_RESPONSE_SUCCESS
	) {
		return {
			...state,
			currentData: action.payload
		}
		
	} else if(action.type === Dispatch.LOG_OUT) {
		return defaultState
	}
	return state
}