import moment from "moment";

class News {
    constructor() {
        this.id = -1
        this.content = "hai"
		this.created_at = ""
		this.owner = ""
		this.shown = 0
		this.title = ""
    }

    static isDataValid(announcement) {
    	if(
            announcement.hasOwnProperty("content") && announcement.hasOwnProperty("title")
	    ) {
    		return announcement.content !== "" && announcement.title!== ""
	    }
    	return false
    }
}

export default News;
