import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { push } from "connected-react-router";

import { withStyles } from "@material-ui/core/styles";
import {
	Dialog,
	TextField,
	Typography,
	Slide,
	Grid,
	Divider,
	MenuItem,
	Button,
	List,
	ListItem,
	Card,
	CardHeader,
	CardContent,
	CardActions,
	ListItemSecondaryAction,
	IconButton,
	Tooltip
} from "@material-ui/core";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import {
	Add,
	KeyboardArrowRight,
	DeleteOutline
} from '@material-ui/icons';

import { DialogTitle, DialogContent, DialogActions } from "../../reusable/Dialog"

import Question from "../../../model/Question"
import Dispatch from "../../../constant/dispatch";
import {SnackBarVariant} from "../../reusable/SnackBar";
import SurveyModel from "../../../model/Survey";

const styles = theme => ({
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	menu: {
		width: 200,
	},
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

class ViewSurveyDialog extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			...this.props.currentSurvey,
			isError: false
		}
	}
	
	handleSaveData() {
		if(SurveyModel.isDataValid(this.props.currentSurvey) && this.props.currentSurvey.question_json.length !== 0) {
			this.props.saveCurrentSurvey(this.props.eventId)
		} else {
			this.setState({isError: true})
			this.props.enqueueSnackBar({
				message: "Data is not complete",
				type: SnackBarVariant.ERROR,
			})
		}
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if(!prevProps.isOpen && this.props.isOpen) {
			this.setState({
				...this.props.currentSurvey
			})
		}
	}
	
	onClose() {
		this.props.closeDialog()
	}
	
	render() {
		const { classes, isOpen, currentSurvey } = this.props;
		
		return (
			<Dialog
				open={isOpen}
				maxWidth="md"
				fullWidth={true}
				TransitionComponent={Transition}>
				<DialogTitle onClose={() => this.onClose()}>
					Survey Details
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={4}>
						<Grid item xs={7}>
							<TextField
								label="Survey Name"
								className={classes.textField}
								value={this.state.title}
								onChange={evt => this.setState({title: evt.target.value})}
								onBlur={evt => this.props.editCurrentSurvey("title", evt.target.value)}
								placeholder=""
								fullWidth
								margin="normal"
								error={this.state.isError && !this.state.title}
								InputLabelProps={{
									shrink: true,
								}} />
						</Grid>
						<Grid item xs={7}>
							<TextField
								label="Survey Owner"
								className={classes.textField}
								value={this.state.owner}
								onChange={evt => this.setState({owner: evt.target.value})}
								onBlur={evt => this.props.editCurrentSurvey("owner", evt.target.value)}
								placeholder=""
								fullWidth
								margin="normal"
								error={this.state.isError && !this.state.owner}
								InputLabelProps={{
									shrink: true,
								}} />
						</Grid>
						<Grid item xs={11}>
							<TextField
								id="introduction"
								label="Survey Introduction"
								multiline
								fullWidth
								rows="3"
								value={this.state.introduction}
								onChange={evt => this.setState({introduction: evt.target.value})}
								onBlur={evt => this.props.editCurrentSurvey("introduction", evt.target.value)}
								className={classes.textField}
								margin="normal"
								variant="outlined"
								error={this.state.isError && !this.state.introduction}
								InputLabelProps={{
									shrink: true,
								}} />
						</Grid>
					</Grid>
					<List className={classes.textField}>
						<Typography
							color={this.state.isError && !this.state.title ? "error" : "textSecondary"}
							variant="caption">
							Questions
						</Typography>
						{
							currentSurvey.question_json.map((question, index) => <div key={index}>
								<Divider/>
								<ListItem button onClick={() => this.props.openViewQuestionDialog(question)}>
									<KeyboardArrowRight/>
									<Typography color="textSecondary">
										{question.prompt}
									</Typography>
									<ListItemSecondaryAction>
										<Tooltip title="Delete">
											<IconButton onClick={() => this.props.editCurrentSurvey(
												"question_json",
												currentSurvey.question_json.filter((_, i) => index !== i)
											)}>
												<DeleteOutline/>
											</IconButton>
										</Tooltip>
									</ListItemSecondaryAction>
								</ListItem>
							</div>)
						}
						<Divider/>
						<ListItem button onClick={() => this.props.openViewQuestionDialog(new Question(currentSurvey.question_json.length))}>
							<Add/>
							<Typography align="center" color="textSecondary">Add a Question</Typography>
						</ListItem>
						<Divider/>
					</List>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						color="primary"
						onClick={() => this.handleSaveData()}
						className={classes.button}>
						SUBMIT
					</Button>
					<Button
						variant="text"
						color="primary"
						onClick={() => this.onClose()}
						className={classes.button}>
						CANCEL
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

const mapStateToProps = function(storage) {
	return {
		isOpen: storage.appState.isViewSurveyDialogOpen,
		currentSurvey: storage.survey.currentSurvey,
		
		eventId: storage.eventManager.currentEvent.id
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setUrl: url => dispatch(push(url)),
		editCurrentSurvey: (key, value) => dispatch({
			type: Dispatch.EDIT_CURRENT_SURVEY_VALUE,
			payload: {
				key: key,
				value: value
			}
		}),
		saveCurrentSurvey: eventId => dispatch({
			type: Dispatch.ON_SAVE_CURRENT_SURVEY_REQUEST,
			payload: eventId
		}),
		openViewQuestionDialog: question => dispatch({
			type: Dispatch.OPEN_VIEW_QUESTION_DIALOG,
			payload: question
		}),
		closeDialog: () => dispatch({
			type: Dispatch.CLOSE_VIEW_SURVEY_DIALOG
		}),
		enqueueSnackBar: snackBar => dispatch({
			type: Dispatch.ENQUEUE_SNACKBAR,
			payload: snackBar
		})
	}
}

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(styles),
)(ViewSurveyDialog);