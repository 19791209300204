import React, {Component} from 'react'
import {connect} from "react-redux"
import {compose} from "recompose"

import {withStyles} from '@material-ui/core/styles';
import {
    Grid,
    Card,
    CardContent,
    Tooltip,
    Typography, CardHeader, Button
} from "@material-ui/core";

import {
    HelpOutline
} from "@material-ui/icons";

import _ from "lodash";

import PieChart from "../../reusable/Chart/PieChart";
import moment from "moment";

import CountUp from 'react-countup';

import {dateToString} from "../../../util/dateUtil";
import LineChart from "../../reusable/Chart/LineChart";
import columns from "../Home/config/registrantTableConfig";
import SessionModel from "../../../model/Session";
import DataTable from "../../reusable/DataTable";
import BarChart from "../../reusable/Chart/BarChart";
import HorizontalBarChart from "../../reusable/Chart/HorizontalBarChart";
import {scroller} from "react-scroll";

import Dispatch from "../../../constant/dispatch"
import CompleteSetupGuide from "../../dashboard/Home/CompleteSetupGuide"

const styles = theme => ({
    root: {
        display: 'flex',
    },
    notificationCard: {
        padding: "0px"
    },
    cardTitle: {
        fontWeight: 450,
        fontSize: 18,
        marginBottom: 1,
        marginTop: 5
    },
    card: {
        height: "100%",
        position: "relative"
    },
    cardContent: {
        position: "absolute",
        bottom: 0
    },
    countUpBig: {
        marginTop: 0,
        marginBottom: 0,
        fontSize: 32,
        fontColor: '#0d47a1'
    },
    box: {
        marginBottom: 20,
        minHeight: 65
    },
    paperHighlightRow: {
        padding: theme.spacing.unit * 3,
        textAlign: 'left',
        color: theme.palette.text.secondary,
        height: 250,
    },
});

class Home extends Component {

    constructor(props) {
        super(props)
        this.props.getEventActivities(this.props.currentEvent.id)
        this.props.getEventCheckIns(this.props.currentEvent.id)
        this.props.getEventQRScans(this.props.currentEvent.id)
        this.props.getBoothsOfEvent(this.props.currentEvent.id)
    }

    unwind = (data, name, key) => {
        let formattedData = [[name, "Count"]];
        let unformattedData = {};
        // return formattedData


        if (!data || data.length === 0) {
            return formattedData
        }

        data.forEach((obj) => {
            // console.log(obj[key], obj["full_name"])
            if (obj[key]) {
                try {
                    JSON.parse(obj[key]).forEach((obj2) => {
                        unformattedData[obj2] = isNaN(unformattedData[obj2]) ? 1 : unformattedData[obj2] + 1
                    })
                } catch (e) {
                    console.log(e); // error in the above string (in this case, yes)!
                }
            }


        })

        Object.keys(unformattedData).forEach((key) => {
            formattedData.push([key, unformattedData[key]])
        })

        return formattedData

    }

    anchor = {
        interactions: null,
        qrScan: null
    }

    formatDemographyData = (name, data, grouper) => {

        let formattedData = [[name, "Count"]];

        let groupedData = _.groupBy(data, grouper);

        Object.keys(groupedData).forEach((key) => {
            formattedData.push([key, groupedData[key].length])
        })
        return formattedData
    }

    formatDateData = (name, data, grouper) => {

        const {currentEvent} = this.props

        let formattedData = [];

        let groupedData = _.groupBy(data, grouper);

        let startDate = currentEvent.start_time;
        let endDate = currentEvent.end_time;

        let currentDate = moment();

        startDate.set({hour: 0, minute: 0, second: 0, millisecond: 0})
        endDate.set({hour: 0, minute: 0, second: 0, millisecond: 0})
        currentDate.set({hour: 0, minute: 0, second: 0, millisecond: 0})

        // let upperlimitDate = moment(endDate).diff(currentDate, 'days') > 7 ? 2 :Math.abs(moment(startDate).diff(currentDate, 'days'))+7
        // let upperlimitDateDifference = moment(endDate).diff(currentDate, 'days') > 7 ?   : Math.abs(moment(endDate).diff(currentDate, 'days'))-7

        /**
         * There are 3 condition of current date, before, during and after event.
         * */

        /**
         * CASE : FOR BEFORE AND DURING EVENT ( DEFAULT )
         * */

        let oldLimitDateDifference = Math.abs(moment(startDate).diff(currentDate, 'days')) + 7 //DIFF(TODAY,EVENTSTART) +  7 days before
        let newLimitDateDifference = Math.abs(moment(endDate).diff(currentDate, 'days')) - 7 //DIFF(TODAY,EVENTEND) +  7 days AFTER

        /**
         * CASE : AFTER EVENT
         * */

        if (currentDate.isAfter(endDate)) {
            console.log("ENTERBLOCK")
            newLimitDateDifference = Math.abs(moment(endDate).diff(currentDate, 'days'))
        }

        console.log(startDate, currentDate, "HEJ", oldLimitDateDifference, newLimitDateDifference)

        for (let i = oldLimitDateDifference; i > newLimitDateDifference && i > 0; i--) {

            let momentDate = moment().subtract(i, 'days').set({hour: 0, minute: 0, second: 0, millisecond: 0})
            let iDate = new Date(momentDate)

            let annotation = null;

            if (momentDate.isSame(startDate) && momentDate.isSame(endDate)) {
                annotation = "Event Day"
            } else if (momentDate.isSame(startDate)) {
                annotation = "Event Start"
            } else if (momentDate.isSame(endDate)) {
                annotation = "Event End"
            }

            if (groupedData[iDate]) {
                formattedData.push([iDate, groupedData[iDate].length, annotation, annotation]);
            } else {
                formattedData.push([iDate, 0, annotation, annotation]);
            }
        }


        return formattedData
    }

    formatHourlyData = (name, data, grouper) => {

        let formattedData = [];

        let groupedData = _.groupBy(data, grouper);

        for (let i = 0; i < 24; i++) {

            let stringI = i + ""

            if (groupedData[i]) {
                formattedData.push([stringI, groupedData[stringI].length]);
            } else {
                formattedData.push([stringI, 0]);
            }
        }

        return formattedData
    }

    formatActivityData = (name, data, grouper) => {
        let formattedData = [];

        let groupedData = _.groupBy(data, grouper);

        Object.keys(groupedData).forEach((key) => {

            const data = groupedData[key]
            const flatMappedData = _.uniqBy(data, (obj) => {
                return obj.user_id
            })

            formattedData.push([key, groupedData[key].length, flatMappedData.length * 100 / this.props.checkInData.length])
        })
        return formattedData
    }

    formatQRDetailedStats = (data, filter, grouper) => {
        let filteredData = _.filter(data, filter)
        let groupedData = _.groupBy(filteredData, grouper)

        let formattedData = [];

        Object.keys(groupedData).forEach((key) => {
            formattedData.push([key, groupedData[key].length])
        })
        return formattedData
    }

    render() {

        console.log(this.props.boothsData)

        if (this.props.boothsData.length <= 0) {
            return <CompleteSetupGuide
                event={this.props.currentEvent}
            />
        }


        // let checkInData = require('../../../util/temporaryData/sguEventCheckin')
        let checkInData = _.cloneDeep(this.props.checkInData)
        // let eventApplicationActivities = require('../../../util/temporaryData/sguApplicationActivities')
        let eventApplicationActivities = _.cloneDeep(this.props.eventApplicationActivities)
        // let qrScanData = require('../../../util/temporaryData/sguQrScanHistory')
        let qrScanData = _.cloneDeep(this.props.qrScanData)
        // let boothsData = _.cloneDeep(this.props.boothsData)
        let boothsData = _.cloneDeep(this.props.boothsData)


        const classes = this.props.classes

        const LightTooltip = withStyles(theme => ({
            tooltip: {
                backgroundColor: theme.palette.common.white,
                color: 'rgba(0, 0, 0, 0.87)',
                boxShadow: theme.shadows[1],
                fontSize: 11,
            },
        }))(Tooltip);

        let genderData = this.formatDemographyData("Gender", checkInData, (obj) => {
            return obj.gender
        });

        let graduationYearData = this.formatDemographyData('Graduation Year', checkInData, (obj) => {
            return obj.graduation_year
        })

        let ageData = this.formatDemographyData('Age', checkInData, (obj) => {
            return moment().diff(obj.date_of_birth, 'years');
        })

        let preferredMajorData = this.unwind(checkInData, "Preferred Major", "preferred_majors")

        let allDemographyData = [
            {
                title: "Gender",
                data: genderData,
                description: <div>Registration grouped by gender, based on the visitor's profile.</div>
            },
            // {
            //     title:"Graduation Year",
            //     data:graduationYearData,
            //     description:<div>Registration grouped by (expected) graduation year, based on the visitor's profile.</div>
            // },
            {
                title: "Age",
                data: ageData,
                description: <div>Registration grouped by age, based on the visitor's profile.</div>
            },
            // {
            //     title:"Preferred Major",
            //     data:preferredMajorData,
            //     description:<div>Registration grouped by prefer, based on the visitor's profile.</div>
            // }
        ];

        checkInData.forEach((obj) => {
            obj.checkin_time = dateToString(obj.created_at)
        })

        let checkInByDate = this.formatDateData('Check In', checkInData, (obj) => {
            // return moment.utc(obj.created_at)
            // let date =  moment(obj.created_at);
            return new Date(moment(obj.created_at).set({hour: 0, minute: 0, second: 0, millisecond: 0}));
            // return new Date(parseInt(date.format('YYYY')), parseInt(date.format('M'))-1, parseInt(date.format('DD')));
        })

        let activityByDate = this.formatDateData('Activity', eventApplicationActivities, (obj) => {
            return new Date(moment(obj.timestamp).set({hour: 0, minute: 0, second: 0, millisecond: 0}));
        })

        let filteredQRScan = _.filter(qrScanData, (obj) => {

            return moment.utc(obj.timestamp).isSameOrBefore(
                this.props.currentEvent.end_time,
                'hour'
            ) || moment.utc(obj.timestamp).isSameOrAfter(
                this.props.currentEvent.start_time,
            )

        });

        let qrScanByDate = this.formatDateData('Qr Scan', filteredQRScan, (obj) => {
            return new Date(moment(obj.timestamp).set({hour: 0, minute: 0, second: 0, millisecond: 0}));
        })

        let allGeneralData = [
            {
                title: "Registration",
                data: checkInByDate,
                number: checkInData.length,
                caption: "registrations",
                description: <div>Registration is recorded every time the user checks in to the event.</div>
            },
            {
                title: "QR Scanned",
                data: qrScanByDate,
                number: qrScanData.length,
                caption: "QR scanned during the event"
            },
            {
                title: "Interaction",
                data: activityByDate,
                number: eventApplicationActivities.length,
                caption: "interactions recorded by the application"
            },
        ]


        let interactionDataOverview = this.formatActivityData("TYPE", eventApplicationActivities, (obj) => {
            return obj.type
        })

        let interactionByHour = this.formatHourlyData('Activity', eventApplicationActivities, (obj) => {
            return new moment(obj.timestamp).hour() + ""
        })

        let qrDataOverview = this.formatActivityData("TYPE", qrScanData, (obj) => {
            return obj.type
        })

        let qrByHour = this.formatHourlyData('Activity', qrScanData, (obj) => {
            return new moment(obj.timestamp).hour() + ""
        })

        let boothVisitData = this.formatQRDetailedStats(qrScanData,
            (obj) => {
                return obj.type === "BOOTH_VISIT"
            },
            (obj) => {
                const booth = boothsData.find((obj2) => {
                    return obj2.id === obj.booth_id
                })
                return booth ? booth.name : 'Unknown Booth'
            })

        let boothWinnerData = this.formatQRDetailedStats(qrScanData,
            (obj) => {
                return obj.type === "BOOTH_WINNER"
            },
            (obj) => {
                const booth = boothsData.find((obj2) => {
                    return obj2.id === obj.booth_id
                })
                return booth ? booth.name : 'Unknown Booth'
            })

        let boothEngageData = this.formatQRDetailedStats(qrScanData,
            (obj) => {
                return obj.type === "BOOTH_ENGAGE"
            },
            (obj) => {
                const booth = boothsData.find((obj2) => {
                    return obj2.id === obj.booth_id
                })
                return booth ? booth.name : 'Unknown Booth'
            })

        // return <div>Lalalllal</div>

        return <div className={classes.root}>
            <Grid container spacing={4}>

                <Grid item xs={12}>
                    <Typography variant="h5" color="textPrimary" className={classes.textField}>
                        General Overview
                    </Typography>
                </Grid>

                {
                    allGeneralData.map((obj, key) => {
                        return <Grid item xs={4} style={{flexDirection: 'row'}} key={key}>
                            <Card className={classes.card}>
                                <CardHeader
                                    title={<div style={{flexDirection: 'row', display: 'inline-flex', width: '100%'}}>
                                        <span className={classes.cardTitle} style={{flex: 1}}>{obj.title}</span>
                                        <LightTooltip style={{alignSelf: 'flex-end', flex: 1}}
                                                      title={<><b>{obj.title}</b><p>{obj.description}</p></>}>
                                            <HelpOutline style={{color: "grey"}}/>
                                        </LightTooltip>
                                    </div>}/>
                                <CardContent>
                                    <CountUp end={obj.number} className={classes.countUpBig}/>
                                    <Typography style={{
                                        display: 'inline-block',
                                        marginInlineStart: 5
                                    }}>{obj.caption}</Typography>
                                    <LineChart rows={obj.data}
                                               columns={[
                                                   {
                                                       type: "date",
                                                       label: "Date",
                                                   },
                                                   {
                                                       type: "number",
                                                       label: "Count"
                                                   },
                                                   {
                                                       type: "string",
                                                       role: "annotation",
                                                       label: "annotation"
                                                   },
                                                   {
                                                       type: "string",
                                                       role: "annotationText",
                                                       label: "annotationText"
                                                   },
                                               ]}/>
                                    <div style={{
                                        display: 'flex',
                                        flex: 1,
                                        justifyContent: 'flex-end',
                                        flexDirection: 'row'
                                    }}>
                                        <Button
                                            variant="text"
                                            color="primary"
                                            onClick={() => {
                                                scroller.scrollTo('interactions', {
                                                    duration: 800,
                                                    delay: 0,
                                                    smooth: 'easeInOutQuart'
                                                });

                                            }}
                                        >
                                            MORE
                                        </Button>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    })
                }

                <Grid item xs={12}>
                    <Typography variant="h5" color="textPrimary" className={classes.textField}>
                        Registration Statistics
                    </Typography>
                </Grid>

                {
                    allDemographyData.map((obj, key) => {
                        return <Grid item md={6} lg={6} key={key}>
                            <Card className={classes.card}>
                                <CardHeader title={<Typography className={classes.cardTitle}>{obj.title}</Typography>}/>
                                <CardContent>
                                    <PieChart data={obj.data}/>
                                </CardContent>
                            </Card>
                        </Grid>
                    })
                }


                <Grid item xs={12}>
                    <DataTable
                        title="Visitor List"
                        selectableRows="multiple"
                        filter={false}
                        viewColumns={true}
                        data={checkInData}
                        columns={columns}
                        // onRowsDelete={session => this.props.deleteSession(session)}
                    />
                </Grid>


                <Grid item xs={12}>
                    <Typography variant="h5" color="textPrimary" className={classes.textField}>
                        QR History Breakdown
                    </Typography>
                </Grid>


                <Grid item md={6} lg={6}>
                    <Card className={classes.card}>
                        <CardHeader title={<Typography className={classes.cardTitle}>QR Scan Per Hour</Typography>}/>
                        <CardContent>
                            <HorizontalBarChart
                                columns={["Interaction Type", "Occurence", "Usage"]}
                                rows={qrDataOverview}
                            />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={6} style={{flexDirection: 'row'}} id={'interactions'}>
                    <Card className={classes.card}>
                        <CardHeader title={<>
                            <Typography className={classes.cardTitle}>QR Scan Per Hour</Typography>
                        </>}/>
                        <CardContent>
                            <LineChart rows={qrByHour}
                                       columns={[
                                           {
                                               type: "number",
                                               label: "Time",
                                           },
                                           {
                                               type: "number",
                                               label: "Count"
                                           }
                                       ]}/>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item md={12} lg={6}>
                    <Card className={classes.card}>
                        <CardHeader title={<Typography className={classes.cardTitle}>QR Scan Per Hour</Typography>}/>
                        <CardContent>
                            <HorizontalBarChart
                                columns={["Booth Name", "Booth Visit"]}
                                rows={boothVisitData}
                            />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item md={12} lg={6}>
                    <Card className={classes.card}>
                        <CardHeader title={<Typography className={classes.cardTitle}>QR Scan Per Hour</Typography>}/>
                        <CardContent>
                            <HorizontalBarChart
                                columns={["Booth Name", "Booth Visit"]}
                                rows={boothWinnerData}
                            />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item md={12} lg={6}>
                    <Card className={classes.card}>
                        <CardHeader title={<Typography className={classes.cardTitle}>QR Scan Per Hour</Typography>}/>
                        <CardContent>
                            <HorizontalBarChart
                                columns={["Booth Name", "Booth Visit"]}
                                rows={boothEngageData}
                            />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5" color="textPrimary" className={classes.textField}>
                        Interaction Breakdown
                    </Typography>
                </Grid>

                <Grid item md={6} lg={6}>
                    <Card className={classes.card}>
                        <CardHeader title={<Typography className={classes.cardTitle}>Interactions</Typography>}/>
                        <CardContent>
                            <HorizontalBarChart
                                columns={["Interaction Type", "Occurence", "Usage"]}
                                rows={interactionDataOverview}
                            />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={6} style={{flexDirection: 'row'}} id={'interactions'}>
                    <Card className={classes.card}>
                        <CardHeader title={<>
                            <Typography className={classes.cardTitle}>Activity Per Hour</Typography>
                        </>}/>
                        <CardContent>
                            <LineChart rows={interactionByHour}
                                       columns={[
                                           {
                                               type: "number",
                                               label: "Time",
                                           },
                                           {
                                               type: "number",
                                               label: "Count"
                                           }
                                       ]}/>
                        </CardContent>
                    </Card>
                </Grid>

            </Grid>
        </div>

    }
}

const mapStateToProps = function (storage) {
    return {
        currentEvent: storage.eventManager.currentEvent,
        eventApplicationActivities: storage.statistic.eventActivities,
        qrScanData: storage.statistic.scanHistory,
        checkInData: storage.statistic.checkIns,
        boothsData: storage.booth.booths,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getEventActivities: (eventId) => {
            dispatch({type: Dispatch.ON_GET_EVENT_ACTIVITIES_REQUEST, payload: eventId})
        },
        getEventCheckIns: (eventId) => {
            dispatch({type: Dispatch.ON_GET_EVENT_CHECKINS_REQUEST, payload: eventId})
        },
        getEventQRScans: (eventId) => {
            dispatch({type: Dispatch.ON_GET_EVENT_QR_SCAN_HISTORY_REQUEST, payload: eventId})
        },
        getBoothsOfEvent: (eventId) => {
            dispatch({type: Dispatch.ON_GET_BOOTHS_REQUEST, payload: eventId})
        }
    }
}

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withStyles(styles)
)(Home)

