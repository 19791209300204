import Survey from "../model/Survey"
import SurveyAction from "../model/SurveyAction"
import Question from "../model/Question"
import Dispatch from "../constant/dispatch"

const defaultState = {
	currentSurvey: new Survey(),
	surveys: [],
	
	currentQuestion: new Question(-1),
	
	isSurveyDataLoaded: false,
	isSurveyResultDataLoaded: false
}

function handleLocalChanges(state, action) {
	if(action.type === Dispatch.OPEN_VIEW_SURVEY_DIALOG) {
		return {
			...state,
			currentSurvey: typeof action.payload === "object" ? action.payload : state.surveys.find(survey => survey.id === action.payload)
		}
	} else if(action.type === Dispatch.CLOSE_VIEW_SURVEY_DIALOG) {
		return {
			...state,
			currentSurvey: defaultState.currentSurvey
		}
	} else if(action.type === Dispatch.EDIT_CURRENT_SURVEY_VALUE) {
		return {
			...state,
			currentSurvey: {
				...state.currentSurvey,
				[action.payload.key]: action.payload.value
			}
		}
		
	} else if(action.type === Dispatch.OPEN_VIEW_QUESTION_DIALOG) {
		return {
			...state,
			currentQuestion: action.payload
		}
	} else if(action.type === Dispatch.CLOSE_VIEW_QUESTION_DIALOG) {
		return {
			...state,
			currentQuestion: defaultState.currentQuestion
		}
	} else if(action.type === Dispatch.SAVE_CURRENT_QUESTION) {
		return {
			...state,
			currentSurvey: {
				...state.currentSurvey,
				question_json: state.currentSurvey.question_json.find(q => q.id === state.currentQuestion.id) ?
					state.currentSurvey.question_json.map(question => question.id === state.currentQuestion.id ? state.currentQuestion : question) :
					state.currentSurvey.question_json.concat(state.currentQuestion)
			}
		}
	} else if(action.type === Dispatch.EDIT_CURRENT_QUESTION_VALUE) {
		return {
			...state,
			currentQuestion: {
				...state.currentQuestion,
				[action.payload.key]: action.payload.value
			}
		}
	} else if(action.type === Dispatch.OPEN_VIEW_SURVEY_RESULT_DIALOG) {
		const targetSurvey = state.surveys.find(survey => survey.id === action.payload)
		SurveyAction.getSurveyResults(targetSurvey)
		return {
			...state,
			currentSurvey: targetSurvey,
			isSurveyResultDataLoaded: false
		}
	} else if(action.type === Dispatch.CLOSE_VIEW_SURVEY_RESULT_DIALOG) {
		return {
			...state,
			currentSurvey: defaultState.currentSurvey
		}
	} else if(action.type === Dispatch.RETURN_TO_EVENT_MANAGER_PAGE) {
		return defaultState
	}
	return state
}

export default function reducer(state = defaultState, action) {
	if(action.type === Dispatch.ON_GET_SURVEYS_REQUEST) {
		SurveyAction.getSurveys(action.payload)
	} else if(action.type === Dispatch.ON_SAVE_CURRENT_SURVEY_REQUEST) {
		let survey = {...state.currentSurvey}
		survey.event_id = action.payload
		survey.id === -1 ? SurveyAction.createSurvey(survey) : SurveyAction.updateSurvey(survey)
	} else if(action.type === Dispatch.ON_DELETE_SURVEY_BY_ID_REQUEST) {
		SurveyAction.deleteSurvey({...action.payload})
	} else if(action.type === Dispatch.ON_GET_SURVEYS_RESPONSE_SUCCESS) {
		return {
			...state,
			surveys: action.payload
		}
	} else if(action.type === Dispatch.ON_SAVE_CURRENT_SURVEY_RESPONSE_SUCCESS) {
		/*return {
			...state,
			surveys: state.surveys.find(s => s.id === action.payload.id) ?
				state.surveys.map(survey => survey.id === action.payload.id ? action.payload : survey) :
				state.surveys.concat(action.payload)
		}*/
		SurveyAction.getSurveys(action.payload)
	} else if(action.type === Dispatch.ON_DELETE_SURVEY_BY_ID_RESPONSE_SUCCESS) {
		return {
			...state,
			surveys: state.surveys.filter(survey => survey.id !== action.payload.id)
		}
	} else if(action.type === Dispatch.ON_GET_SURVEY_RESULTS_RESPONSE_SUCCESS) {
		return {
			...state,
			currentSurvey: action.payload,
			isSurveyResultDataLoaded: true
		}
		
	} else if(action.type === Dispatch.LOG_OUT) {
		return defaultState
	}
	return handleLocalChanges(state, action)
}