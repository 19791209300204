class Book {
    constructor(eventId) {
        this.id = -1
        this.event_id = eventId
        this.title = ""
        this.author = ""
        this.category = ""
        this.publisher = ""

        this.additional_information = {
            url : "",
            image : "",
            title : "",
            summary : "",
            publisher : "",
            author : "",
            price : "",
            interact_type : "external_url"
        }
        //TODO: PM Exclusive
        this.type= "BOOKS"

        this.price = 0
    }

    static getRequestCompatibleObject(book) {
        let compatibleBook = {...book}
        compatibleBook.additional_information = JSON.stringify(book.additional_information)
        return compatibleBook
    }

    static isDataValid(book) {
        if(
            book.hasOwnProperty("title") && book.hasOwnProperty("category")
        ) {
            return book.title !== "" && book.category !== ""
        }
        return false
    }

}

export default Book
